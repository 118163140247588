<template>
    <div class="swiperContainer" ref="swiperContainer">
        <div class="swiper-pagination p-2" v-if="showPagination"></div>
        <div class="swiper-wrapper" :class="showPagination ? 'mt-2':''">
            <slot>
            </slot>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Swiper from 'swiper';

@Component
export default class SwiperSlides extends Vue {
    
    @Prop({
        type: Number,
        default: 0
    }) slideIndex: number;

    @Prop({
        default: true
    }) showPagination: boolean;
    
    @Prop({
        default: true
    }) autoHeight: boolean;

    swiper: any;
    mounted(){
        this.swiper = new Swiper(this.$refs.swiperContainer, {
            pagination: {
                el: '.swiper-pagination',
            },
            autoHeight: this.autoHeight,
            direction: 'horizontal',
            initialSlide: this.slideIndex,
            speed: 100,
            on: {
                slideChange: this.onSlideChange
            }
        });
    }
    @Watch('slideIndex')
    onSlideIndexChange(next, prev){
        this.swiper.slideTo(next);
    }

    onSlideChange(){
        if(this.swiper)
            this.$emit('slidechange', this.swiper.activeIndex);
    }
}

</script>

<style scoped>

    .swiperContainer {
        width: 100%;
        overflow: hidden;
        position: relative;
    }
    .swiper-pagination {
        z-index: 1;
    }
    .swiper-pagination-bullets {
        bottom: unset!important;
    }

</style>



