import Vue from 'vue';
import VueRouter from 'vue-router';
import { RouteConfig } from 'vue-router';
import FbToken from '@/views/fbToken.vue';
import Splash from '@/views/splash/splash.vue';
import Login from '@/views/auth/Login.vue';
import Register from '@/views/auth/Register.vue';
import LoginForm from '@/views/auth/LoginForm.vue';
import Layout from '@/views/layout/Layout.vue';
import Percorsi from '@/views/learn/percorsi/percorsi.vue';
import Cinture from '@/views/learn/cinture/cinture.vue';
import allTemi from '@/views/learn/cinture/allTemi.vue';
import DettaglioTema from '@/views/learn/cinture/dettaglioTema.vue';
import DettaglioLezione from '@/views/learn/lezioni/dettaglioLezione.vue';
import Glossario from '@/views/learn/glossario/glossario.vue';
import GlossarioAll from '@/views/learn/glossario/glossarioAll.vue';
import Questionario from '@/views/learn/questionario/questionario.vue';
import RisultatoQuestionario from '@/views/learn/questionario/risultatoQuestionario.vue';
import Premi from '@/views/profile/Premi.vue';
import BlankPage from '@/views/layout/BlankPage.vue';
import ProfileEdit from '@/views/profile/ProfileEdit.vue';
import Challenges from '@/views/play/challenges/challenges.vue';
import ChallengeDetail from '@/views/play/challenges/challengeDetail.vue';
import ChallengeLayout from '@/views/play/challengeLayout.vue';
import Ranking from '@/views/play/ranking/ranking.vue';
import EditFriendChallenge from '@/views/play/ranking/editFriendChallenge.vue';
import FriendChallengeRanking from '@/views/play/ranking/friendChallengeRanking.vue';
import ManageFriendChallenges from '@/views/play/ranking/manageFriendChallenges.vue';
import CreateFriendChallenge from '@/views/play/ranking/createFriendChallenge.vue';
import Wallet from '@/views/play/wallet/wallet.vue';
import SwitchVendita from '@/views/play/wallet/switchVendita.vue';
import SwitchAcquisto from '@/views/play/wallet/switchAcquisto.vue';
import AssetsLayout from '@/views/play/assets/assetsLayout.vue';
import Assets from '@/views/play/assets/assets.vue';
import MacroClasses from '@/views/play/assets/macroClasses.vue';
import MicroClasses from '@/views/play/assets/microClasses.vue';
import MicroClassSummary from '@/views/play/assets/microClassSummary.vue';
import AssetsList from '@/views/play/assets/assetsList.vue';
import Analysis from '@/views/play/analysis/analysis.vue';
import HorizontalChart from '@/views/play/horizontalChart.vue';
import PeriodiHorizontalTable from '@/views/play/periodiHorizontalTable.vue';
import PerformanceHorizontalTable from '@/views/play/performanceHorizontalTable.vue';
import ChatRoom from '@/views/chat/chatRoom.vue';
import Archivio from '@/views/archivio/archivio.vue';
import ArchivioDetail from '@/views/archivio/archivioDetail.vue';

let routes: RouteConfig[] = [
    {
        path: '/',
        //name: 'splash',
        component: Splash
    },
    {
        path: '/login',
        //name: 'login',
        component: Login
    },
    {
        path: '/register',
        //name: 'register',
        component: Register
    },
    {
        path: '/loginForm',
        //name: 'register',
        component: LoginForm
    },
    {
        path: '/fbToken',
        //name: 'fbToken',
        component: FbToken
    },
    {
        path: '/',
        //name: 'layout',
        component: Layout,
        children: [
            {
                path: '/blank',
                name: 'blank',
                meta: { hideMenu: true, menuOpen: true },
                component: BlankPage
            },
            {
				path: '/archivio/:nodeIdentifier',
                name: 'archivio',
                meta: { tutorial: 'archivio', pageKey: 'archivio', backButton: true },
                component: Archivio,
            },
            {
				path: '/archivioDetail/:nodeIdentifier',
                name: 'archivioDetail',
                meta: { backButton: true },
                component: ArchivioDetail,
            },
            {
				path: '/chatRoom',
                name: 'chatRoom',
                meta: { tutorial: 'chatRoom' },
                component: ChatRoom,
            },
            {
                path: '/percorsi',
                name: 'percorsi',
                meta: { tutorial: 'percorsi', glossario: true },
                component: Percorsi,
            },
            {
                path: '/percorsi/:percorsoIdentifier/cinture',
                name: 'cinture',
                meta: { tutorial: 'cinture', glossario: true },
                component: Cinture,
            },
            {
                path: '/percorsi/:percorsoIdentifier/alltemi',
                name: 'cinture',
                meta: { tutorial: 'allTemi', glossario: true },
                component: allTemi,
            },
            {
                path: '/percorsi/:percorsoIdentifier/alltemi/:temaIdentifier',
                name: 'tema',
                meta: { tutorial: 'tema', backButton: true, glossario: true },
                component: DettaglioTema,
            },
            {
                path: '/percorsi/:percorsoIdentifier/cinture/:cinturaIdentifier/temi/:temaIdentifier',
                name: 'tema',
                meta: { tutorial: 'tema', backButton: true, glossario: true },
                component: DettaglioTema,
            },
            {
                path: '/cinture/:cinturaIdentifier/lezione/:lezioneIdentifier',
                name: 'lezione',
                meta: { tutorial: 'lezione', backButton: true },
                component: DettaglioLezione
            },
            {
                path: '/cinture/:cinturaIdentifier/lezione/:lezioneIdentifier/test',
                name: 'testLezione',
                meta: { tutorial: 'testLezione', backButton: true },
                component: Questionario
            },
            {
                path: '/cinture/:cinturaIdentifier/lezione/:lezioneIdentifier/risultatoQuestionario',
                name: 'risultatoTestLezione',
                meta: { tutorial: 'risultatoTestLezione'},
                component: RisultatoQuestionario
            },
            {
                path: '/glossario/:word',
                meta: { title: 'Glossario' },
                component: Glossario
            },
            {
                path: '/glossario/',
                name: 'glossario',
                meta: { tutorial: 'glossario', title: 'Glossario', backButton: true },
                component: GlossarioAll
            },
            {
				path: '/challenges',
                name: 'challenges',
				meta: { tutorial: 'challenges' }, //showHelp: true, help: "challenges"},
				component: Challenges
            },
            {
				path: '/challenges/:identifier',
                name: 'challengeDetailNotSigned',
                meta: { tutorial: 'challengeDetailNotSigned', backButton: true },
				component: ChallengeDetail
            },
            {
				path: '/premi',
                name: 'premi',
				meta: { tutorial: 'premi' },
				component: Premi
            },
            {
				path: '/profile/edit',
				meta: { },
				component: ProfileEdit
            },
            {
				path: '/horizontalChart/:chartLabel/:microClass/:yType',
                //name: 'horizontalChart',
                meta: { backButton: true, hideMenu: true, hideBar: true },
				component: HorizontalChart
            },
            {
				path: '/periodiHorizontalTable/:label/:microClass',
                //name: 'periodiHorizontalTable',
                meta: { backButton: true, hideMenu: true, hideBar: true },
				component: PeriodiHorizontalTable
            },
            {
				path: '/performanceHorizontalTable/:label/:assetIdentifier',
                //name: 'periodiHorizontalTable',
                meta: { backButton: true, hideMenu: true, hideBar: true },
				component: PerformanceHorizontalTable
            },
            {
                path: '/challenge/:challengeIdentifier/:portafoglioIdentifier',
                //name: 'challengeLayout',
                component: ChallengeLayout,
                children: [
                    {
                        path: 'details',
                        name: 'challengeDetailSigned',
                        meta: { tutorial: 'challengeDetailSigned', backButton: true },
                        component: ChallengeDetail,
                    },
                    {
                        path: 'ranking',
                        name: 'ranking',
                        meta: { tutorial: 'ranking', backButton: true },
                        component: Ranking,
                    },
                    {
                        path: 'manageFriendChallenges',
                        name: 'manageFriendChallenges',
                        meta: { tutorial: 'manageFriendChallenges', backButton: true, hideBar: true, pb0: true },
                        component: ManageFriendChallenges
                    },
                    {
                        path: 'friendChallengeRanking/:friendChallengeIdentifier',
                        name: 'friendChallengeRanking',
                        meta: { tutorial: 'friendChallengeRanking', backButton: true, hideBar: true, pb0: true },
                        component: FriendChallengeRanking
                    },
                    {
                        path: 'createFriendChallenge',
                        name: 'createFriendChallenge',
                        meta: { tutorial: 'createFriendChallenge', backButton: true, hideBar: true, pb0: true },
                        component: CreateFriendChallenge
                    },
                    {
                        path: 'editFriendChallenge/:friendChallengeIdentifier',
                        name: 'editFriendChallenge',
                        meta: { tutorial: 'editFriendChallenge', backButton: true, hideBar: true, pb0: true },
                        component: EditFriendChallenge
                    },
                    {
                        path: 'wallet',
                        name: 'wallet',
                        meta: { tutorial: 'wallet', backButton: true },
                        component: Wallet,
                    },
                    {
                        path: 'switchVendita',
                        name: 'switchVendita',
                        meta: { tutorial: 'switchVendita', backButton: true, hideBar: true },
                        component: SwitchVendita,
                    },
                    {
                        path: 'switchAcquisto',
                        name: 'switchAcquisto',
                        meta: { tutorial: 'switchAcquisto', hideBar: true },
                        component: SwitchAcquisto,
                        children: [
                            {
                                path: 'assets',
                                name: 'assetsSwitch',
                                meta: { backButton: true, hideBar: true },
                                component: () => import('@/views/play/wallet/assetsSwitch.vue'),
                            },
                            {
                                path: 'assets/:assetType',
                                name: 'assetsAssetTypeSwitch',
                                meta: { tutorial: 'assetsAssetType', backButton: true, hideBar: true },
                                component: () => import('@/views/play/wallet/macroClassesSwitch.vue'),
                            },
                            {
                                path: 'assets/:assetType/:macroClass',
                                name: 'assetsMacroClassesSwitch',
                                meta: { tutorial: 'assetsMacroClasses', backButton: true, hideBar: true },
                                component: () => import('@/views/play/wallet/microClassesSwitch.vue'),
                            },
                            {
                                path: 'assets/:assetType/:macroClass/:microClass',
                                name: 'assetsBenchmarkSwitch',
                                meta: { tutorial: 'assetsBenchmark', backButton: true, hideBar: true },
                                component: () => import('@/views/play/wallet/microClassSummarySwitch.vue'),
                            },
                            {
                                path: 'assets/:assetType/:macroClass/:microClass/list',
                                name: 'assetsBuyListSwitch',
                                meta: { tutorial: 'assetsBuyList', backButton: true, hideBar: true, pb0: true },
                                component: () => import('@/views/play/wallet/assetsListSwitch.vue'),
                            },
                        ]
                    },
                    {
                        path: '',
                        meta: { backButton: true },
                        component: AssetsLayout,
                        children: [
                            {
                                path: 'assets',
                                name: 'assets',
                                meta: { tutorial: 'assets', backButton: true },
                                component: Assets,
                            },
                            {
                                path: 'assets/:assetType',
                                name: 'assetsAssetType',
                                meta: { tutorial: 'assetsAssetType', backButton: true },
                                component: MacroClasses,
                            },
                            {
                                path: 'assets/:assetType/:macroClass',
                                name: 'assetsMacroClasses',
                                meta: { tutorial: 'assetsMacroClasses', backButton: true },
                                component: MicroClasses,
                            },
                            {
                                path: 'assets/:assetType/:macroClass/:microClass',
                                name: 'assetsBenchmark',
                                meta: { tutorial: 'assetsBenchmark', backButton: true, hideBar: true },
                                component: MicroClassSummary,
                            },
                            {
                                path: 'assets/:assetType/:macroClass/:microClass/list',
                                name: 'assetsBuyList',
                                meta: { tutorial: 'assetsBuyList', backButton: true, hideBar: true, pb0: true },
                                component: AssetsList,
                            },
                        ]
                    },
                    {
                        path: 'analysis',
                        name: 'analysis',
                        meta: { tutorial: 'analysis', backButton: true, pageKey: 'analysis' },
                        component: Analysis,
                    },
                    {
                        path: 'analysis/:assetType',
                        name: 'analysisAssetType',
                        meta: { tutorial: 'analysis', backButton: true, pageKey: 'analysisassetType' },
                        component: Analysis,
                    },
                    {
                        path: 'analysis/:assetType/:macroClass',
                        name: 'analysisMacroClass',
                        meta: { tutorial: 'analysis', backButton: true, pageKey: 'analysismacroClass' },
                        component: Analysis,
                    },
                    {
                        path: 'analysis/:assetType/:macroClass/:microClass',
                        name: 'analysisMicroClass',
                        meta: { tutorial: 'analysis', backButton: true, pageKey: 'analysismicroClass' },
                        component: Analysis,
                    },
                ]
            }
        ]
    }
]


Vue.use(VueRouter);

const Router = new VueRouter({
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export default Router;
