<template>
    <div class="walletBar">
        <div class="customContainer">
            <div class="walletSlot" :class="{ 'x2': forSwitch, 'text-orange': forSwitch }">
                <small v-if="!forSwitch">{{$store.state.dizionario.wallet_bar_liquidity_label}}</small>
                <small v-else>{{$store.state.dizionario.wallet_bar_switchLiqudity_label}}</small>
                <animated-number :value="totalLiquidity">
                    <span slot-scope="animated" :class="{'text-orange': forSwitch }">{{animated.value | currency(0)}}</span>
                </animated-number>
            </div>
            <div class="walletSlot" v-if="!forSwitch">
                <small>{{$store.state.dizionario.wallet_bar_liquidity_perc_label}}</small>
                <animated-number :value="liquidityPercentPerc" :decimals="1">
                    <span slot-scope="animated">{{animated.value}}%</span>
                </animated-number>
            </div>
            <div class="verticalBar">
            </div>
            <div class="walletSlot">
                <small>{{$store.state.dizionario.wallet_bar_liquidity_wallet_risk_label}}</small>
                <animated-number :value="percWalletRisk" :decimals="1">
                    <span slot-scope="animated" :class="{'text-red': percWalletRisk > $store.state.portafoglioStatus.matchRisk}">
                        {{animated.value}}%
                    </span>
                </animated-number>
            </div>
            <div class="walletSlot">
                <small>{{$store.state.dizionario.wallet_bar_liquidity_wallet_match_risk_label}}</small>
                <animated-number :value="$store.state.portafoglioStatus.matchRisk" :decimals="1">
                    <span slot-scope="animated">{{animated.value}}%</span>
                </animated-number>
            </div>

            <div class="percentages-bar">
                <div class="h-100" v-for="(type, index) in $store.state.portafoglioStatus.percentuali" 
                    :key="index" 
                    v-typeclass="type.nome"
                    :style="'width:' + type.peso + '%'"></div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import AnimatedNumber from '@/components/animatedNumber.vue';
import store from '@/store';
import { PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        AnimatedNumber
    }
})
export default class WalletBar extends Vue {

    @Prop({
        type: Number,
        default: 10
    }) matchRisk: number;

    @Prop({
        type: Number,
        required: false
    }) walletRisk: number;
    
    @Prop({
        type: Number,
        required: false,
        default: 0
    }) sumToLiquidity: number;
    
    @Prop({
        type: Number,
        required: false,
        default: null
    }) liquidityPercent: number;
    
    @Prop({
        type: Boolean,
        required: false,
        default: false
    }) refresh: boolean;
    
    @Prop({
        type: Boolean,
        required: false,
        default: false
    }) forSwitch: boolean;
    
    @Watch('walletRisk')
    onWalletRiskChange(next, prev){
        store.state.portafoglioStatus.walletRisk = next;
    }

    get percWalletRisk(){
        return store.state.portafoglioStatus.walletRisk * 100;
    }

    get liquidityPercentPerc(){
        if(this.liquidityPercent || this.liquidityPercent != null)
            return this.liquidityPercent * 100;
        else
            return store.state.portafoglioStatus.liquidityPercentage * 100;
    }
    
    get totalLiquidity(){
        return store.state.portafoglioStatus.liquidity + this.sumToLiquidity;
    }

    created(){
        if(!this.$route.params.portafoglioIdentifier){
            console.error('Cannot use walletBar component on this route, missing param "portafoglioIdentifier"');
            return;
        }
        if(this.refresh)
            StorageServices.deletePortafoglioStatus(this.$route.params.portafoglioIdentifier);
            
        let prom = new Promise<OM.PortafoglioStatusVm>((resolve, reject) => {
            let wallet = StorageServices.getPortafoglioStatus(this.$route.params.portafoglioIdentifier);
            if(wallet){
                resolve(wallet);
            } else {
                PortafoglioClient.getPortafoglioStatusWithSwitch({
                    portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
                    creaSwitchVm: null
                })
                .then(x => {
                    StorageServices.setPortafoglioStatus(x);
                    resolve(x);
                })
            }
        });
        prom.then(x => {
            store.state.portafoglioStatus = x;
            //this.wallet = store.state.portafoglioStatus;
            // this.wallet.portafoglioIdentifier = x.portafoglioIdentifier;
            // this.wallet.liquidity = x.liquidity;
            // this.wallet.liquidityPercentage = x.liquidityPercentage;
            // this.wallet.walletRisk = x.walletRisk;
            // this.wallet.matchRisk = x.matchRisk;
            if(this.walletRisk){
                store.state.portafoglioStatus.walletRisk = this.walletRisk;
            }
        })
    }



}
</script>

<style scoped>

    .walletBar {
        /* display: flex; */
        width: 100%;
        justify-content: space-around;
        border-bottom: 1px solid rgba(246, 156, 20, 0.4);
        padding: 8px 0 4px;
        background: #11171d;
        z-index: 9;
        height: 60px;

        display: block;
    }

    .customContainer {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .walletBar span {
        display: block;
        font-size: 22px;
        font-family: 'Poppins-Light';
        color: #e8e8e8;
    }

    .walletBar .walletSlot {
        width: 100%;
        text-align: center;
    }

    .walletBar .walletSlot.x2 {
        width: 200%;
    }

    .verticalBar {
        height: 50%;
        width: 1%;
        background: rgba(246, 156, 20, 0.4);
        margin-top: 15px;
    }

</style>
