<template>
    <div>
        <div id="app">
            <opmodal-container></opmodal-container>
            <transition name="fade">
                <div class="spinnerContainer" v-if="showSpinner">
                    <div class="stage">
                        <div class="dot dot1"></div>
                        <div class="dot dot2"></div>
                        <div class="dot dot3"></div>
                    </div>
                </div>
            </transition>
            <router-view></router-view>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices'

@Component
export default class App extends Vue {

    created(){
        ModalServices.SetModalInstance(this.$opModal);
    }

    get showSpinner(){
        return this.$store.state.showSpinner > 0;
    }
}
</script>

<style scoped>
#app {
    max-width: 720px;
    margin: 0 auto;
    position: relative;
}
</style>