<template>
    <div class="spinner">
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import * as OM from '@/model';

@Component
export default class MiniSpinner extends Vue {

}

</script>
<style scoped>
    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }

    .spinner {
        position: relative;
        height: 30px;
        width: 30px;
        margin: auto;
    }
    
    .spinner:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border-top: 2px solid #f69c14;
        border-right: 2px solid transparent;
        animation: spinner .6s linear infinite;
    }
</style>
