<template>
    <div class="">
        <div class="challengeSelector">
            <div class="selectorSlug" :class="{ 'active': selectedSlide == 0 }" @click="selectedSlide = 0">
                {{$store.state.dizionario.challenges_filter_in_corso}}
            </div>
            <div class="selectorSlug" :class="{ 'active': selectedSlide == 1 }" @click="selectedSlide = 1">
                {{$store.state.dizionario.challenges_filter_partecipo}}
            </div>
            <div class="selectorSlug" :class="{ 'active': selectedSlide == 2 }" @click="selectedSlide = 2">
                {{$store.state.dizionario.challenges_filter_concluse}}
            </div>
            <!-- <div class="selectorSlug" :class="{ 'active': selectedSlide == 3 }" @click="selectedSlide = 3">
                {{$store.state.dizionario.challenges_filter_all}}
            </div> -->
            <div class="selectorSlug" :class="{ 'active': selectedSlide == 3, 'notified': hasInviti }" @click="goToAmici">
                GARE CON AMICI
            </div>
        </div>
        <div class="systemDateContainer">
            <p class="text-center text-low-white">
                {{$store.state.dizionario.challenges_systemDate_text}} <span class="text-white">{{$store.state.systemDate | date("DD MMMM YYYY")}}</span>
            </p>
        </div>
        <div class="swiperSlidesContainer" ref="swiperSlidesContainer">
            <swiper-slides class="challengeSlider" :slide-index="selectedSlide" :showPagination="false" @slidechange="onSlideChange">
                <div class="swiper-slide">
                    <div class="px-3">
                        <challenge-card v-for="(item, i) in ongoingChallenges" :key="i" :challenge="item"
                            @click.native="selectChallenge(item)">
                        </challenge-card>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="px-3">
                        <challenge-card v-for="(item, i) in attendedChallenges" :key="i" :challenge="item"
                            @click.native="selectChallenge(item)">
                        </challenge-card>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="px-3">
                        <challenge-card v-for="(item, i) in closedChallenges" :key="i" :challenge="item" class="closed"
                            @click.native="selectChallenge(item)">
                        </challenge-card>
                    </div>
                </div>
                <!-- <div class="swiper-slide">
                    <div class="px-3">
                        <challenge-card v-for="(item, i) in challenges" :key="i" :challenge="item"
                            @click.native="selectChallenge(item)">
                        </challenge-card>
                    </div>
                </div> -->
            </swiper-slides>
        </div>
    </div>
</template>
<script lang="ts">
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import SwiperSlides from '@/components/swiperSlides.vue';
import ChallengeCard from '@/components/challengeCard.vue';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';
import DisclaimerModal from '@/components/disclaimerModal.vue';
import { ModalServices } from '@/services/ModalServices';
import SelectChallengeModal from './selectChallengeModal.vue';
import { ChallengeClient, FriendChallengeClient, PortafoglioClient, UserClient } from '@/services/Services';

@Component({
    components: {
        SwiperSlides,
        ChallengeCard
    }
})
export default class ChallengesView extends Vue {

    challenges: VM.ChallengeVm[] = [];
    ongoingChallenges: VM.ChallengeVm[] = [];
    attendedChallenges: VM.ChallengeVm[] = [];
    closedChallenges: VM.ChallengeVm[] = [];
    selectedSlide: number = 0;
    hasInviti: boolean = false;

    beforeRouteEnter(to, from, next){
        Promise.all([
            ChallengeClient.getAllVm(),
            PortafoglioClient.getUserChallenges(store.state.loginResponse.userIdentifier),
            UserClient.checkPlayDisclaimer(store.state.loginResponse.userIdentifier),
            FriendChallengeClient.getReceivedInvites(store.state.loginResponse.userIdentifier, "")
        ])
        .then( datas => {
            next((vc: ChallengesView) => {
                vc.challenges = datas[0];
                vc.challenges.forEach(x => {
                    let challengeIndex = datas[1].findIndex(y => y.identifier == x.identifier);
                    if(challengeIndex > -1){
                        x.portafoglioIdentifier = datas[1][challengeIndex].value;
                    }
                });
                vc.ongoingChallenges = vc.challenges.filter(x => {
                    //return new Date(x.dataInizio).getTime() < new Date().getTime() && new Date(x.dataFine).getTime() > new Date().getTime();
                    return new Date(x.dataFine).getTime() >= new Date().getTime();
                });
                vc.attendedChallenges = vc.challenges.filter(x => {
                    return x.portafoglioIdentifier && new Date(x.dataFine).getTime() >= new Date().getTime();
                });
                vc.closedChallenges = vc.challenges.filter(x => new Date(x.dataFine).getTime() < new Date().getTime());
                if(datas[3].length > 0){
                    vc.hasInviti = true;
                    vc.attendedChallenges.forEach(x => {
                        let challengeIndex = datas[3].findIndex(y => y.challengeIdentifier == x.identifier);
                        if(challengeIndex > -1){
                            x.notified = true;
                        }
                    })
                }

                //se non ha visionato il disclaimer, apre modale
                if(!datas[2]){
                    ModalServices.OpenModal(DisclaimerModal, {})
                }
            });
        })
    }

    onSlideChange(index){
        this.selectedSlide = index;
        this.$nextTick(() => {
            (<HTMLElement>this.$refs.swiperSlidesContainer).scrollTop = 0;
        })
    }

    selectChallenge(item: VM.ChallengeVm){
        StorageServices.setSelectedChallenge(item);
        if(item.portafoglioIdentifier)
            this.$router.push('/challenge/' + item.identifier + '/' + item.portafoglioIdentifier + '/details')
        else
            this.$router.push('challenges/' + item.identifier)
    }

    goToAmici(){
        ModalServices.OpenModal(SelectChallengeModal, {
            challenges: this.attendedChallenges,
        })
    }

}
</script>

<style scoped>

    .challengeSelector {
        width: 100%;
        display: flex;
        justify-content: space-around;
        position: fixed;
        margin: auto;
        top: 53px;
        left: 0;
        right: 0;
        background-color: #11171d;
        z-index: 9;
        padding: 10px 15px;
        max-width: 720px;
    }

    .systemDateContainer {
        margin-top: 65px;
    }

    .swiperSlidesContainer {
        height: calc(100vh - 120px);
    }

    .selectorSlug {
        border: 1px solid #2e3238;
        border-radius: 6px;
        width: 20vw;
        text-align: center;
        color: white;
        padding: 3px 0;
        text-transform: uppercase;
        transition: border 0.3s, color 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selectorSlug.active {
        color: rgb(246, 156, 20);
        border-color: rgba(246, 156, 20, 0.7);
    }

</style>
