<template>
    <div class="text-center">
        <form @submit.prevent.stop="save" class="p-4">
            <div class="form-group">
                <label>Nome della gara</label>
                <input type="text" class="form-control" v-model="vm.name" />
            </div>
            <div class="form-group" v-if="cards.length > 0">
                <label>Seleziona partecipanti da invitare</label>
                <ranking-card-selector :cards="cards" class="userList"
                    :selected="vm.invitedUsers">
                </ranking-card-selector>
            </div>
            <div class="form-group">
                <label>La gara riparte una volta accettato l'invito</label>
                <div @click="vm.azzeraDati = !vm.azzeraDati">
                    <button type="button" class="btn btn-check" :class="{ 'active': vm.azzeraDati }">
                    </button>
                </div>
            </div>
            <button type="submit" class="btn btn-dark mt-3" :disabled="!vm.name"> 
                Salva
            </button>
        </form>
    </div>
</template>

<script lang="ts">
import { Vue, Watch } from 'vue-property-decorator';
import Component from '@/routeComponent'
import router from '@/router';
import { StorageServices } from '@/services/StorageServices'
import store from '@/store';
import * as VM from '@/viewModel';
import * as OM from '@/model';
import RankingCardSelector from '@/components/rankingCardSelector.vue';
import { FriendChallengeClient, RankingCardClient } from '@/services/Services';

@Component({
    components: {
        RankingCardSelector
    }
})
export default class CreateFriendChallenge extends Vue {

    cards: VM.RankingCardVm[] = [];
    vm: VM.CreateFriendChallengeVm = new VM.CreateFriendChallengeVm();

    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        let friendChallenge = StorageServices.getSelectedFriendChallenge();
        store.state.headerText = `<small class="text-orange small">` + challenge.titolo + `</small><p class="mb-0">` 
            + friendChallenge.name + `</p>`;
        Promise.all([
            FriendChallengeClient.getById(to.params.friendChallengeIdentifier),
            FriendChallengeClient.getInvitedPending(store.state.loginResponse.userIdentifier, to.params.friendChallengeIdentifier),
            RankingCardClient.getRankings(challenge.identifier, 'iniziogara')
        ])
        .then(xs => {
            next((vc: CreateFriendChallenge) => {
                vc.vm.challengeIdentifier = to.params.friendChallengeIdentifier;
                vc.vm.challengeName = challenge.titolo;
                vc.vm.name = xs[0].name;
                xs[0].users.forEach(x => {
                    vc.vm.invitedUsers.push(<any>{
                        identifier: x.userIdentifier,
                        value: x.userName,
                        selected: true,
                        blocked: true
                    });
                })
                xs[1].forEach(x => {
                    vc.vm.invitedUsers.push(<any>{
                        identifier: x.identifier,
                        value: x.value,
                        selected: true
                    });
                })

                vc.cards = xs[2];
            });
        })
    }

    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        next();
    }

    save(){
        FriendChallengeClient.updateFriendChallenge(this.vm)
        .then(x => {
            this.$router.back();
        })
    }

}

</script>

<style scoped>

.userList {
    height: 58vh;
    overflow-y: auto;
}

</style>