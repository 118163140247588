<template>
    <div class="registerContainer">
        <div class="text-center">
            <img src="@/img/App_icon.svg" />
            <h4 class="mt-2 text-low-white">Registrati</h4>
        </div>
        <transition name="fade" mode="out-in">
            <div class="text-low-white" v-if="pageState == ''">
                <div class="form-group">
                    <label>Nome</label>
                    <input class="form-control" type="text" v-model="vm.nome" />
                </div>
                <div class="form-group">
                    <label>Cognome</label>
                    <input class="form-control" type="text" v-model="vm.cognome" />
                </div>
                <div class="form-group">
                    <label>Email</label>
                    <input class="form-control" type="email" v-model="vm.email" />
                </div>
                <div class="form-group">
                    <label>Password</label>
                    <input class="form-control" type="password" v-model="vm.password" />
                </div>
            </div>
            <div class="text-low-white text-center" v-else-if="pageState == 'social_registered'">
                <a class="registerLink">{{$store.state.dizionario.register_email_used_by_socialLogin}}</a>
            </div>
            <div class="text-low-white text-center" v-else-if="pageState == 'email_occupied'">
                <a class="registerLink" @click="resetPassword">{{$store.state.dizionario.register_alreadyRegistered}}</a>
            </div>
            <div class="text-low-white text-center" v-else-if="pageState == 'email_sent'">
                <p>{{$store.state.dizionario.register_confirmEmail_sent}}</p>
            </div>
            <div class="text-low-white text-center" v-else-if="pageState == 'reset_mail_sent'">
                <p>{{$store.state.dizionario.register_resetPassword_email_sent}}</p>
            </div>
            <div class="text-low-white text-center" v-else-if="pageState == 'error'">
                <p>{{$store.state.dizionario.register_error}}</p>
                <a class="registerLink" @click="retry">Riprova</a>
            </div>
        </transition>

        <div class="w-100 d-flex justify-content-around">
            <button type="button" class="btn btn-dark" @click="$router.back()">Indietro</button>
            <button type="button" class="btn btn-dark" @click="registrati"
                :class="{ disabled: !canSubmit }" v-if="pageState == ''">Registrati</button>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import router from '@/router';
import store from '@/store';
import { fbRedirectPage } from '@/config';
import { AuthServices } from '@/services/AuthServices';
import { StorageServices } from '@/services/StorageServices';
import * as VM from '@/viewModel';
import { validateEmail } from '../../utility';
import { appVersion } from '@/config';

@Component
export default class Register extends Vue {

    vm: VM.RegisterVm = new VM.RegisterVm();
    pageState = '';

    created(){
        this.vm.appVersion = appVersion;
    }

    registrati(){
        if(!this.canSubmit)
            return;

        AuthServices.Register(this.vm).then( x => {
            this.pageState = x;
        }).catch( () => {
            this.pageState = 'error';
        })
    }

    get canSubmit(){
        return this.vm.nome && this.vm.cognome && validateEmail(this.vm.email) && this.vm.password;
    }

    retry(){
        this.pageState = '';
    }

    // addToSocialLogin(){
    //     if(!window.cordova)
    //         return;
    //     if(window.facebookConnectPlugin)
    //     {
    //         store.commit("showSpinner");
    //         window.facebookConnectPlugin.login(
    //             ['email','public_profile'], 
    //             (success: any) =>{
    //                 AuthServices.facebookLogin(success.authResponse.accessToken, this.vm.password)
    //                 .then(x => {
    //                     store.commit("hideSpinner");   
    //                     AuthServices.setAuthToken(x.token);
    //                     store.state.loginResponse = x;
    //                     StorageServices.setLoginResponse(x);
    //                     router.replace("/blank");
    //                 })
    //                 .catch(x => {
    //                     store.commit("hideSpinner");   
    //                 })
    //             }, 
    //             (error:any) =>{
    //                 store.commit("hideSpinner");   
    //                 console.dir(error);
    //             }
    //         );
    //     }
    // }

    resetPassword(){
        AuthServices.forgotPassword(this.vm.email)
        .then(x => {
            this.pageState = 'reset_mail_sent';
        })
        .catch(x => {
            this.pageState = 'error';
        })
    }

}
</script>
<style scoped>

    a.registerLink {
        text-decoration: underline;
    }

</style>
