<template>
    <div class="fullCard">
        <h4 class="pl-4 pt-4 title text-white">{{label}}</h4>
        <div class="chartContainer">
            <div :id="chartId" ref="chartElement">
            </div>
        </div>
        <div class="donutPallino" v-for="(item, i) in types" :key="i" v-typeclass="item.nome + 'pallino'">
            {{item.nome}}
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import Chartist from 'chartist/dist/chartist.min.js';
import { removeDiacritics } from '@/utility';

@Component
export default class DonutChartCard extends Vue {

    @Prop({
        type: [ Array ]
    }) values: any[];

    @Prop({
        type: [ Array ]
    }) types: VM.TypeForDonutChartVm[];

    @Prop({
        type: String
    }) yType: string;

    @Prop({
        type: [ String ]
    }) label: string;

    chartId: string = 'c' + (Math.random() * 10000000).toFixed(0);

    chartElement: any;
    chartist: any;
    localValues: any[];

    @Watch('values')
    onValues(next, prev){
        this.localValues = JSON.parse(JSON.stringify(this.values));
        this.plotChart();
    }

    mounted(){
        this.chartElement = this.$refs.chartElement;
        this.localValues = JSON.parse(JSON.stringify(this.values));
        this.plotChart();
    }

    plotChart(){
        this.chartElement.style.width = "100%";
        var data = {
            series: this.localValues.map((x, index) => {
                return {
                    value: x,
                    className: removeDiacritics(this.types[index].nome).toLowerCase().replace(/ /g,"-")
                }
            }),
        };
        var options = {
            donut: true,
            donutWidth: 33,
            donutSolid: true,
            startAngle: 0,
            showLabel: true,
            labelInterpolationFnc: function(value) {
                return Math.round(value) + '%';
            }
        }
        this.chartist = new Chartist.Pie('#' + this.chartId, data, options);
    }
}

</script>

<style scoped>
    .fullCard .title {
        font-size: 23px;
        color: rgb(50, 60, 71);
        font-family: 'Poppins';
    }

    .donutPallino {
        display: inline-block;
        width: 50%;
        padding-left: 50px;
        position: relative;
        color: white;
    }

    .donutPallino::before {
        content: '';
        width:  10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        left: 30px;
        top: 4px;
    }
    
</style>
