<template>
    <div class="modalWrapper text-center text-white">
        <p>Cancellazione account</p>
        <p style="font-size: 20px;">Sei sicuro di voler cancellare l'account?</p>
        <div class="d-flex justify-content-around">
            <button type="button" class="btn btn-dark" @click="$emit('close')">
                No
            </button>
            <button type="button" class="btn btn-dark" @click="callback()">
                Si
            </button>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class DeleteAccountModal extends Vue {
    
    @Prop() callback;

}
</script>
<style scoped>

    .modalWrapper{
        background: rgb(18, 24, 29);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 20px;
    }

</style>
