<template>
    <div class="opModal">
        <div class="modalHeader">
            {{text}}
        </div>
        <div class="modalBody text-low-white">
            <div class="form-group">
                <input type="number" class="form-control" v-model.number="internal" @input="sendValue" />
            </div>
        </div>
        <div class="modalFooter">
            <button type="button" class="btn btn-dark btn-large" @click="close">
                OK
            </button>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ConfirmModal extends Vue {
    
    @Prop({
        required: true
    }) text: string;

    @Prop({
        required: true
    }) value: any;

    internal: string = "";
    created(){
        this.internal = this.value;
    }

    @Prop({
        required: true
    }) callback: (val) => void;
    
    sendValue(ev){
        if(ev.target.value)
            this.callback(ev.target.value);
        else
            this.callback(0);
    }

    close(){
        this.$emit('close');
    }

}
</script>
<style scoped>

    .opModal {
        background-color: #11171d;
        padding: 20px;
        border-radius: 8px;
    }

    .modalHeader {
        color: white;
        font-size: 16px;
        margin-bottom: 20px;
    }

    .modalBody {
        margin-bottom: 20px;
    }

    .modalFooter {
        display: flex;
        justify-content: space-around;
    }

    .modalFooter button {
        width: 40%;
    }

</style>
