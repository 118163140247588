<template>
    <div class="pt-4 px-3" v-if="loaded">
        <div class="d-flex justify-content-between w-100 mb-4">
            <div>
                <h5 class="cinturaTitle">{{selectedCintura.nome}}</h5>
                <div class="progressBar">
                    <progress-bar :completed="selectedCintura.progress" :color="selectedCintura.hexColor"></progress-bar>
                </div>
            </div>
            <!-- <div>
                <button type="button" class="btn textChanger minus mr-2" @click="minusText">A</button>
                <button type="button" class="btn textChanger plus" @click="plusText">A</button>
            </div> -->
        </div>
        <!-- <div class="d-flex lightDark">
            <tema-cintura :enabled="true" :completato="temaVm.completamento" :icona="temaVm.icona"
                :cintura-color="selectedCintura.hexColor"></tema-cintura>
            <div style="width: calc(100% - 100px);">
                <small class="text-orange">{{temaVm.nome}}</small>
                <p class="text-large d-flex align-items-center">{{lezione.titolo}}</p>
            </div>
        </div> -->

        <div class="text-large">
            {{$store.state.dizionario.questionario_title}}
        </div>

        <div class="driver">
            <div class="driverPallino" :class="domandaIndex == i ? 'active': ''"
                v-for="(item, i) in lezione.questionario.domande.length" :key="i">

            </div>
        </div>

        <div class="mt-2" v-if="lezione.questionario.domande.length > 0">
            <div class="answersContainer" v-if="domandaIndex < lezione.questionario.domande.length">
                <transition name="fade" mode="out-in">
                    <domanda :key="domandaIndex" :domanda="lezione.questionario.domande[domandaIndex]" 
                        v-model="risultato.risposte[domandaIndex]" @input="next"
                        :style="'font-size:' + fontSize + 'px!important;'"></domanda>
                </transition>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Watch } from 'vue-property-decorator';
import Component from '@/routeComponent';
import PageHeader from '@/components/pageHeader.vue';
import Domanda from './domanda.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { ModalServices } from '@/services/ModalServices'
import AlertModal from '@/components/alertModal.vue';
import CinturaAdvancedModal from './cinturaAdvancedModal.vue';
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';
import TemaCintura from '@/components/temaCintura.vue';
import ProgressBar from '@/components/progressBar.vue';
import DomandeQualitaModal from './domandeQualitaModal.vue';
import { RisultatoQuestionarioClient } from '@/services/Services';

@Component({
    components: {
        PageHeader,
        Domanda,
        TemaCintura,
        ProgressBar
    }
})
export default class QuestionarioView extends Vue {

    lezione: OM.Lezione = new OM.Lezione();
    risultato: VM.RisultatoQuestionarioVm = new VM.RisultatoQuestionarioVm();
    domandaIndex: number = 0;
    hideForAnimation: boolean = false;
    buttonDisabled: boolean = true;
    loaded: boolean = false;
    temaVm: OM.TemaVm = new OM.TemaVm();
    selectedCintura: VM.CinturaWithTemiVm = new VM.CinturaWithTemiVm();

    fontSize: number = 18;

    beforeRouteEnter(to, from, next){
        next((vc: QuestionarioView) => {
            vc.lezione = StorageServices.getSelectedLezione();
            vc.risultato.lezioneIdentifier = vc.$route.params.lezioneIdentifier;
            vc.risultato.temaIdentifier = vc.lezione.temaIdentifier;
            vc.risultato.cinturaIdentifier = vc.$route.params.cinturaIdentifier;
            vc.risultato.userIdentifier = store.state.loginResponse.userIdentifier;
            vc.loaded = true;
            vc.temaVm = StorageServices.getTemaVm();
            vc.selectedCintura = StorageServices.getSelectedCintura();
        })
    }
    
    minusText(){
        if(this.fontSize > 12)
            this.fontSize -= 2;
    }

    plusText(){
        if(this.fontSize < 24)
            this.fontSize += 2;
    }

    prev(){
        this.domandaIndex--;
    }

    next(){
        if(this.risultato.risposte[this.domandaIndex]){
            this.domandaIndex++;
        }
        if(this.domandaIndex >= this.lezione.questionario.domande.length)
            this.save();
    }

    save(){
        RisultatoQuestionarioClient.save(this.risultato)
        .then( risultatiAvanzamento => {
            //this.selectedCintura.progress = risultatiAvanzamento.cinturaProgressTo;
            //store.state.risultatoQuestionario = risultatiAvanzamento;
            StorageServices.setRisultatoQuestionario(risultatiAvanzamento);
            this.$router.push('/cinture/' + this.$route.params.cinturaIdentifier + '/lezione/' + 
                this.lezione.identifier + '/risultatoQuestionario');
        })
        .catch( err => {

        })
    }

    navBack(){
        this.$router.back();
    }

}

</script>
