<template>
    <div>
        <!-- <div class="backButtonArchivio" @click="back">
            <img src="@/img/back_header.svg" />
        </div> -->
        <archivio-node v-for="(item, i) in nodes" :key="i" :node="item" @click.native="goTo(item)"></archivio-node>
    </div>
</template>

<script lang="ts">
import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import * as VM from '@/viewModel';
import store from '@/store';
import ArchivioNode from './archivioNode.vue';
import { ArchivioClient } from '@/services/Services';

@Component({
    components: {
        ArchivioNode
    }
})
export default class Archivio extends Vue {

    nodes: VM.ArchivioNodeVm[] = [];
    nodeId: string = null;
    path: string[] = [];

    beforeRouteEnter(to, from, next){
        let nodeId = to.params.nodeIdentifier;
        if(nodeId == "0")
            nodeId = null;
        store.state.headerText = `<p class="mb-0">` + store.state.dizionario['menu_5'] + `</p>`
        ArchivioClient.getChildrenOf(nodeId, store.state.loginResponse.userIdentifier)
        .then(x => {
            next((vc: Archivio) => {
                vc.nodes = x;
            });
        })
    }
    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        next();
    }
    beforeRouteUpdate(to, from, next) {
        let nodeId = to.params.nodeIdentifier;
        if(nodeId == "0")
            nodeId = null;
        ArchivioClient.getChildrenOf(nodeId, store.state.loginResponse.userIdentifier)
        .then(x => {
            this.nodes = x;
            next();
        })
    }

    back(){
        let lastTitle = this.path.pop();
        this.$router.back();
    }
    goTo(item: VM.ArchivioNodeVm){
        // this.path.push(item.name);
        if(item.isFolder) {
            this.$router.push('/archivio/' + item.identifier);
        } else {
            ArchivioClient.addReadBy(item.identifier, store.state.loginResponse.userIdentifier);
            this.$router.push('/archivioDetail/' + item.identifier);
        }
    }

}

</script>

<style scoped>

.backButtonArchivio {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 55px;
    line-height: 55px;
    z-index: 99;
    text-align: center;
}

</style>
