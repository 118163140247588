<template>
    <div>
        <swiper-slides class="mb-3" v-if="chartData" @slidechange="onSlideChange" :slide-index="slideIndex">
            <div class="swiper-slide">
                <analysis-slide key="performance" :serie="selectedAsset ? selectedAsset.value.performance : chartData.performance" 
                    :title="$store.state.dizionario.analysis_performance_title" chart-type="perc" :filter="filter"></analysis-slide>
            </div>
            <div class="swiper-slide">
                <analysis-slide key="controValore" :serie="selectedAsset ? selectedAsset.value.controValore : chartData.controValore" 
                    :title="$store.state.dizionario.analysis_controvalore_title" chart-type="k" :filter="filter"></analysis-slide>
            </div>
            <div class="swiper-slide">
                <analysis-slide key="utiliPerdite" :serie="selectedAsset ? selectedAsset.value.utiliPerdite : chartData.utiliPerdite" 
                    :title="$store.state.dizionario.analysis_utili_perdite_title" chart-type="k" :filter="filter"></analysis-slide>
            </div>
            <div class="swiper-slide">
                <analysis-slide key="peso" :serie="selectedAsset ? selectedAsset.value.peso : chartData.peso" 
                    :title="$store.state.dizionario.analysis_peso_title" chart-type="perc" :filter="filter"></analysis-slide>
            </div>
            <div class="swiper-slide">
                <analysis-slide key="volatilita20" :serie="selectedAsset ? selectedAsset.value.volatilita20 : chartData.volatilita20" 
                    :title="$store.state.dizionario.analysis_volatilita_title" chart-type="perc" :filter="filter"></analysis-slide>
            </div>
            <div class="swiper-slide">
                <analysis-slide key="commissioni" :serie="selectedAsset ? selectedAsset.value.commissioni : chartData.commissioni" 
                    :title="$store.state.dizionario.analysis_commissioni_title" chart-type="k" :filter="filter"></analysis-slide>
            </div>
            <div class="swiper-slide">
                <analysis-slide key="commissioniPerc" :serie="selectedAsset ? selectedAsset.value.commissioniPerc : chartData.commissioniPerc" 
                    :title="$store.state.dizionario.analysis_commissioniPerc_title" chart-type="perc" :filter="filter"></analysis-slide>
            </div>
        </swiper-slides>
        <!-- <swiper-slides class="mb-3" v-if="selectedAsset" @slidechange="onSlideChange" :slide-index="slideIndex">
            <div class="swiper-slide">
                <div class="px-3 mt-4 position-relative">
                    <div class="openChart" @click="openChart(selectedAsset.value.performance,  $store.state.dizionario.analysis_performance_title , 'perc')">
                        <span class="text-low-white">{{$store.state.dizionario.analysis_open_button}}</span>
                        <img src="@/img/round_arrow_right.svg" />
                    </div>
                    <chart-card :values="selectedAsset.value.performance" :label=" $store.state.dizionario.analysis_performance_title" y-type="%"></chart-card>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="px-3 mt-4 position-relative">
                    <div class="openChart" @click="openChart(selectedAsset.value.controValore, $store.state.dizionario.analysis_controvalore_title, 'k')">
                        <span class="text-low-white">{{$store.state.dizionario.analysis_open_button}}</span>
                        <img src="@/img/round_arrow_right.svg" />
                    </div>
                    <chart-card :values="selectedAsset.value.controValore" :label="$store.state.dizionario.analysis_controvalore_title" y-type="k"></chart-card>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="px-3 mt-4 position-relative">
                    <div class="openChart" @click="openChart(selectedAsset.value.utiliPerdite, $store.state.dizionario.analysis_utili_perdite_title, 'k')">
                        <span class="text-low-white">{{$store.state.dizionario.analysis_open_button}}</span>
                        <img src="@/img/round_arrow_right.svg" />
                    </div>
                    <chart-card :values="selectedAsset.value.utiliPerdite" :label="$store.state.dizionario.analysis_utili_perdite_title" y-type="k"></chart-card>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="px-3 mt-4 position-relative">
                    <div class="openChart" @click="openChart(selectedAsset.value.peso, $store.state.dizionario.analysis_peso_title, 'perc')">
                        <span class="text-low-white">{{$store.state.dizionario.analysis_open_button}}</span>
                        <img src="@/img/round_arrow_right.svg" />
                    </div>
                    <chart-card :values="selectedAsset.value.peso" :label="$store.state.dizionario.analysis_peso_title" y-type="%"></chart-card>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="px-3 mt-4 position-relative">
                    <div class="openChart" @click="openChart(selectedAsset.value.volatilita20, $store.state.dizionario.analysis_volatilita_title, 'perc')">
                        <span class="text-low-white">{{$store.state.dizionario.analysis_open_button}}</span>
                        <img src="@/img/round_arrow_right.svg" />
                    </div>
                    <chart-card :values="selectedAsset.value.volatilita20" :label="$store.state.dizionario.analysis_volatilita_title" y-type="%"></chart-card>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="px-3 mt-4 position-relative">
                    <div class="openChart" @click="openChart(selectedAsset.value.commissioni, $store.state.dizionario.analysis_commissioni_title, 'perc')">
                        <span class="text-low-white">{{$store.state.dizionario.analysis_open_button}}</span>
                        <img src="@/img/round_arrow_right.svg" />
                    </div>
                    <chart-card :values="selectedAsset.value.commissioni" :label="$store.state.dizionario.analysis_commissioni_title" y-type="k"></chart-card>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="px-3 mt-4 position-relative">
                    <div class="openChart" @click="openChart(selectedAsset.value.commissioniPerc, $store.state.dizionario.analysis_commissioniPerc_title, 'perc')">
                        <span class="text-low-white">{{$store.state.dizionario.analysis_open_button}}</span>
                        <img src="@/img/round_arrow_right.svg" />
                    </div>
                    <chart-card :values="selectedAsset.value.commissioniPerc" :label="$store.state.dizionario.analysis_commissioniPerc_title" y-type="%"></chart-card>
                </div>
            </div>
        </swiper-slides> -->
        <asset-type-slugs class="my-3" :slugs="periodFilters" @select="setPeriodFilter"></asset-type-slugs>
        <div class="resocontoContainer">
            <div class="resoconto" :class="{ 'resocontoFilter': i == 0 && orangeTop }"
                v-for="(item, i) in resoconti" :key="item.key + i" @click="clickFunction(item.key, i)">
                <div class="text-light" :class="{ 'pl-4': orangeTop && i > 0 }">
                    <asset-type-icon class="mr-2" :label="!orangeTop && i == 0 ? 'portafoglio' : (filter.assetType || item.key)" 
                        v-if="!orangeTop || i == 0"></asset-type-icon>
                    <span :style="i == 0 ? 'font-weight: bolder;' : ''">
                        {{!orangeTop && i == 0 ? $store.state.dizionario.analysis_label_totale_portafoglio : item.key}}
                    </span>
                </div>
                <div class="d-flex justify-content-end">
                    <div class="nowrap" v-color="item.value[resocontoProperty][item.value[resocontoProperty].length - 1].value" 
                        v-if="resocontoPropertyType == '%'">
                        {{item.value[resocontoProperty][item.value[resocontoProperty].length - 1].value | perc(2)}}%
                    </div>
                    <div class="nowrap" v-color="item.value[resocontoProperty][item.value[resocontoProperty].length - 1].value"
                        v-else>
                        {{item.value[resocontoProperty][item.value[resocontoProperty].length - 1].value | currency(0)}} €
                    </div>
                    <div class="ml-2">
                        <i class="fa fa-chevron-up" v-if="i == 0"></i>
                        <i class="fa fa-chevron-down" v-else></i>
                    </div>
                </div>
            </div>
            <div class="resoconto" v-for="(item, i) in assets" :key="item.key + i" @click="selectAsset(item)">
                <div class="text-light pl-4">
                    {{item.key}}
                </div>
                <div class="d-flex justify-content-end">
                    <div class="nowrap" v-color="item.value[resocontoProperty][item.value[resocontoProperty].length - 1].value"
                        v-if="resocontoPropertyType == '%'">
                        {{item.value[resocontoProperty][item.value[resocontoProperty].length - 1].value | perc(2)}}%
                    </div>
                    <div class="nowrap" v-color="item.value[resocontoProperty][item.value[resocontoProperty].length - 1].value"
                        v-else>
                        {{item.value[resocontoProperty][item.value[resocontoProperty].length - 1].value | currency(0)}} €
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';
import SwiperSlides from '@/components/swiperSlides.vue';
import ChartCard from '@/components/chartCard.vue';
import * as Enum from '@/enum';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import AssetTypeIcon from '@/components/assetTypeIcon.vue';
import AnalysisSlide from './analysisSlide.vue';
import AssetTypeSlugs from '@/components/assetTypeSlugs.vue';
import { PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        SwiperSlides,
        ChartCard,
        AssetTypeIcon,
        AnalysisSlide,
        AssetTypeSlugs
    }
})
export default class AnalysisView extends Vue {

    resoconti: OM.KeyValuePairOfStringAndResocontoPortafoglio[] = [];
    filter: OM.AssetFilterVm = new OM.AssetFilterVm();
    assets: OM.KeyValuePairOfStringAndResocontoPortafoglio[] = [];
    selectedAsset: OM.KeyValuePairOfStringAndResocontoPortafoglio = null;
    resocontoLiquidity: OM.KeyValuePairOfStringAndResocontoPortafoglio = null;
    resocontoWallet: OM.KeyValuePairOfStringAndResocontoPortafoglio = null;
    resocontoProperty: string = 'performance';
    resocontoPropertyType: string = '%';
    orangeTop: boolean = true;
    slideIndex: number = 0;

    periodFilters: string[] = [];

    chartData: OM.ResocontoPortafoglio = null;

    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        store.state.headerText = `<p class="mb-0">` + challenge.titolo + `</p>`;
        next();
    }

    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        next();
    }

    created(){
        this.filter.portafoglioIdentifier = this.$route.params.portafoglioIdentifier;
        this.filter.assetType = this.$route.params.assetType || '';
        this.filter.macroClass = this.$route.params.macroClass || '';
        this.filter.microClass = this.$route.params.microClass || '';

        this.resocontoProperty = store.state.analysisProperty || 'performance';
        this.resocontoPropertyType = store.state.analysisPropertyType || '%';
        if(this.resocontoProperty == "performance"){
            this.slideIndex = 0;
        } else if(this.resocontoProperty == "controValore") {
            this.slideIndex = 1;
        } else if(this.resocontoProperty == "utiliPerdite"){
            this.slideIndex = 2;
        } else if(this.resocontoProperty == "peso"){
            this.slideIndex = 3;
        } else if(this.resocontoProperty == "volatilita20"){
            this.slideIndex = 4;
        } else if(this.resocontoProperty == "commissioni"){
            this.slideIndex = 5;
        } else if(this.resocontoProperty == "commissioniPerc"){
            this.slideIndex = 6;
        }
        
        this.applyFilter()
        .then(x => {
            this.periodFilters.push(store.state.dizionario['analysis_period_filter_fromStart']);
            this.periodFilters.push(store.state.dizionario['analysis_period_filter_quarterToDate']);
            this.periodFilters.push(store.state.dizionario['analysis_period_filter_monthToDate']);
            this.periodFilters.push(store.state.dizionario['analysis_period_filter_weekToDate']);
            this.periodFilters.push(store.state.dizionario['analysis_period_filter_lastWeek']);
            this.periodFilters.push(store.state.dizionario['analysis_period_filter_lastMonth']);
            this.periodFilters.push(store.state.dizionario['analysis_period_filter_lastQuarter']);
        });
    }

    applyFilter(){
        let proms = [];
        proms.push(PortafoglioClient.getAnalysis(this.filter));
        if(this.filter.assetType != "" && this.filter.macroClass != "" && this.filter.microClass != ""){
            proms.push(PortafoglioClient.getAssetsForAnalysis(this.filter));
        }
        return Promise.all(proms)
        .then(data => {
            this.resoconti = data[0];
            if(this.filter.assetType == "" && this.filter.macroClass == "" && this.filter.microClass == ""){
                this.orangeTop = false;
                this.resocontoWallet = this.resoconti[0];
                StorageServices.setResocontoWallet(this.resocontoWallet);
                this.chartData = this.resocontoWallet.value;
            } else {
                this.chartData = this.resoconti[0].value;
            }
            this.resocontoWallet = StorageServices.getResocontoWallet();
            if(data[1])
                this.assets = data[1];
        })
    }

    setPeriodFilter(input){
        if(input == store.state.dizionario['analysis_period_filter_fromStart']){
            this.filter.resocontoType = "";
        } else if(input == store.state.dizionario['analysis_period_filter_quarterToDate']){
            this.filter.resocontoType = Enum.ResocontoType.QuarterToDate;
        } else if(input == store.state.dizionario['analysis_period_filter_monthToDate']){
            this.filter.resocontoType = Enum.ResocontoType.MonthToDate;
        } else if(input == store.state.dizionario['analysis_period_filter_weekToDate']){
            this.filter.resocontoType = Enum.ResocontoType.WeekToDate;
        } else if(input == store.state.dizionario['analysis_period_filter_lastWeek']){
            this.filter.resocontoType = Enum.ResocontoType.LastWeek;
        } else if(input == store.state.dizionario['analysis_period_filter_lastMonth']){
            this.filter.resocontoType = Enum.ResocontoType.LastMonth;
        } else if(input == store.state.dizionario['analysis_period_filter_lastQuarter']){
            this.filter.resocontoType = Enum.ResocontoType.LastQuarter;
        }
        this.applyFilter();
    }

    clickFunction(val, i){
        if(val == 'Portafoglio')
            return;
        if(i == 0 && this.orangeTop)
            this.$router.back();
        else
            this.addToFilter(val);
    }

    addToFilter(value){
        if(this.filter.assetType == ''){
            this.$router.push('analysis/' + value);
        } else if(this.filter.macroClass == '') {
            this.$router.push(this.filter.assetType + '/' + value);
        } else if(this.filter.microClass == '') {
            this.$router.push(this.filter.macroClass + '/' + value);
        } else {
            return;
        }
    }

    selectAsset(item: OM.KeyValuePairOfStringAndResocontoPortafoglio){
        this.selectedAsset = item;
    }

    onSlideChange(index){
        if(index == 0){
            this.resocontoProperty = "performance";
            this.resocontoPropertyType = '%';
        } else if(index == 1) {
            this.resocontoProperty = "controValore";
            this.resocontoPropertyType = 'k';
        } else if(index == 2){
            this.resocontoProperty = "utiliPerdite";
            this.resocontoPropertyType = 'k';
        } else if(index == 3){
            this.resocontoProperty = "peso";
            this.resocontoPropertyType = '%';
        } else if(index == 4){
            this.resocontoProperty = "volatilita20";
            this.resocontoPropertyType = '%';
        } else if(index == 5){
            this.resocontoProperty = "commissioni";
            this.resocontoPropertyType = 'k';
        } else if(index == 6){
            this.resocontoProperty = "commissioniPerc";
            this.resocontoPropertyType = '%';
        }
        store.state.analysisProperty = this.resocontoProperty;
        store.state.analysisPropertyType = this.resocontoPropertyType;
    }

    openChart(data, label, yType){
        label = label.replace('%', 'perc');
        StorageServices.setChartData(data);
        let title = "";
        if(this.filter.microClass != '')
            title = this.filter.microClass;
        else if(this.filter.macroClass != '')
            title = this.filter.macroClass;
        else if(this.filter.assetType != '')
            title = this.filter.assetType;
        else
            title = this.$store.state.dizionario.analysis_label_totale_portafoglio;
        this.$router.push('/horizontalChart/' + label + '/' + title + '/' + yType);
    }
}

</script>

<style scoped>

    .resoconto {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        font-size: 16px;
        align-items: center;
        color: white;
    }

    .resoconto:nth-child(odd) {
        background-color: #2d3236;
    }
    .resoconto:nth-child(even) {
        background-color: #192128;
    }

    .resoconto.resocontoFilter {
        background-color: rgba(246, 156, 20, 0.1);
    }
    
    .verticalLine {
        position: absolute;
        height: 36px;
        width: 2px;
        background: rgba(246, 156, 20);
        margin: auto;
        left: 0;
        right: 0;
        margin-top: 15px;
    }

</style>
