<template>
    <div class="modalWrapper">
        <div class="modalHeader">
            {{$store.state.dizionario.play_disclaimer_modalTitle}}
        </div>
        <div class="modalBody text-low-white">
            <div v-html="$store.state.dizionarioHtml.disclaimer_play_area"></div>
        </div>
        <div class="modalFooter">
            <div class="mb-4 text-low-white">
                <button type="button" class="btn btn-check mr-2" :class="{ active: hoLetto }" @click="hoLetto = !hoLetto">
                </button>
                {{$store.state.dizionario.play_disclaimer_ho_letto}}
            </div>
            <div class="d-flex justify-content-around">
                <button type="button" class="btn btn-dark btn-large" @click="denyFunction">
                    {{$store.state.dizionario.play_disclaimer_deny}}
                </button>

                <button type="button" class="btn btn-dark btn-large" :class="{ disabled: !hoLetto }" @click="confirmFunction">
                    {{$store.state.dizionario.play_disclaimer_accept}}
                </button>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import store from '@/store';
import { UserClient } from '@/services/Services';

@Component
export default class ConfirmModal extends Vue {

    hoLetto: boolean = false;

    denyFunction(){
        this.$router.back();
        this.$emit('close');
    }
    
    confirmFunction(){
        if(!this.hoLetto)
            return;
        UserClient.acceptPlayDisclaimer(store.state.loginResponse.userIdentifier);
        this.$emit('close');
    }
}
</script>
<style scoped>

    .modalWrapper{
        background: rgb(18, 24, 29);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        text-align: center;
    }

    .modalHeader {
        color: white;
        font-size: 16px;
        margin-bottom: 20px;
    }

    .modalBody {
        margin-bottom: 20px;
    }

    .modalFooter button {
        width: 40%;
    }

</style>
