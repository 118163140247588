<template>
    <div class="rankingCard" :class="item.isSelf ? 'userLogged':''">
        <div class="leftSide">
            <div class="leftSideContainer positionContainer">{{position}}</div>
            <div class="leftSideContainer">
                <img :src="require('@/img/' + beltPath)" />
            </div>
        </div>
        <div class="info">
            <div class="upperRow">
                <div class="playerName">
                    <div class="flag itaFlag mr-1"></div>
                    {{item.playerName}}
                </div>
                <div>
                    <div class="mr-1" :class="{ 'triangleUp': item.vsPrevious == 1, 'triangleDown': item.vsPrevious == -1 }"></div>
                    <span class="performance" v-color="item.performance">
                        {{item.performance | perc(2)}}%
                    </span>
                </div>
            </div>
            <div class="lowerRow">
                <div class="lowerBlock">
                    <span v-if="item.premiUser.length == 0">Nessun premio</span>
                    <table class="premiTable" v-else>
                        <tr>
                            <td></td>
                            <td><div class="pallino gold"></div></td>
                            <td><div class="pallino silver"></div></td>
                            <td><div class="pallino bronze"></div></td>
                        </tr>
                        <tr v-for="(item, i) in item.premiUser" :key="i">
                            <td class="text-left text-grey pl-2">{{item.tipoPremio}}</td>
                            <td>{{item.podioUno}}</td>
                            <td>{{item.podioDue}}</td>
                            <td>{{item.podioTre}}</td>
                        </tr>
                    </table>
                </div>
                <div class="lowerBlock right">
                    <div>
                        <img src="@/img/ranking_learning.svg" />
                        <span class="text-low-white">{{item.punteggioLearning}}</span>
                    </div>
                    <div>
                        <img src="@/img/ranking_gaming.svg" />
                        <span class="text-low-white">{{item.punteggioGaming}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as VM from '@/viewModel';
import { beltColorToSvg } from '@/utility'

@Component
export default class RankingCard extends Vue {

    @Prop({
        type: [Object, VM.RankingCardVm]
    }) item: VM.RankingCardVm;

    @Prop() position: number;

    get beltPath(){
        return beltColorToSvg(this.item.coloreCintura);
    }
}

</script>

<style scoped>

    .rankingCard {
        border-radius: 6px;
        background: rgb(34, 44, 52);
        width: 95%;
        margin: 10px auto;
        position: relative;
        display: flex;
    }
    .rankingCard.userLogged {
        border: 2px solid rgb(246, 156, 20);
        box-sizing: content-box;
    }

    .rankingCard .leftSide {
        display: flex;
        flex-direction: column;
        width: 55px;
    }
    .rankingCard .leftSide .leftSideContainer {
        width: 55px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
        .rankingCard .positionContainer {
            color: white;
            font-size: 30px;
            font-family: 'Poppins-Medium';
            border-bottom: 1px solid rgb(12, 16, 21);
        }

    .rankingCard .info {
        width: 100%;
        border-left: 1px solid rgb(12, 16, 21);
    }

    .info .upperRow {
        height: 36px;
        width: 100%;
        border-bottom: 1px solid rgb(12, 16, 21);
        padding: 8px;
        display: flex;
        justify-content: space-between;
    }

    .info span.performance {
        font-size: 14px;
    }

    .info .upperRow .playerName {
        font-family: 'Poppins-Medium';
        font-size: 16px;
        display: flex;
        align-items: center;
    }

    .lowerRow {
        display: flex;
        justify-content: space-around;
        height: 74px;
        align-items: center;
    }
    .lowerBlock {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .lowerBlock.right {
        justify-content: space-around
    }
    .lowerBlock.right div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .lowerBlock.right img {
        margin-bottom: 6px;
    }

    table.premiTable {
        width: 100%;
        text-align: center;
    }

    .pallinoColor {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
    }

    .flag {
        width: 24px;
        height: 14px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: inline-block;
    }
    .itaFlag {
        background-image: url('../img/itaFlag.png');
    }
    .fraFlag {
        background-image: url('../img/fraFlag.png');
    }
    .spaFlag {
        background-image: url('../img/spainFlag.png');
    }
    .gerFlag {
        background-image: url('../img/deutchFlag.png');
    }

</style>