<template>
    <div class="modalWrapper text-center text-white">
        <p>Cancellazione ordine</p>
        <h5 class="text-low-white">{{ordine.strumento}}</h5>
        <p :class="ordine.tipoOrdine == 1 ? 'text-green' : 'text-red'">{{ordine.tipoOrdine == 1 ? 'Buy' : 'Sell'}}</p>
        <p style="font-size: 20px;">Sei sicuro di voler cancellare l'ordine?</p>
        <div class="d-flex justify-content-around">
            <button type="button" class="btn btn-dark" @click="$emit('close')">
                No
            </button>
            <button type="button" class="btn btn-dark" @click="deleteOrder">
                Si
            </button>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';
import { OrdineClient, PortafoglioClient } from '@/services/Services';

@Component
export default class DeleteOrderModal extends Vue {
    
    @Prop({
        type: [Object, VM.OrdinePendingVm]
    }) ordine: VM.OrdinePendingVm;
    
    @Prop({
        type: String,
        required: true
    }) portafoglioIdentifier: string;
    
    deleteOrder(){
        OrdineClient.delete(this.ordine.identifier)
        .then(x => {
            PortafoglioClient.getPortafoglioStatusWithSwitch({
                portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
                creaSwitchVm: null
            })
            .then(x => {
                store.state.portafoglioStatus = x;
                StorageServices.setPortafoglioStatus(x);
            });
            this.$emit('close');
        });
    }

}
</script>
<style scoped>

    .modalWrapper{
        background: rgb(18, 24, 29);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 20px;
    }

</style>
