<template>
    <div class="callToButtonBg" :style="relative ? 'position: relative;' : ''">
        <button type="button" class="callToButton" @click="$emit('click')">
            <slot></slot>
            <i class="fa fa-chevron-right callToIcon"></i>
        </button>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CallToButton extends Vue {

    @Prop({
        default: false,
        required: false
    }) relative: boolean;

}
</script>

<style scoped>

    .callToButtonBg {
        position: fixed;
        z-index: 9;
        left: 0;
        right: 0;
        bottom: 0px;
        width: 100%;
        background-color: rgb(18, 24, 29);
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.callToButton {
        height: 30px;
        background-color: transparent;
        color: rgb(246, 156, 20);
        border-radius: 15px;
        border: 1.5px solid rgba(246, 156, 20, 0.7);
        text-align: left;
        padding-left: 10px;
        padding-right: 20px;
        position: relative;
        max-width: 720px;
        width: 100%;
    }

    .callToIcon {
        margin: 0 4px;
        position: absolute;
        right: 4px;
        top: 8px;
    }

</style>
