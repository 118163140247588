<template>
    <div>
        <div class="" v-for="(tema, i) in temiList" :key="i">
            <div class="" :id="'heading'+ (i+1)">
                <h5 class="mb-0">
                    <button class="btn btn-link btnTema" data-toggle="collapse" :data-target="'#collapse' + (i+1)" aria-expanded="true" :aria-controls="'collapse' + (i+1)">
                    <i class="fas fa-check-circle"></i>
                    <!-- <i class="fas fa-stream"></i> -->
                    {{tema.nome}}
                    </button>
                </h5>
            </div>
            <div :id="'collapse' + (i+1)" class="collapse" :class="i == index ? 'show' : ''" :aria-labelledby="'heading'+ (i+1)" data-parent="#accordion">
                <div class="card-body themeListWrapper">
                    <ul>
                        <li v-for="(lezione, i) in lezioniList" :key="i"  @click="navToLezione(lezione.identifier)">
                            {{lezioniList[i].titolo}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import router from '@/router';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Component
export default class accordionItem extends Vue {
    
    @Prop() temiList: OM.Tema[];
    @Prop() lezioniList: OM.Lezione[];
    @Prop() index: number;
    
    created(){

        

    }

    navToLezione(lezioneId: string){
        this.$router.push('/cinture/lezione/' + lezioneId);
    }

}
</script>
<style scoped>

.btnTema {
    font-weight: 600;
    color: #000;
    background-color: transparent;
}

.btnTema:hover, .btnTema:focus {
    color: #000;
    text-decoration: none;
}

.btnTema i {
    margin: 5px;
}

.themeListWrapper {
    box-shadow: 2px 5px 17px 2px rgba(181,179,181,0.3);
}

</style>
