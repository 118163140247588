<template>
    <div class="h-100">
        <div class="d-flex justify-content-between align-items-center" style="height: 50px;">
            <div class="d-flex pl-3">
                <h5>
                    {{$route.params.label}}
                </h5>
            </div>
        </div>
        <mini-spinner v-if="datas == null"></mini-spinner>
        <div v-else style="overflow: auto; width: 100%;">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <td>Anno</td>
                        <td>Jan</td>
                        <td>Feb</td>
                        <td>Mar</td>
                        <td>Apr</td>
                        <td>May</td>
                        <td>Jun</td>
                        <td>Jul</td>
                        <td>Aug</td>
                        <td>Sep</td>
                        <td>Oct</td>
                        <td>Nov</td>
                        <td>Dec</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, i) in datas" :key="i">
                        <td>{{data.year}}</td>
                        <td class="pl-2 text-low-white" v-for="(item, j) in data.monthValues" :key="j" v-color="item">{{item | perc(1)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script lang="ts">
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import MiniSpinner from '@/components/miniSpinner.vue';
import { AssetClient } from '@/services/Services';

@Component({
    components: {
        MiniSpinner
    }
})
export default class PeriodiHorizontalTable extends Vue {

    datas: VM.AssetPerformanceByMonthVm[] = null;

    beforeRouteEnter(to, from, next){
        store.state.headerText = `<small class="text-orange small">` + 
            to.params.label + `</small>`;
        next();
    }
    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        if(window.cordova)
            (<any>screen).orientation.lock('portrait');
        next();
    }

    mounted(){
        AssetClient.getPerformanceTableOfBenchmark(this.$route.params.assetIdentifier)
        .then(x => {
            this.datas = x;
        })
        if(window.cordova)
            (<any>screen).orientation.lock('landscape');
    }

}
</script>

<style scoped>
.table tbody td:first-of-type {
    color: white !important;
    border-right: 1px solid #dee2e6;
}

.table tbody td:last-of-type {
    padding-right: 8px;
}

.table.table-striped {
    table-layout: fixed;
    margin-bottom: 0;
}

.table.table-striped td {
    width: 17vw;
}

.table.table-striped thead tr {
    display: block;
    position: relative;
}

.table.table-striped tbody {
    display: block;
    width: 100%;
    height: calc(100vh - 144px);
    overflow-y: auto;
}
</style>
