<template>
    <div class="modalWrapper p-4">
        <h5>{{$store.state.dizionario.seleziona_la_gara}}</h5>
        <div class="list">
            <div class="listItem" v-if="challenges.length == 0">
                {{$store.state.dizionario.devi_partecipare_a_una_gara_per_partecipare_con_gli_amici}}
            </div>
            <div class="listItem" v-for="(item, i) in challenges" :class="{ 'notified': item.notified }" 
                :key="i" @click="goTo(item)">
                {{item.titolo}}
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { StorageServices } from '@/services/StorageServices';
import * as VM from '@/viewModel'

@Component
export default class SelectChallengeModal extends Vue {

    @Prop() challenges: VM.ChallengeVm[];

    goTo(item: VM.ChallengeVm){
        StorageServices.setSelectedChallenge(item);
        this.$emit('close');
        this.$router.push('/challenge/' + item.identifier + '/' + item.portafoglioIdentifier + '/manageFriendChallenges')
    }

}
</script>

<style scoped>

    .modalWrapper{
        background: rgb(18, 24, 29);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        text-align: center;
    }

</style>
