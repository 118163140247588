<template>
    <div :class="{ 'd-none': modalOpened }">
        <div class="hideScrollBarX">
            <div class="slugsContainer">
                <div class="slug" :class="{ active: filter.periodo == 'iniziogara' }" @click="filter.periodo = 'iniziogara'">
                    {{$store.state.dizionario.ranking_filter_inizio_gara}}
                </div>
                <div class="slug" :class="{ active: filter.periodo == 'trimestre' }" @click="filter.periodo = 'trimestre'">
                    {{$store.state.dizionario.ranking_filter_ultimo_trimestre}}
                </div>
                <div class="slug" :class="{ active: filter.periodo == 'mese' }" @click="filter.periodo = 'mese'">
                    {{$store.state.dizionario.ranking_filter_mese}}
                </div>
            </div>
        </div>
        <div class="slugsContainer text-center" v-if="filtered">
            <div class="slug text-orange" @click="resetFilter">
                {{$store.state.dizionario.ranking_filter_resetLabel}}
            </div>
        </div>
        <div v-for="(person, index) in people" :key="index">
            <ranking-card :item="person" :position="index + 1"
                :ref="person.isSelf ? 'scrollHere': 'card' + index"></ranking-card>
        </div>
    </div>
</template>

<script lang="ts">
import router from '@/router';
import { Vue, Watch } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import RankingCard from '@/components/rankingCard.vue';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import { ModalServices } from '@/services/ModalServices';
import RankingFilterModal from './rankingFilterModal.vue';
import { RankingCardClient } from '@/services/Services';

@Component({
    components: {
        RankingCard
    }
})
export default class RankingView extends Vue {

    people: VM.RankingCardVm[] = [];
    filter: VM.RankingFilterVm = new VM.RankingFilterVm();
    modalOpened: boolean = false;

    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        store.state.headerText = `<p class="mb-0">` + challenge.titolo + `</p>`;
        RankingCardClient.getRankings(challenge.identifier, 'iniziogara')
        .then(x => {
            next((vc: RankingView) => {
                vc.people = x;
                vc.filter.periodo = 'iniziogara';
                vc.filter.challengeIdentifier = challenge.identifier;
                store.state.headerButtons.second = {
                    imgUrl: './img/Filters.svg',
                    onClick: vc.openFilterModal
                };
                store.state.headerButtons.third = {
                    imgUrl: './img/friendchallenge.svg',
                    onClick: vc.openFriendChallenges
                }
            });
        })
    }

    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        store.state.headerButtons.second = null;
        store.state.headerButtons.third = null;
        next();
    }

    @Watch('filter.periodo')
    onFilterChange(next, prev){
        this.applyFilter();
    }

    mounted(){
        setTimeout(() => {
            let scrollTo = this.$refs.scrollHere ? (<any>this.$refs.scrollHere)[0].$el : null;
            if(scrollTo){
                scrollTo.scrollIntoView({
                    behavior: "instant",
                    block: "center",
                    inline: "center"
                });
            }
        }, 50)
    }

    applyFilter(){
        RankingCardClient.getRankingsWithFilter(this.filter)
        .then(x => {
            this.people = x;
        })
    }

    openFriendChallenges(){
        this.$router.push('manageFriendChallenges');
    }

    get filtered(){
        return this.filter.fasciaDiEta || this.filter.statoLavorativo || this.filter.conoscenzaFinanziaria || 
                this.filter.areaGeo1 || this.filter.areaGeo2 || this.filter.areaGeo3;
    }

    resetFilter(){
        let prevs = { periodo: this.filter.periodo, challengeIdentifier: this.filter.challengeIdentifier };
        this.filter = new VM.RankingFilterVm();
        this.filter.periodo = prevs.periodo;
        this.filter.challengeIdentifier = prevs.challengeIdentifier;
        this.applyFilter();
    }

    openFilterModal(){
        this.modalOpened = true;
        ModalServices.OpenModal(RankingFilterModal,
        {
            vm: this.filter,
        }, () => {
            this.modalOpened = false;
            this.applyFilter();
        });
    }
}

</script>

<style scoped>

</style>