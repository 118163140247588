<template>
    <div class="appHeader">
        <div class="customContainer">
            <div class="headerLeft">
                <slot name="left">
                </slot>
            </div>
            <div class="headerCenter">
                <div class="pageTitle">
                    <slot>
                        {{pageTitle}}
                    </slot>
                </div>
            </div>
            <div class="headerRight">
                <slot name="right">
                </slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';

@Component
export default class PageHeader extends Vue {
    pageTitle: string = "";
    created(){
        this.pageTitle = this.$route.meta.title;
    }
}

</script>
