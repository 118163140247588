<template>
    <div>
        <wallet-bar class="fixedWalletBar" :refresh="false"></wallet-bar>
        <div class="px-3 walletBarPadding mt-3">
            <donut-chart-card class="pb-4" :values="donutSerie" :types="donutTypes" 
                :label="$store.state.dizionario.wallet_donut_chart_title" y-type="%"></donut-chart-card>
        </div>
        <asset-type-slugs class="my-3" :slugs="types" @select="filterByType"></asset-type-slugs>
        <div class="assetList">
            <div class="assetItemBlock" v-for="(item, i) in switches" :key="'s'+i">
                <div class="assetItem p-3" @click="slideAsset(item)" 
                    :class="{ slided2: item.selected && item.deletable, slided: item.selected && !item.deletable }">
                    <div class="imageContainer">
                        <img src="@/img/switch.svg" />
                    </div>
                    <div class="info">
                        <h2 class="w-80">Switch #{{i + 1}}</h2>
                        <div class="d-flex">
                            <div class="text-left mr-4">
                                <small class="text-low-white">{{$store.state.dizionario.wallet_list_date_label}}</small>
                                <p class="mb-0 text-white">{{item.orders[0].dataOrdine | date("YYYY-MM-DD")}}</p>
                            </div>
                            <div class="text-left mr-4">
                                <small class="text-low-white">{{$store.state.dizionario.wallet_list_lastOrder_label}}</small>
                                <p class="mb-0 text-white">{{item.orders[item.orders.length-1].dataMovimento | date("YYYY-MM-DD")}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sottoAssetItem">
                    <button type="button" class="btn btn-danger" @click="deleteSwitch(item, i)" v-if="item.deletable">
                        {{$store.state.dizionario.wallet_cancelOrder_label}}
                    </button>
                    <button type="button" class="btn btn-switch" @click="openSwitchDetail(item.orders)">
                        Dettaglio
                    </button>
                </div>
            </div>
            <div class="assetItemBlock" v-for="(ordine, i) in ordiniPending" :key="'o'+i">
                <div class="assetItem p-3" @click="slideAsset(ordine)" :class="{ slided: ordine.selected }">
                    <div class="imageContainer">
                        <asset-type-icon class="" :label="ordine.tipoAsset"></asset-type-icon>
                    </div>
                    <div class="info">
                        <h2 class="w-80">{{ordine.strumento}}</h2>
                        <div class="d-flex">
                            <div class="text-left mr-4">
                                <small class="text-low-white">{{$store.state.dizionario.wallet_list_date_label}}</small>
                                <p class="mb-0 text-white">{{ordine.dataOrdine | date("YYYY-MM-DD")}}</p>
                            </div>
                            <div class="text-left mr-4">
                                <small class="text-low-white">{{$store.state.dizionario.wallet_list_value_label}}</small>
                                <p class="mb-0 text-white">€ {{ordine.controvalore | currency}}</p>
                            </div>
                            <div class="text-left">
                                <small class="text-low-white">{{$store.state.dizionario.wallet_list_wallet_perc_label}}</small>
                                <p class="mb-0 text-white">{{ordine.peso.toFixed(2)}}%</p>
                            </div>
                        </div>
                    </div>
                    <div class="pendingImageContainer">
                        <img :src="require('@/img/' + (ordine.tipoOrdine == 1 ? 'buy' : 'sell') + '_order.svg')" />
                        <small class="text-orange">PENDING</small>
                    </div>
                </div>
                <div class="sottoAssetItem">
                    <button type="button" class="btn btn-danger" @click="deleteOrder(ordine, i)" v-if="ordine.deletable">
                        {{$store.state.dizionario.wallet_cancelOrder_label}}
                    </button>
                    <button type="button" class="btn btn-switch" v-else>
                        {{$store.state.dizionario.wallet_orderInProgress_label}}
                    </button>
                </div>
            </div>
            <div class="assetItemBlock" v-for="(item, i) in assetsWithControvalore" :key="'a'+i" @click="slideAsset(item)">
                <div class="assetItem p-3" :class="{ slided2: item.selected }">
                    <!-- :style="{'background-image': 'url(' + item.image + ')'}" -->
                    <div class="imageContainer">
                        <asset-type-icon class="" :label="item.tipo"></asset-type-icon>
                    </div>
                    <div class="info">
                        <h2>{{item.nome}}</h2>
                        <div class="d-flex justify-content-between">
                            <div class="text-left w-50">
                                <small class="text-low-white">{{$store.state.dizionario.wallet_list_value_label}}</small>
                                <p class="mb-0 text-white">€ {{item.controvalore | currency}}</p>
                            </div>
                            <div class="text-left w-50">
                                <small class="text-low-white">{{$store.state.dizionario.wallet_list_wallet_perc_label}}</small>
                                <p class="mb-0 text-white">{{item.peso.toFixed(2)}}%</p>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div>
                                <small class="text-low-white">{{$store.state.dizionario.wallet_list_wallet_perf_ytd}}</small>
                                <p class="mb-0" v-color="item.performanceYtd">{{item.performanceYtd | perc(1)}}%</p>
                            </div>
                            <div>
                                <small class="text-low-white">{{$store.state.dizionario.wallet_list_wallet_perf_1y}}</small>
                                <p class="mb-0" v-color="item.performance1Year">{{item.performance1Year | perc(1)}}%</p>
                            </div>
                            <div>
                                <small class="text-low-white">{{$store.state.dizionario.wallet_list_wallet_perf_3y}}</small>
                                <p class="mb-0" v-color="item.performance3Year">{{item.performance3Year | perc(1)}}%</p>
                            </div>
                            <div>
                                <small class="text-low-white">{{$store.state.dizionario.wallet_list_wallet_vol_3y}}</small>
                                <p class="mb-0" v-color="item.volatilita750">{{item.volatilita750 | perc(1)}}%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sottoAssetItem">
                    <button type="button" class="btn btn-danger" @click="openSellAssetModal(item)">
                        {{$store.state.dizionario.wallet_sellAsset_label}}
                    </button>
                    <button type="button" class="btn btn-switch" @click="goToSwitch">
                        <!-- {{$store.state.dizionario.wallet_sellAsset_label}} -->
                        Switch
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import * as OM from '@/model';
import DonutChartCard from '@/components/donutChartCard.vue'
import { StorageServices } from '@/services/StorageServices'
import AssetTypeSlugs from '@/components/assetTypeSlugs.vue';
import { ModalServices } from '@/services/ModalServices';
import SellAssetModal from '@/components/sellAssetModal.vue';
import SwitchDetailModal from './switchDetailModal.vue';
import SwitchDeleteModal from './switchDeleteModal.vue';
import DeleteOrderModal from './deleteOrderModal.vue';
import WalletBar from '@/components/walletBar.vue';
import AssetTypeIcon from '@/components/assetTypeIcon.vue';
import { OrdineClient, PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        DonutChartCard,
        AssetTypeSlugs,
        WalletBar,
        AssetTypeIcon
    }
})
export default class WalletView extends Vue {

    types: string[] = []
    filter: OM.AssetFilterVm = new OM.AssetFilterVm();
    assets: OM.AssetForDonutChart[] = [];
    ordiniPending: OM.OrdinePendingVm[] = [];
    switches: OM.SwitchPendingVm[] = [];
    donutSerie: number[] = [];
    donutTypes: OM.TypeForDonutChartVm[] = [];

    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        store.state.headerText = `<p class="mb-0">` + challenge.titolo + `</p>`;

        let filterVm = new OM.AssetFilterVm();
        filterVm.portafoglioIdentifier = to.params.portafoglioIdentifier;
        Promise.all([
            PortafoglioClient.getAssetClasses(filterVm),
            PortafoglioClient.getDonutChart(filterVm),
            PortafoglioClient.getDonutChartTypes(filterVm),
            OrdineClient.getOrdiniPendingWithSwitch(filterVm.portafoglioIdentifier, "")
        ])
        .then(datas => {
            next((vc: WalletView) => {
                datas[0].unshift('All');
                vc.types = datas[0];
                filterVm.assetType = "All";
                vc.filter = filterVm;
                vc.assets = datas[1];
                vc.donutTypes = datas[2];
                vc.donutSerie = datas[2].map(x => x.peso);
                vc.ordiniPending = datas[3].ordini;
                vc.switches = datas[3].switches;
            });
        })
    }

    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        next();
    }
    
    filterByType(val){
        this.filter.assetType = val;
        let proms = []
        Promise.all([
            PortafoglioClient.getDonutChart(this.filter),
            OrdineClient.getOrdiniPendingWithSwitch(this.filter.portafoglioIdentifier, val)
        ])
        .then(x => {
            this.assets = x[0];
            this.ordiniPending = x[1].ordini;
            this.switches = x[1].switches;
        })
    }

    get assetsWithControvalore(){
        return this.assets.filter(x => x.controvalore > 0);
    }

    slideAsset(item){
        if(item.nome == "Liquidity")
            return;
        Vue.set(item, 'selected', !item.selected);
    }

    reinit(){
        let filterVm = new OM.AssetFilterVm();
        filterVm.portafoglioIdentifier = this.$route.params.portafoglioIdentifier;
        Promise.all([
            PortafoglioClient.getAssetClasses(filterVm),
            PortafoglioClient.getDonutChart(filterVm),
            PortafoglioClient.getDonutChartTypes(filterVm),
            OrdineClient.getOrdiniPendingWithSwitch(filterVm.portafoglioIdentifier, '')
        ])
        .then( datas => {
            datas[0].unshift('All');
            this.types = datas[0];
            filterVm.assetType = "All";
            this.filter = filterVm;
            this.assets = datas[1];
            this.donutTypes = datas[2];
            this.donutSerie = datas[2].map(x => x.peso);
            this.ordiniPending = datas[3].ordini;
            this.switches = datas[3].switches;
        })
    }

    totalControvalore(item: OM.OrdinePendingVm[]){
        return item.reduce((t, x) => t += x.controvalore, 0);
    }

    deleteOrder(item: OM.OrdinePendingVm, index){
        ModalServices.OpenModal(DeleteOrderModal,
        {
            ordine: item,
            portafoglioIdentifier: this.$route.params.portafoglioIdentifier
        }, this.reinit);
    }
    
    deleteSwitch(item: OM.SwitchPendingVm, index){
        ModalServices.OpenModal(SwitchDeleteModal,
        {
            ordini: item.orders
        }, this.reinit);
    }

    openSellAssetModal(asset: OM.AssetForDonutChart) {
        if(asset.nome == "Liquidity")
            return;
        ModalServices.OpenModal(SellAssetModal,
        {
            asset: asset,
            portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
            saveCb: (vm: OM.CreaOrdineVm) => {
                OrdineClient.creaOrdine(vm)
                .then(x => {
                    PortafoglioClient.getPortafoglioStatusWithSwitch({
                        portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
                        creaSwitchVm: null
                    })
                    .then(x => {
                        store.state.portafoglioStatus = x;
                        StorageServices.setPortafoglioStatus(x);
                    });
                    this.reinit();
                    ModalServices.CloseLastModal();
                })
            }
        });
    }

    openSwitchDetail(ordini){
        ModalServices.OpenModal(SwitchDetailModal,
        {
            ordini: ordini
        });
    }

    goToSwitch(){
        this.$router.push('switchVendita');
    }

}

</script>

<style scoped>

</style>
