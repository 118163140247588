<template>
    <div class="cinturaModal py-4">
        <h4>Questionario qualità</h4>
        <transition name="fastFade" mode="out-in">
            <section class="w-100" v-if="!showCommentoSection">
                <div class="text-center px-2 mt-4">
                    <p class="text-orange">Ti facciamo qualche semplice domanda come feedback sulla lezione (facoltativo)</p>
                    <div class="domanda" v-for="(item, i) in domande" :key="i">
                        <p class="text-low-white">{{item.testoDomanda}}</p>
                        <div class="w-100 d-flex justify-content-center px-2">
                            <button type="button" class="btn btn-dark btn-qualita ml-3" :class="{ active: selectedRisposte[i] == ii}"
                                @click="setRisposta(i, risposta, ii)" v-for="(risposta, ii) in item.scelte" :key="ii">
                                {{risposta}}
                            </button>
                        </div>
                    </div>
                </div>
                <hr class="my-3 mx-auto" style="width: 85%" />
                <div class="w-100 d-flex px-2 justify-content-around ">
                    <button type="button" class="btn btn-dark btn-large mr-2" @click="showCommentoSection = true">
                        Skip
                    </button>
                    <button type="button" class="btn btn-dark btn-large ml-2" @click="saveRisposte">
                        Salva
                    </button>
                </div>
            </section>
            <section class="w-100" v-else>
                <div class="text-center px-3 mt-4">
                    <p class="text-orange">Vuoi lasciare un commento sulla lezione?</p>
                    <textarea class="form-control" rows="4" @change="(ev) => rispostaQualita.commento = ev.target.value">
                    </textarea>
                </div>
                <hr class="my-3 mx-auto" style="width: 85%" />
                <div class="w-100 d-flex px-2 justify-content-around ">
                    <button type="button" class="btn btn-dark btn-large mr-2" @click="$emit('close')">
                        Skip
                    </button>
                    <button type="button" class="btn btn-dark btn-large ml-2" @click="saveCommento">
                        Salva
                    </button>
                </div>
            </section>
        </transition>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices';
import { StorageServices } from '@/services/StorageServices';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import { DomandaQualitaConfigClient, RispostaQualitaClient } from '@/services/Services';

@Component
export default class DomandeQualitaModal extends Vue {

    domande: OM.DomandaQualitaConfig[] = [];
    rispostaQualita: OM.RispostaQualitaVm = new OM.RispostaQualitaVm();
    selectedRisposte: number[] = [];

    showCommentoSection: boolean = false;

    @Prop() titoloLezione: string;

    created(){
        this.rispostaQualita.userIdentifier = store.state.loginResponse.userIdentifier;
        this.rispostaQualita.userName = store.state.loginResponse.nomeUtente;
        this.rispostaQualita.nomeTema = StorageServices.getTemaVm().nome;
        this.rispostaQualita.nomeCintura = StorageServices.getSelectedCintura().nome;
        this.rispostaQualita.risposte = [];
        this.rispostaQualita.lezioneIdentifier = this.$route.params.lezioneIdentifier;
        this.rispostaQualita.titoloLezione = this.titoloLezione;
        DomandaQualitaConfigClient.getAll()
        .then(x => {
            this.domande = x;
        })
    }

    setRisposta(domandaIndex, rispostaText, rispostaIndex){
        this.rispostaQualita.risposte[domandaIndex] = {
            domanda: this.domande[domandaIndex].testoDomanda,
            risposta: rispostaText
        };
        Vue.set(this.selectedRisposte, domandaIndex, rispostaIndex); 
    }

    saveRisposte(){
        RispostaQualitaClient.save(this.rispostaQualita)
        .then(x => {
            this.rispostaQualita.identifier = x;
            this.showCommentoSection = true;
        })
    }
    
    saveCommento(){
        RispostaQualitaClient.save(this.rispostaQualita)
        .then(x => {
            this.$emit('close');
        })
    }
}
</script>
<style scoped>

    .cinturaModal {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: #11171d;
    }

    .domanda {
        margin: 20px 0;
    }

    .domanda p {
        margin-top: 10px;
        margin-bottom: 8px;
    }

    .btn-qualita {
        min-width: 60px;
    }
    
    .btn-qualita.active {
        background-color: #d7d7d7!important;
        color: black!important;
    }

</style>
