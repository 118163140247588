<template>
    <div>
        <div v-if="loaded">
            <transition name="fade" mode="out-in">
                <div v-if="chatIdentifier">
                    <chat :chat-identifier="chatIdentifier"></chat>
                </div>
                <div class="createChatContainer text-low-white" v-else>
                    <p>{{$store.state.dizionario.chat_createChat_text}}</p>
                    <button type="button" class="btn btn-orange" @click="startChat()">
                        {{$store.state.dizionario.chat_createChat_buttonLabel}}
                    </button>
                </div>
            </transition>
        </div>
    </div>
</template>

<script lang="ts">
import router from '@/router';
import { Component, Vue } from 'vue-property-decorator';
import Chat from '@/components/chat.vue';
import store from '@/store';
import { ChatClient } from '@/services/Services';

@Component({
    components: {
        Chat
    }
})
export default class ChatRoom extends Vue {

    loaded: boolean = false;
    chatIdentifier: string = null;
    created(){
        ChatClient.getByUserIdentifier(store.state.loginResponse.userIdentifier)
        .then(x => {
            this.chatIdentifier = x;
            this.loaded = true;
        })
    }

    startChat(){
        ChatClient.startChat(store.state.loginResponse.userIdentifier)
        .then(x => {
            this.chatIdentifier = x;
        })
    }
}

</script>

<style scoped>

.createChatContainer {
    text-align: center;
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 30px;
}

</style>
