<template>
    <div>
        <div class="cintureWrapper hasFooter">
            <!-- <button type="button" class="btn btn-info porcataBtn" @click="toggleCintura">Toggle</button> -->
            <div class="mb-3" v-for="(cintura, iCint) in cintureList" :key="iCint" :class="cintura.enabled ? '' : 'disabled'">
                <h5 class="cinturaTitle">{{cintura.nome}}</h5>
                <div class="progressBar">
                    <progress-bar :completed="cintura.progress" :color="cintura.hexColor"></progress-bar>
                </div>
                <div class="horizontalContainer" v-if="cintura.temi.length > 0">
                    <tema-cintura v-for="(tema, i) in cintura.temi" :key="i" :nome="tema.nome" 
                        :icona="tema.icona"
                        :enabled="cintura.enabled" :completato="tema.completamento" 
                        :cintura-color="cintura.hexColor" @click.native="navTo(cintura, tema)"></tema-cintura>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import TemaCintura from '@/components/temaCintura.vue';
import ProgressBar from '@/components/progressBar.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';
import { CinturaClient } from '@/services/Services';

@Component({
    components: {
        TemaCintura,
        ProgressBar
    }
})
export default class CintureView extends Vue {

    username: string = store.state.loginResponse.nomeUtente;
    
    cintureList: OM.CinturaWithTemiVm[] = [];

    beforeRouteEnter(to, from, next){
        CinturaClient.getCintureWithTemiByPercorso(to.params.percorsoIdentifier)
        .then(x => {
            next((vc: CintureView) => {
                vc.cintureList = x;
                StorageServices.setCintureList(vc.cintureList);
            })
        })
    }

    navTo(cintura: VM.CinturaWithTemiVm, tema: OM.Tema){
        if(cintura.enabled){
            this.$router.push('/percorsi/' + this.$route.params.percorsoIdentifier + '/cinture/' + cintura.identifier + '/temi/' + tema.identifier);
        }
    }

    toggleCintura(){
        this.cintureList.forEach(cintura => {
            cintura.enabled = true;
        });
    }

}

</script>

<style scoped>


.porcataBtn {
    position: fixed;
    top: 50px;
    right: 30px;
    border: 5px solid #fff;
    z-index: 100;
}
</style>
