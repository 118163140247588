import { render, staticRenderFns } from "./percorsi.vue?vue&type=template&id=12d8ebe4&scoped=true"
import script from "./percorsi.vue?vue&type=script&lang=ts"
export * from "./percorsi.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12d8ebe4",
  null
  
)

export default component.exports