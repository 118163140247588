<template>
    <div class="h-100">
        <div class="d-flex justify-content-between align-items-center" style="height: 50px;">
            <div class="d-flex pl-3">
                <h5>
                    {{$route.params.microClass}}
                </h5>
            </div>
        </div>
        <div style="overflow: auto; width: 100%;">
            <table class="table table-striped">
                <tbody>
                    <tr>
                        <td class="pl-2 pr-2">{{$store.state.dizionario.challenge_assets_chart_period_details_periodo}}</td>
                        <td class="pl-2 text-low-white" v-for="(periodo, j) in datiPeriodo" :key="j">{{periodo.nomePeriodo}}</td>
                    </tr>

                    <tr>
                        <td class="pl-2 pr-2">{{$store.state.dizionario.challenge_assets_chart_period_details_num_period}}</td>
                        <td class="pl-2 text-low-white" v-for="(periodo, j) in datiPeriodo" :key="j">{{periodo.numeroPeriodi}}</td>
                    </tr>

                    <tr>
                        <td class="pl-2 pr-2">{{$store.state.dizionario.challenge_assets_chart_period_details_perf_max}}</td>
                        <td class="pl-2" v-color="periodo.performanceMax" v-for="(periodo, j) in datiPeriodo" :key="j">{{periodo.performanceMax | perc(1)}}</td>
                    </tr>

                    <tr>
                        <td class="pl-2 pr-2">{{$store.state.dizionario.challenge_assets_chart_period_details_perf_media}}</td>
                        <td class="pl-2" v-color="periodo.performanceMedia" v-for="(periodo, j) in datiPeriodo" :key="j">{{periodo.performanceMedia | perc(1)}}</td>
                    </tr>

                    <tr>
                        <td class="pl-2 pr-2">{{$store.state.dizionario.challenge_assets_chart_period_details_perf_min}}</td>
                        <td class="pl-2" v-color="periodo.performanceMin" v-for="(periodo, j) in datiPeriodo" :key="j">{{periodo.performanceMin | perc(1)}}</td>
                    </tr>

                    <tr>
                        <td class="pl-2 pr-2">{{$store.state.dizionario.challenge_assets_chart_period_details_periodi_pos}}</td>
                        <td class="pl-2" v-color="periodo.periodiPositivi" v-for="(periodo, j) in datiPeriodo" :key="j">{{periodo.periodiPositivi | perc(1)}}</td>
                    </tr>

                    <tr>
                        <td class="pl-2 pr-2">{{$store.state.dizionario.challenge_assets_chart_period_details_periodi_neg}}</td>
                        <td class="pl-2" v-color="periodo.periodiNegativi" v-for="(periodo, j) in datiPeriodo" :key="j">{{periodo.periodiNegativi | perc(1)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script lang="ts">
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';

@Component
export default class PeriodiHorizontalTable extends Vue {

    datiPeriodo: OM.DatiPeriodo[] = null;
    headers = ["Periodo","N° Periodi","Perf. Max","Perf. Media","Perf. Min","Periodi Positivi","Periodi Negativi"];

    beforeRouteEnter(to, from, next){
        store.state.headerText = `<small class="text-orange small">` + 
            to.params.label + `</small>`;
        next();
    }
    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        if(window.cordova)
            (<any>screen).orientation.lock('portrait');
        next();
    }

    mounted(){
        this.datiPeriodo = StorageServices.getHorizontalTableData();
        if(window.cordova)
            (<any>screen).orientation.lock('landscape');
    }

}
</script>

<style scoped>
.table tbody td:first-of-type {
    color: white !important;
    border-right: 1px solid #dee2e6;
}

.table tbody td:last-of-type {
    padding-right: 8px;
}
</style>
