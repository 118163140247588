<template>

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AuthServices } from '@/services/AuthServices';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';
import * as VM from '@/viewModel';
import { appVersion } from '@/config';

@Component
export default class FbToken extends Vue {
    created(){
        let token = <string>this.$route.query.access_token;
        let vm: VM.ProviderTokenLoginVm = {
            access_token: token,
            password: '',
            appVersion
        }
        AuthServices.facebookTokenLogin(vm)
        .then(x => {
            AuthServices.setAuthToken(x.token);
            store.state.loginResponse = x;
            StorageServices.setLoginResponse(x);
            this.$router.push("/blank");
        })
    }
}

</script>