<template>
    <div class="modalWrapper">
        <div class="modalHeader">
        </div>
        <div class="modalBody">
            <h5>{{$store.state.dizionario.new_version_text}}</h5>
        </div>
        <div class="modalFooter">
            <button type="button" class="btn btn-dark btn-large" @click="openStore">
                Ok
            </button>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { playStoreUrl, appleStoreUrl } from '@/config';

@Component
export default class NewVersionModal extends Vue {
    
    openStore(){
        if(window.cordova){
            if(device.platform == "iOS"){
                window.open(appleStoreUrl, '_system');
            } else {
                window.open(playStoreUrl, '_system');
            }
        } else {
            window.open(playStoreUrl, '_blank');
        }
    }

}
</script>
<style scoped>

    .modalWrapper{
        background: rgb(18, 24, 29);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        text-align: center;
    }

    .modalHeader {
        color: white;
        font-size: 16px;
        margin-bottom: 20px;
    }

    .modalBody {
        margin-bottom: 20px;
    }

    .modalFooter {
        display: flex;
        justify-content: space-around;
    }

    .modalFooter button {
        width: 40%;
    }


</style>
