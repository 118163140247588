<template>
    <div class="hideScrollBarX">
        <div class="slugsContainer">
            <div class="slug" :class="{'active': i == selectedIndex}" v-for="(item, i) in slugs" :key="i"
                @click="select(item, i)">
                {{item}}
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AssetTypeSlugs extends Vue {

    @Prop() slugs: string[];
    selectedIndex: number = 0;

    select(item, index){
        this.selectedIndex = index;
        this.$emit('select', item);
    }
}
</script>

<style scoped>

    .hideScrollBarX {
        overflow: hidden;
        height: 34px;
    }

    .slugsContainer {
        overflow-x: auto;
        width: 100%;
        white-space: nowrap;
        padding: 0 30px;
        height: 46px;
    }

    .slug {
        display: inline-block;
        border: 1.5px solid #535353;
        border-radius: 8px;
        padding: 6px 10px;
        text-align: center;
        margin: 0 9px;
        color: #ffffff;
    }
    
    .slug.active {
        color: rgb(246, 156, 20);
        border: 1.5px solid rgb(246, 156, 20);
    }

</style>
