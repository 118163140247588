<template>
    <div class="progressBar" ref="progressBar">
        <div class="stepContainer" v-for="(item, i) in steps" :key="i" :style="'width:' + stepWidth + 'px'">
            <div class="step" :style="getStepStyle(item)">
            </div>
            <small class="text-slow-white">{{getProgress(item) | currency(0)}}%</small>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Component
export default class UserProgressBar extends Vue {
    
    @Prop() progressiCinture: VM.ProgressoCinturaVm[];
    @Prop() steps: number;

    stepWidth: number = 0;
    superati: number = 0;

    mounted(){
        console.log(this.steps);
        let totalWidth = parseInt(getComputedStyle(<Element>this.$refs.progressBar).width);
        this.stepWidth = totalWidth / this.steps;
        this.progressiCinture.forEach(x => {
            if(x.progress >= 100)
                this.superati++;
        })
    }

    getStepStyle(item: number){
        if(this.progressiCinture[item -1]){
            return 'background-color: ' + this.progressiCinture[item -1].hexColor + 
                '; height: ' + this.progressiCinture[item -1].progress + '%;';
        } else {
            let color = "";
            switch(item - 1){
                case 0:
                    color = "#F0F8FE";
                    break;
                case 1:
                    color = "#FFD700";
                    break;
                case 2:
                    color = "#FF8200";
                    break;
                case 3:
                    color = "#31B700";
                    break;
                case 4:
                    color = "#0085CA";
                    break;
                case 5:
                    color = "#7C4D3A";
                    break;
                case 6:
                    color = "#15001F";
                    break;
            }
            return 'background-color: ' + color + 
                '; height: 2px;';
        }
    }

    getProgress(item: number) {
        if(this.progressiCinture[item -1]){
            return this.progressiCinture[item -1].progress;
        }
        return 0;
    }
}

</script>

<style scoped>

    .progressBar {
        width: 85%;
        display: flex;
        margin: 0 auto;
    }

    .progressBar .stepContainer {
        display: inline-block;
        height: 80px;
        padding: 0 2px;
        position: relative;
    }
    .progressBar .stepContainer:before {
        position: absolute;
        content: '';
        width: calc(100% - 11px);
        height: 100%;
        background-color: #080808;
        top: 0;
        left: 5px;
        z-index: -1;
        border-radius: 4px;
    }

        .progressBar .stepContainer .step {
            text-align: center;
            min-height: 1px;
            z-index: 2;
            bottom: 0px;
            margin: auto;
            position: absolute;
            width: calc(100% - 13px);
            left: 0;
            right: 0;
            border-radius: 4px;
        }
        .progressBar .stepContainer small {
            position: absolute;
            bottom: -25px;
            text-align: center;
            left: 0;
            right: 0;
        }

</style>
