<template>
    <div>      
        <audio preload="metadata" ref="player" @timeupdate="updateTime" @loadedmetadata="setEndTime">
            <source :src="source" type="audio/mpeg">
        </audio>
        <div class="player">
            <div class="time">
                <div class="time__current">{{currentTime}}</div>
                <div class="time__line" ref="timeline" @touchstart="setTime" @touchmove="setTime">
                    <div class="line__current" ref="currentLine"></div>
                </div>
                <div class="time__full">{{endTime}}</div>
            </div>
            <div class="control" @click="togglePlay">
                <i class="fas fa-3x" :class="isPlaying ? 'fa-pause' : 'fa-play'"></i>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class AudioPlayer extends Vue {

    @Prop() source: string;

    isPlaying: boolean = false;
    player: any;
    timeline: any;
    currentLine: any;
    currentTime: string = '00:00';
    endTime: string = '00:00';

    play(){
        this.isPlaying = true;
        this.player.play();
    }
    stop(){
        this.isPlaying = false;
        this.player.stop();
    }
    togglePlay(){
        this.isPlaying = !this.isPlaying;
        if(!this.isPlaying)
            this.player.pause();
        else 
            this.player.play();
    }

    mounted(){
        this.player = this.$refs.player;
        this.timeline = this.$refs.timeline;
        this.currentLine = this.$refs.currentLine;
    }

    setEndTime(e){
        this.endTime = this.formatTime(e.target.duration);
    }

    setTime(e) {
        var x;
        var setTime;
        var setWidth;
        var nowWidth;
        var timeLineWidth = this.timeline.offsetWidth;
        this.player.pause();

        if (e.touches[0].pageX) {
            x = e.touches[0].pageX;
        } else {
            x = e.touches[0].clientX;
        }
        nowWidth = x - this.timeline.getBoundingClientRect().left;
        setWidth = (nowWidth * 100) / timeLineWidth;

        this.currentLine.style.width = setWidth + '%';
        this.player.currentTime = (setWidth * this.player.duration) / 100;
        if(this.isPlaying)
            this.player.play();
    };
    
    updateTime() {
        if (this.isPlaying) {
            var time = this.player.currentTime;
            this.currentTime = this.formatTime(time);
            this.currentLine.style.width = (time * 100) / this.player.duration + '%';
        }
    };

    formatTime(time) {
        var timeArr: any = [
            Math.floor((((time % 31536000) % 86400) % 3600) / 60),
            ((((time % 31536000) % 86400) % 3600) % 60)
        ];

        timeArr[1] = Math.floor(timeArr[1]);

        for (var i = 0; i < timeArr.length; i++) {
            if (timeArr[i] < 10) {
                timeArr[i] = '0' + timeArr[i];
            };
        }
        return timeArr[0] + ':' + timeArr[1];
    }

}

</script>

<style scoped>

.player {
    width: 100%;
    height: auto;
    position: relative;
    height: 70px;
}

.control {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 35px;
    cursor: pointer;
    height: 40px;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: background-size 0.1s ease-in-out;
    transition: background-size 0.1s ease-in-out;
    width: 100%;
    will-change: background-size;
    color: #f69c14;
}
.time {
    bottom: 0;
    color: #fff;
    font-size: 14px;
    position: absolute;
    width: 100%;
}
.time__current, .time__full {
    cursor: default;
    margin: 10px 10px 20px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.time__current {
    float: left;
}
.time__full {
    float: right;
}
.time__line {
    background: #fff;
    bottom: 0;
    cursor: pointer;
    height: 16px;
    position: absolute;
    width: 100%;
    touch-action: none;
    z-index: 5;
}

.line__current {
    height: 16px;
    position: absolute;
    width: 0%;
    background: #f69c14;
}

</style>
