<template>
    <div class="tallModal">
        <div class="header">
            <div class="headerLeft"></div>
            <div class="headerCenter">
                <p class="title text-center">
                    <small class="text-orange small">{{$route.params.microClass}}</small>
                    <br>
                    <span class="mb-0 title">{{$store.state.dizionario.challenge_assets_buy_modal_title}}</span>
                </p>
            </div>
            <div class="headerRight">
                <div class="headerButton" @click="openHelp" v-if="showHelp">
                    <i class="fa fa-question"></i>
                </div>
                <div class="headerButton" @click="closeModal">
                    <img src="@/img/Close_header.svg" />
                </div>
            </div>
        </div>
        <wallet-bar :wallet-risk="walletRisk" :sum-to-liquidity="sumToLiquidity" :liquidity-percent="liquidityPercentage"
            :for-switch="!!creaSwitchVm"></wallet-bar>
        <div class="titleContainer walletBarPadding" v-typeclass="asset.tipo">
            <small class="text-low-white assetTypeLabel">{{asset.tipoAsset}}</small>
            <h2>{{asset.nome}}</h2>
            <div class="assetDatas">
                <div>
                    <small class="text-low-white">{{$store.state.dizionario.challenge_assets_buy_modal_last_price}}</small><br/>
                    <span class="text-white">{{asset.nav}}</span>
                </div>
                <div>
                    <small class="text-low-white">{{$store.state.dizionario.challenge_assets_buy_modal_valuta}}</small><br/>
                    <span class="text-white">{{asset.valuta}}</span>
                </div>
            </div>
            <div class="shadowInset"></div>
        </div>
        <div class="d-flex stocksSection">
            <div class="w-50" @click="openInputModal('Quantità ordine', 'quantitaInput', 'quantitaOrdine')"> <!-- @click="showQuantitaInput" -->
                <small class="text-low-white">{{$store.state.dizionario.challenge_assets_buy_modal_quantita_ordine}}</small>
                <!-- <input class="acquistoInput" type="number" v-model.number="quantitaInput" v-if="isQuantitaInput"
                    @blur="showQuantitaInput" @click.stop
                    step="0.01" :min="0" ref="quantitaInput" /> -->
                <animated-number :value="quantitaOrdine" :speed="100" 
                    :decimals="asset.tipoAsset.toLowerCase() == 'etf' ? 0 : 6">
                    <p slot-scope="animated" class="mb-0 text-white">{{animated.value}}</p>
                </animated-number>
            </div>
            <div class="w-50 text-right" @click="openInputModal('Controvalore (€)', 'acquistoInput', 'totaleAcquisto')">
                <small class="text-low-white">{{$store.state.dizionario.challenge_assets_buy_modal_total}}</small>
                <!-- <input class="acquistoInput" type="number" v-model.number="acquistoInput" v-if="isAcquistoInput"
                    @blur="showAcquistoInput" @click.stop
                    step="0.01" :min="0" :max="asset.controvalore" ref="acquistoInput" /> -->
                <animated-number :value="totaleAcquisto" :speed="100" :decimals="2">
                    <p slot-scope="animated" class="mb-0 text-white">{{animated.value | currency}}</p>
                </animated-number>
            </div>
        </div>
        <div class="sliderSection">
            <div class="labelValue text-center mb-4" @click="openInputModal('Controvalore (€)', 'sliderValue', 'sliderValue')">
                <p class="text-orange mb-0">{{$store.state.dizionario.challenge_assets_buy_modal_perc_label}}</p>
                <!-- <input class="acquistoInput" type="number" v-model.number="sliderValue" v-if="isSliderInput"
                    @blur="showSliderInput" @click.stop
                    :min="0" :max="100" ref="sliderInput" style="width: 50%;" /> -->
                <animated-number :value="sliderValue" :speed="100" :delayed="false" :decimals="1">
                    <p slot-scope="animated" class="mb-0 text-large text-white">{{animated.value}}</p>
                </animated-number>
            </div>
            <!-- <infinite-slider v-model="sliderValue" :min="0" :max="100" :ratio="0.01"></infinite-slider>  -->
            <swiper-percentage v-model="sliderValue" :asset-type="asset.tipo"></swiper-percentage>
            <!-- <range-slider class="slider" :min="minSlider" :max="maxSlider" :step="0.1" v-model="sliderValue">
            </range-slider> -->
        </div>
        <call-to-button class="absoluteToBottom" @click="createOrdine">{{$store.state.dizionario.challenge_assets_buy_modal_confirm_button}}</call-to-button>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices';
import SwiperPercentage from '@/components/swiperPercentage.vue';
import WalletBar from '@/components/walletBar.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import AnimatedNumber from '@/components/animatedNumber.vue';
import CallToButton from '@/components/callToButton.vue';
import 'vue-range-slider/dist/vue-range-slider.css';
import store from '@/store';
import * as Enum from '@/enum';
import { stdDev } from '@/utility'
import AlertModal from '@/components/alertModal.vue'
import HelperModal from '@/components/helperModal.vue';
import NumberInputModal from '@/components/numberInputModal.vue';
import { PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        SwiperPercentage,
        WalletBar,
        AnimatedNumber,
        CallToButton
    }
})
export default class BuyAssetModal extends Vue {
    
    @Prop({
        type: [Object, VM.AssetForBuyListVm]
    }) asset: VM.AssetForBuyListVm;
    
    @Prop({
        type: String,
        required: true
    }) portafoglioIdentifier: string;

    @Prop({
        type: [VM.CreaSwitchVm, Object],
    }) creaSwitchVm: VM.CreaSwitchVm;

    @Prop({
        required: true
    }) saveOrderCb: (orderVm: VM.CreaOrdineVm) => void;

    portafoglioStatus: OM.PortafoglioStatusVm = new OM.PortafoglioStatusVm();

    sliderValue: number = 0;
    minSlider: number = 0;
    maxSlider: number = 100;
    dataForCalcolo: VM.DataForCalcoloAcquistoVm = new VM.DataForCalcoloAcquistoVm();
    walletRisk: number = 0;
    valoreInEur: number = 0;
    isAcquistoInput: boolean = false;
    acquistoInput: number = 0;
    isQuantitaInput: boolean = false;
    quantitaInput: number = 0;
    isSliderInput: boolean = false;

    openInputModal(text, key, value){
        this.$opModal.show(NumberInputModal,
        {
            text: text,
            value: this[value],
            callback: (val) => { this[key] = parseFloat(val) }
        });
    }

    created(){
        this.portafoglioStatus = StorageServices.getPortafoglioStatus(this.portafoglioIdentifier);
        PortafoglioClient.getDataForCalcoloAcquistoWithSwitch({
            portafoglioIdentifier: this.portafoglioIdentifier, 
            assetIdentifier: this.asset.identifier,
            creaSwitchVm: this.creaSwitchVm ? this.creaSwitchVm : null
        })
        .then(x => {
            this.valoreInEur = this.asset.nav / store.state.valute[this.asset.valuta];
            this.maxSlider = 100;
            this.dataForCalcolo = x;
            this.calcolaWalletRisk(0);
        })
    }

    @Watch('quantitaInput')
    onQuantitaInputChange(next, prev){
        let total = next * this.valoreInEur;
        if(total > this.totalLiquidity)
            total = this.totalLiquidity;
        if(total < 0)
            total = 0;
        next = total / this.valoreInEur;
        if(this.asset.tipoAsset.toLowerCase() == 'etf')
            this.quantitaInput = Math.round(next);
        else
            this.quantitaInput = next;
        this.sliderValue = Math.round(this.quantitaInput * this.valoreInEur * 100 / this.totalLiquidity);
    }
    
    showQuantitaInput(ev) {
        this.isQuantitaInput = !this.isQuantitaInput;
        this.quantitaInput = parseFloat(this.quantitaOrdine.toFixed(2));
        if (this.quantitaInput == 0)
            this.quantitaInput = null;
        if(this.isQuantitaInput){
            setTimeout(() => {
                (<HTMLElement>this.$refs.quantitaInput).focus();
            }, 100);
        }
        ev.preventDefault();
        ev.stopPropagation();
    }
    
    @Watch('acquistoInput')
    onAcquistoInputChange(next, prev){
        if(next > this.totalLiquidity){
            this.acquistoInput = this.totalLiquidity;
            next = this.totalLiquidity;
        }
        if(next < 0) {
            this.acquistoInput = 0;
            next = 0;
        }
        this.sliderValue = Math.round(next * 100 / this.totalLiquidity);
    }
    
    showAcquistoInput(ev) {
        this.isAcquistoInput = !this.isAcquistoInput;
        this.acquistoInput = parseFloat(this.totaleAcquisto.toFixed(2));
        if (this.acquistoInput == 0)
            this.acquistoInput = null;
        if(this.isAcquistoInput){
            setTimeout(() => {
                (<HTMLElement>this.$refs.acquistoInput).focus();
            }, 100)
        }
        ev.preventDefault();
        ev.stopPropagation();
    }

    get sumToLiquidity(){
        if(!this.creaSwitchVm){
            return -this.totaleAcquisto;
        } else {
            return -store.state.portafoglioStatus.liquidity + this.totalLiquidity - this.totaleAcquisto;
        }
    }
   
    get totaleOrdineSwitch(){
        if(!this.creaSwitchVm)
            return 0;

        let vendite = this.creaSwitchVm.ordiniVendita.reduce((p, x) => p += x.controvalore, 0);
        let acquisti = this.creaSwitchVm.ordiniAcquisto.reduce((p, x) => p += x.controvalore, 0);
        return vendite - acquisti;
    }

    get totalLiquidity(){
        if(!this.creaSwitchVm){
            return store.state.portafoglioStatus.liquidity;
        } else {
            return this.totaleOrdineSwitch;
        }
    }
    
    get quantitaOrdine(){
        if (this.asset.tipoAsset.toLowerCase() == "fondo")
            return this.totaleAcquisto / this.valoreInEur;
        else if (this.asset.tipoAsset.toLowerCase() == "etf")
            return Math.round(this.totaleAcquisto / this.valoreInEur);
    }

    get totaleAcquisto(){
        let ris = this.totalLiquidity * this.sliderValue / 100;
        if(this.asset.tipoAsset.toLowerCase() == "fondo"){
            return ris;
        } else if (this.asset.tipoAsset.toLowerCase() == "etf") {
            let partial = Math.floor(ris / this.valoreInEur);
            return partial * this.valoreInEur;
        }
    }

    get liquidityPercentage(){
        let switchPercent = this.totaleOrdineSwitch / store.state.portafoglioStatus.controvalorePortafoglio;
        return (store.state.portafoglioStatus.liquidityPercentage + switchPercent) - 
            ((store.state.portafoglioStatus.liquidityPercentage + switchPercent) * this.totaleAcquisto / this.totalLiquidity);
    }

    showSliderInput(ev){
        this.isSliderInput = !this.isSliderInput;
        if(this.isSliderInput){
            if(this.sliderValue == 0)
                this.sliderValue = <any>'';
            setTimeout(() => {
                (<HTMLElement>this.$refs.sliderInput).focus();
            }, 100)
        } else {
            if(!this.sliderValue)
                this.sliderValue = 0;
        }
        ev.preventDefault();
        ev.stopPropagation();
    }

    sliderTimeout: number;
    @Watch('sliderValue')
    onSliderValueChange(next, prev){
        if(!this.isSliderInput) {
            //next = Math.floor(next);
        } else {
            if(next > 100){
                this.sliderValue = 100;
                next = 100;
            }
            if(next < 0){
                this.sliderValue = 0;
                next = '';
            }
        }
        this.sliderValue = next;
        clearTimeout(this.sliderTimeout);
        this.sliderTimeout = setTimeout(() => {
            this.calcolaWalletRisk(next);
        }, 50)
    }

    calcolaWalletRisk(sliderValue){
        let variazioniConAsset = [];
        let percent = 0;
        if(this.creaSwitchVm)
            percent = (this.totaleOrdineSwitch / store.state.portafoglioStatus.controvalorePortafoglio)  * (sliderValue / 100);
        else
            percent = (store.state.portafoglioStatus.liquidityPercentage) * (sliderValue / 100);
        this.dataForCalcolo.variazioniPesate.forEach((x, i) => {
            variazioniConAsset[i] = x + percent * this.dataForCalcolo.variazioniAsset[i];
        });
        this.walletRisk = stdDev(variazioniConAsset) * Math.sqrt(250);
        return this.walletRisk;
    }

    createOrdine(){
        if(this.totaleAcquisto <= 0){
            this.$emit('close');
            return;
        }
        if(this.walletRisk * 100 > store.state.portafoglioStatus.matchRisk){
            ModalServices.OpenModal(AlertModal,
            {
                text: 'Non puoi superare la percentuale di rischio della gara'
            });
            return;
        }
        let vm: VM.CreaOrdineVm = new VM.CreaOrdineVm();
        vm.userIdentifier = store.state.loginResponse.userIdentifier;
        vm.portafoglioIdentifier = this.$route.params.portafoglioIdentifier;
        vm.tipoOrdine = Enum.TipoOrdine.Acquisto;
        vm.assetIdentifier = this.asset.identifier;
        vm.assetName = this.asset.nome;
        vm.assetType = this.asset.tipo;
        vm.controvalore = this.totaleAcquisto;
        vm.dataOrdine = new Date().toISOString();
        this.saveOrderCb(vm);
        this.$emit('close');
    }

    beforeDestroy(){
        store.state.portafoglioStatus.walletRisk =  this.calcolaWalletRisk(0);
    }

    closeModal(){
        this.$emit('close');
    }

    
    get showHelp(){
        return !!this.$store.state.dizionarioHtml.buyModal;
    }

    openHelp(){
        if(!this.showHelp)
            return;

        ModalServices.OpenModal(HelperModal,
        {
            dictionaryKey: 'buyModal'
        });
    }
    
}
</script>
<style scoped>

    .header {
        position: relative;
    }

    .modalWrapper {
        background: rgb(18, 24, 29);
        height: calc(100vh - 80px);
        overflow-y: auto;
    }

    .assetTypeLabel {
        position: absolute;
        top: 10px;
    }

    .titleContainer {
        height: 35vh;
        padding: 25px 20px;
        position: relative;
        margin-top: 3px;
    }
        .titleContainer h2 {
            font-family: 'Poppins-Light';
            font-size: 30px;
        }
        .titleContainer .shadowInset {
            height: 3px;
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            background-image: linear-gradient(to top, rgba(18, 24, 29, 0.50), transparent);
        }

    .stocksSection {
        padding: 25px 20px 4px;
        border-bottom: 1px solid rgba(246, 156, 20, 0.2);
    }
        .stocksSection div:first-child {
            margin-right: 30%;
        }
        .stocksSection p {
            font-size: 20px;
        }

    .sliderSection {
        margin: 0;
        padding: 15px 20px 0px;
    }
        .sliderSection .labelValue .text-large {
            font-size: 34px;
            line-height: 1;
        }

    .assetDatas {
        width: 100%;
        display: flex;
        position: absolute;
        bottom: 10px;
        left: 0px;
    }
        .assetDatas div {
            width: 50%;
            padding-left: 20px;
        }

    input.acquistoInput {
        width: 100%;
    }

</style>
