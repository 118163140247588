<template>
    <div class="pageWrapper hasHeader glossario">
        <page-header>
            {{definizione.key}}
            <div slot="left">
                <div class="headerButton" @click="$router.back()">
                    <img src="@/img/back_header.svg" />
                </div>
            </div>
        </page-header>

        <div class="d-flex justify-content-end px-3 w-100 mb-4">
            <div>
                <button type="button" class="btn textChanger minus mr-2" 
                    :disabled="classChangeDimension == 'dimension_1'"
                    @click="minusText">A</button>

                <button type="button" class="btn textChanger plus" 
                    :disabled="classChangeDimension == 'dimension_4'"
                    @click="plusText">A</button>
            </div>
        </div>

        <lezione-Content :content="definizione.content" class="px-3 textChangeSizeContent" :class="classChangeDimension" v-if="loaded"></lezione-Content>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/model';
import PageHeader from '@/components/pageHeader.vue';
import LezioneContent from '@/components/lezioneContent.vue';
import { GlossarioClient } from '@/services/Services';

@Component({
    components: {
        PageHeader,
        LezioneContent
    }
})
export default class Glossario extends Vue {

    classChangeDimension: string = "dimension_1";
    changeDimensionStepNumber: number = 1;
    definizione: OM.Glossario = new OM.Glossario();
    loaded: boolean = false;

    created(){
        GlossarioClient.getByKey(this.$route.params.word)
        .then(x => {
            this.definizione = x;
            this.loaded = true;
        })
    }

    minusText(){
        if(this.changeDimensionStepNumber > 1) {
            this.changeDimensionStepNumber -= 1;
            this.classChangeDimension = "dimension_" + this.changeDimensionStepNumber;
        }

    }

    plusText(){
        if(this.changeDimensionStepNumber < 4) {
            this.changeDimensionStepNumber += 1;
            this.classChangeDimension = "dimension_" + this.changeDimensionStepNumber;
        }
    }
}

</script>
<style scoped>
.hasHeader{
    padding-top:24px;
}

</style>
