import { render, staticRenderFns } from "./ranking.vue?vue&type=template&id=700f50ca&scoped=true"
import script from "./ranking.vue?vue&type=script&lang=ts"
export * from "./ranking.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700f50ca",
  null
  
)

export default component.exports