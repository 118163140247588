<template>
    <div class="modalWrapper">
        <div class="header">
            <div class="headerLeft"></div>
            <div class="headerCenter">
                <p class="title text-center">
                    <span class="mb-0 title">Dettaglio Switch</span>
                </p>
            </div>
            <div class="headerRight">
                <div class="headerButton" @click="$emit('close')">
                    <img src="@/img/Close_header.svg" />
                </div>
            </div>
        </div>
        <div class="assetItemBlock" v-for="(item, i) in ordini" :key="'o'+i">
            <div class="assetItem p-3">
                <div class="imageContainer">
                    <asset-type-icon class="" :label="item.tipoAsset"></asset-type-icon>
                </div>
                <div class="info">
                    <h2 class="w-80">{{item.strumento}}</h2>
                    <div class="d-flex justify-content-between">
                        <div class="text-left">
                            <small class="text-low-white">{{$store.state.dizionario.wallet_list_date_label}}</small>
                            <p class="mb-0 text-white">{{item.dataOrdine | date("YYYY-MM-DD")}}</p>
                        </div>
                        <div class="text-left">
                            <small class="text-low-white">{{$store.state.dizionario.wallet_list_movimentoDate_label}}</small>
                            <p class="mb-0 text-white">{{item.dataMovimento | date("YYYY-MM-DD")}}</p>
                        </div>
                        <div class="text-left">
                            <small class="text-low-white">{{$store.state.dizionario.wallet_list_value_label}}</small>
                            <p class="mb-0 text-white">€ {{item.controvalore | currency}}</p>
                        </div>
                    </div>
                </div>
                <div class="pendingImageContainer" v-if="isPending(item)">
                    <img :src="require('@/img/' + (item.tipoOrdine == 1 ? 'buy' : 'sell') + '_order.svg')" />
                    <small class="text-orange">PENDING</small>
                </div>
            </div>
        </div>
        <call-to-button @click="$emit('close')">Chiudi</call-to-button>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices';
import * as VM from '@/viewModel';
import AssetTypeIcon from '@/components/assetTypeIcon.vue';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';
import CallToButton from '@/components/callToButton.vue';

@Component({
    components: {
        AssetTypeIcon,
        CallToButton
    }
})
export default class SwitchDetailModal extends Vue {
    
    @Prop({
        type: [Array]
    }) ordini: VM.OrdinePendingVm[];

    isPending(ordine: VM.OrdinePendingVm){
        return new Date(ordine.dataMovimento) > new Date(store.state.systemDate);
    }

}
</script>
<style scoped>

    .header {
        position: relative;
    }

    .modalWrapper {
        background: rgb(18, 24, 29);
        height: calc(100% - 80px);
        overflow-y: auto;
    }

</style>
