<template>
    <div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class BlankPage extends Vue {

    created(){
    }
}

</script>