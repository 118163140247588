<template>
    <div class="imgCard" @click="select(label)">
        <img :src="require('@/img/' + imgName + '.svg')" />
        <p>{{label}}</p>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { removeDiacritics } from '@/utility';

@Component
export default class ImgCard extends Vue {

    @Prop() label: string;

    get imgName(){
        let ris = removeDiacritics(this.label).toLowerCase();
        return ris.replace(/ /g,"_");
    }

    select(item){
        this.$emit('select', item);
    }

}
</script>

<style scoped>

    .imgCard {
        border-radius: 8px;
        border: 1px solid rgba(34, 44, 52, 0.5);
        background-color: rgba(34, 44, 52, 0.3);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        text-align: center;
        height: 100%;
    }

    .imgCard img {
        height: 64px;
    }
    .imgCard p {
        margin-bottom: 0px;
        margin-top: 12px;
        color: #ffffff75;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.5px;
        width: 50%;
    }

</style>
