<template>
    <div class="modalWrapper">
        <div class="modalHeader">
            {{title}}
        </div>
        <div class="modalBody text-low-white">
            <p>{{text}}</p>
        </div>
        <div class="modalFooter">
            <button type="button" class="btn btn-dark btn-large" @click="$emit('close')" v-if="showCancel">
                {{cancelText || $store.state.dizionario.confirm_modal_annulla}}
            </button>
            <button type="button" class="btn btn-dark btn-large" @click="confirmFunction">
                {{confirmText || $store.state.dizionario.confirm_modal_conferma}}
            </button>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ConfirmModal extends Vue {
    
    @Prop({
        required: true
    }) title: string;

    @Prop({
        required: true
    }) text: string;
    
    @Prop({
        required: true
    }) callback: any;
    
    @Prop({
        required: false,
        default: true
    }) showCancel: boolean;

    @Prop({
        required: false,
        default: ''
    }) cancelText: string;
    
    @Prop({
        required: false,
        default: ''
    }) confirmText: string;
    
    confirmFunction(){
        this.callback(this);
        this.$emit('close');
    }
}
</script>
<style scoped>

    .modalWrapper{
        background: rgb(18, 24, 29);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        text-align: center;
    }

    .modalHeader {
        color: white;
        font-size: 16px;
        margin-bottom: 20px;
    }

    .modalBody {
        margin-bottom: 20px;
    }

    .modalFooter {
        display: flex;
        justify-content: space-around;
    }

    .modalFooter button {
        width: 40%;
    }

</style>
