<template>
    <div class="pt-4 px-3" v-if="loaded">
        <div class="d-flex justify-content-between w-100 mb-4">
            <div>
                <h5 class="cinturaTitle">{{selectedCintura.nome}}</h5>
                <div class="progressBar">
                    <progress-bar :completed="selectedCintura.progress" :color="selectedCintura.hexColor"></progress-bar>
                </div>
            </div>
            <div>
                <button type="button" class="btn textChanger minus mr-2" 
                    :disabled="classChangeDimension == 'dimension_1'"
                    @click="minusText">A</button>

                <button type="button" class="btn textChanger plus" 
                    :disabled="classChangeDimension == 'dimension_4'"
                    @click="plusText">A</button>
            </div>
        </div>
        <div class="d-flex lightDark">
            <tema-cintura :enabled="true" :completato="temaVm.completamento" :icona="temaVm.icona"
                :cintura-color="selectedCintura.hexColor"></tema-cintura>
            <div style="width: calc(100% - 100px);">
                <small class="text-orange">{{temaVm.nome}}</small>
                <p class="text-low-white text-large d-flex align-items-center">{{lezione.titolo}}</p>
            </div>
        </div>

        <div class="mt-4 text-center" v-if="lezione.usePolly">
            <audio-player v-if="showPlayer" :source="lezione.pollyUrl">
            </audio-player>
            <button type="button" v-else class="btn btn-dark" @click="startPolly">Ascolta la lezione audio</button>
        </div>
        
        <lezione-content class="mt-5 textChangeSizeContent" ref="textContainer"
            :class="classChangeDimension" :content="lezione.content"></lezione-content>

        <div class="text-center mb-5">
            <button type="button" class="btn btn-dark btn-large testButton" @click="navToTest">
                {{$store.state.dizionario.lezioni_test_button_label}}
                <i class="fa fa-chevron-right"></i>
            </button>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import router from '@/router';
import PageHeader from '@/components/pageHeader.vue';
import LezioneContent from '@/components/lezioneContent.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import TemaCintura from '@/components/temaCintura.vue';
import ProgressBar from '@/components/progressBar.vue';
import AudioPlayer from '@/components/audioPlayer.vue';
import { LezioneClient } from '@/services/Services';

@Component({
    components: {
        PageHeader,
        LezioneContent,
        TemaCintura,
        ProgressBar,
        AudioPlayer
    }
})
export default class DettaglioLezioneView extends Vue {

    lezione: OM.Lezione = new OM.Lezione();
    loaded: boolean = false;
    temaVm: OM.TemaVm = new OM.TemaVm();
    selectedCintura: VM.CinturaWithTemiVm = new VM.CinturaWithTemiVm();
    showPlayer: boolean = false;
    media: any;

    classChangeDimension: string = "dimension_1";
    changeDimensionStepNumber: number = 1;

    beforeRouteEnter(to, from, next){
        LezioneClient.getById(to.params.lezioneIdentifier)
        .then(x => {
            next((vc: DettaglioLezioneView) => {
                vc.lezione = x;
                vc.temaVm = StorageServices.getTemaVm();
                vc.selectedCintura = StorageServices.getSelectedCintura();
                vc.loaded = true;
            });
        });
    }

    navBack(){
        this.$router.back();
    }

    minusText(){
        if(this.changeDimensionStepNumber > 1) {
            this.changeDimensionStepNumber -= 1;
            this.classChangeDimension = "dimension_" + this.changeDimensionStepNumber;
        }
    }

    plusText(){
        if(this.changeDimensionStepNumber < 4) {
            this.changeDimensionStepNumber += 1;
            this.classChangeDimension = "dimension_" + this.changeDimensionStepNumber;
        }
    }

    navToTest(){
        StorageServices.setSelectedLezione(this.lezione);
        router.push('/cinture/' + this.$route.params.cinturaIdentifier + '/lezione/' + 
            this.$route.params.lezioneIdentifier + '/test')
    }

    startPolly(){
        this.showPlayer = true;

        // this.media = new Media(this.lezione.pollyUrl, () => {
        // });
        // this.media.play();
    }

}

</script>

<style scoped>

    .testButton {
        position: relative;
        border: 1px solid rgb(246, 156, 20)!important;
        text-align: left;
    }

    .testButton i {
        position: absolute;
        height: 12px;
        top: 0;
        bottom: 0px;
        right: 8px;
        margin: auto 0;
    }

    .textChangeSize {
        letter-spacing: 0.1px!important;
    }
        .textChangeSize .contentHtmlContainer * {
            font-family: 'Open Sans';
            font-weight: 300;
        }

</style>
