<template>
    <div class="assetList">
        <div class="benchmarkData" v-if="benchmark">
            <div class="customContainer">
                <div class="text-center">
                    <small class="text-orange">{{$store.state.dizionario.challenge_assets_list_benchmark_label}}</small>
                </div>
                <div class="d-flex justify-content-between px-5">
                    <div>
                        <small class="text-low-white">{{$store.state.dizionario.challenge_assets_list_perf_ytd}}</small>
                        <p class="mb-0 text-14" v-color="benchmark.performanceYtd">{{benchmark.performanceYtd | perc(1)}}%</p>
                    </div>
                    <div>
                        <small class="text-low-white">{{$store.state.dizionario.challenge_assets_list_perf_1y}}</small>
                        <p class="mb-0 text-14" v-color="benchmark.performance1Year">{{benchmark.performance1Year | perc(1)}}%</p>
                    </div>
                    <div>
                        <small class="text-low-white">{{$store.state.dizionario.challenge_assets_list_perf_3y}}</small>
                        <p class="mb-0 text-14" v-color="benchmark.performance3Year">{{benchmark.performance3Year | perc(1)}}%</p>
                    </div>
                    <div>
                        <small class="text-low-white">{{$store.state.dizionario.challenge_assets_list_vol_3y}}</small>
                        <p class="mb-0 text-14" v-color="benchmark.volatilita750">{{benchmark.volatilita750 | perc(1)}}%</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="assetItem p-3 mb-1" v-for="(item, i) in assets" :key="i" @click="openBuySellAssetModal(item)">
            <!-- :style="{'background-image': 'url(' + item.image + ')'}" -->
            <div class="imageContainer">
                <asset-type-icon class="" :label="item.tipo"></asset-type-icon>
            </div>
            <div class="info">
                <h2>{{item.nome}}</h2>
                <div class="d-flex justify-content-between">
                    <!-- <div v-for="(performance, index) in item.performance" :key="index">
                        <small class="text-low-white">{{performance.date}}</small>
                        <p class="text-green mb-0">{{performance.value | perc}}</p>
                    </div> -->
                    <div>
                        <small class="text-low-white">{{$store.state.dizionario.challenge_assets_list_perf_ytd}}</small>
                        <p class="mb-0 text-14" v-color="item.performanceYtd">{{item.performanceYtd | perc(1)}}%</p>
                    </div>
                    <div>
                        <small class="text-low-white">{{$store.state.dizionario.challenge_assets_list_perf_1y}}</small>
                        <p class="mb-0 text-14" v-color="item.performance1Year">{{item.performance1Year | perc(1)}}%</p>
                    </div>
                    <div>
                        <small class="text-low-white">{{$store.state.dizionario.challenge_assets_list_perf_3y}}</small>
                        <p class="mb-0 text-14" v-color="item.performance3Year">{{item.performance3Year | perc(1)}}%</p>
                    </div>
                    <div>
                        <small class="text-low-white">{{$store.state.dizionario.challenge_assets_list_vol_3y}}</small>
                        <p class="mb-0 text-14" v-color="item.volatilita750">{{item.volatilita750 | perc(1)}}%</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import WalletBar from '@/components/walletBar.vue';
import CircleCard from '@/components/circleCard.vue';
import { StorageServices } from '@/services/StorageServices'
import CallToButton from '@/components/callToButton.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import SwiperSlides from '@/components/swiperSlides.vue';
import PeriodiCard from '@/components/periodiCard.vue';
import { ModalServices } from '@/services/ModalServices';
import BuyAssetModal from '@/components/buyAssetModal.vue';
import AssetTypeIcon from '@/components/assetTypeIcon.vue';
import { AssetClient, OrdineClient, PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        WalletBar,
        CallToButton,
        SwiperSlides,
        PeriodiCard,
        AssetTypeIcon
    }
})
export default class AssetsListView extends Vue {

    assets: OM.AssetForBuyListVm[] = [];
    benchmark: OM.AssetForBuyListVm = null;

    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        store.state.headerText = `<small class="text-orange small">` + 
            to.params.macroClass + `</small><p class="mb-0">` + to.params.microClass + `</p>`

        let filterVm = new OM.AssetFilterVm();
        filterVm.challengeIdentifier = to.params.challengeIdentifier;
        filterVm.assetType = to.params.assetType;
        filterVm.macroClass = to.params.macroClass;
        filterVm.microClass = to.params.microClass;
        AssetClient.getAssetsForBuyList(filterVm)
        .then( data => {
            next((vc: AssetsListView) => {
                let benchIndex = data.findIndex(x => x.tipoAsset.toLowerCase() == 'indice');
                if(benchIndex > -1)
                    vc.benchmark = data.splice(benchIndex, 1)[0];
                vc.assets = data;
            });
        })
    }
    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        next();
    }

    openBuySellAssetModal(asset: OM.AssetForBuyListVm) {
        ModalServices.OpenModal(BuyAssetModal,
        {
            asset: asset,
            portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
            saveOrderCb: (vm: OM.CreaOrdineVm) => {
                OrdineClient.creaOrdine(vm)
                .then(x => {
                    PortafoglioClient.getPortafoglioStatusWithSwitch({
                        portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
                        creaSwitchVm: null
                    })
                    .then(x => {
                        store.state.portafoglioStatus = x;
                        StorageServices.setPortafoglioStatus(x);
                        let challenge = StorageServices.getSelectedChallenge();
                        this.$router.push('/challenge/' + challenge.identifier + '/' + challenge.portafoglioIdentifier + '/wallet')
                    });
                })
            }
        });
    }

}

</script>

<style scoped>

.assetList {
    padding-top: 60px;
}

.benchmarkData {
    background: #11171d;
    position: fixed;
    top: 113px;
    left: 0;
    right: 0;
    z-index: 9;
    height: 60px;
}

</style>
