<template>
    <div ref="contentContainer">
        <div v-if="content.html1" v-glossario="content.html1" class="contentHtmlContainer"></div>
        <div v-if="content.imageUrl1" class="contentImageContainer">
            <img :src="content.imageUrl1" />
        </div>
        <div v-if="content.html2" v-glossario="content.html2" class="contentHtmlContainer"></div>
        <div v-if="content.imageUrl2" class="contentImageContainer">
            <img :src="content.imageUrl2" />
        </div>
        <div v-if="content.html3" v-glossario="content.html3" class="contentHtmlContainer"></div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import { ModalServices } from '@/services/ModalServices';
import FullImageModal from '@/components/fullImageModal.vue';

@Component
export default class LezioneContent extends Vue {
    
    @Prop({
        type: [OM.LezioneContent, Object]
    }) content: OM.LezioneContent;

    container: any;
    mounted(){
        this.container = this.$refs.contentContainer;
        let imgs = this.container.querySelectorAll('img');
        imgs.forEach(x => {
            x.addEventListener('click', (ev) => {
                if(!window.cordova)
                    return;

                PhotoViewer.show(ev.target.src, '');
            });
        })
    }

}
</script>