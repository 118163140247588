<template>
    <div class="text-center">
        <div class="hideScrollBarX">
            <div class="slugsContainer">
                <div class="slug" :class="{ active: section == 'gare' }" @click="section = 'gare'">
                    Gare
                </div>
                <div class="slug" :class="{ active: section == 'inviti' }" @click="setInviti">
                    Inviti
                </div>
            </div>
        </div>
        <section v-if="section == 'gare'">
            <div v-if="challenges.length == 0">
                <h5 class="mt-5">Nessuna gara da mostrare</h5>
            </div>
            <div v-else>
                <div class="friendChallenge" v-for="(item, i) in challenges" :key="i" @click="rankingChallenge(item)">
                    <div class="d-flex justify-content-between">
                        <div>
                            <small>Data inizio:</small>
                            <p class="text-orange">{{item.dataInizio | date('DD MMM YYYY')}}</p>
                        </div>
                        <div class="text-right">
                            <small>Creata da:</small>
                            <p class="text-orange">{{item.creatorName}}</p>
                        </div>
                    </div>
                    <h3 class="slim">{{item.name}}</h3>
                    <div>
                        <small>Numero giocatori:</small>
                        <p class="text-orange">{{item.userCount}}</p>
                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-dark mt-2" :class="{'abs': challenges.length == 0 }" @click="createChallenge">
                Crea una nuova gara
            </button>
        </section>
        <section v-else>
            <div class="friendChallenge" v-for="(item, i) in receivedInvites" :key="i">
                <div class="d-flex justify-content-between">
                    <div class="text-right">
                        <small>Invitato da:</small>
                        <p class="text-orange">{{item.senderName}}</p>
                    </div>
                </div>
                <h3 class="slim">{{item.friendChallengeName}}</h3>
                <div class="d-flex justify-content-between">
                    <div>
                        <button type="button" class="btn btn-danger" @click="rifiutaInvito(item, i)">
                            Rifiuta
                        </button>
                    </div>
                    <div class="text-right">
                        <button type="button" class="btn btn-success" @click="accettaInvito(item, i)">
                            Accetta
                        </button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import router from '@/router';
import { Vue, Watch } from 'vue-property-decorator';
import Component from '@/routeComponent'
import { StorageServices } from '@/services/StorageServices'
import { ModalServices } from '@/services/ModalServices'
import store from '@/store';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import AlertModal from '@/components/alertModal.vue'
import { FriendChallengeClient } from '@/services/Services';

@Component({
    components: {
        AlertModal
    }
})
export default class ManageFriendChallenges extends Vue {

    challenges: VM.FriendChallengeVm[] = [];
    section: string = 'gare';
    receivedInvites: OM.FriendChallengeInvite[] = [];

    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        store.state.headerText = `<small class="text-orange small">` + challenge.titolo + `</small><p class="mb-0">` 
            + 'Gare con gli amici' + `</p>`;
        FriendChallengeClient.getByUser(store.state.loginResponse.userIdentifier, challenge.identifier)
        .then(x => {
            next((vc: ManageFriendChallenges) => {
                vc.challenges = x;
            });
        })
    }

    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        next();
    }

    createChallenge(){
        this.$router.push('createFriendChallenge');
    }

    rankingChallenge(item: VM.FriendChallengeVm){
        StorageServices.setSelectedFriendChallenge(item);
        this.$router.push('friendChallengeRanking/' + item.identifier);
    }

    setInviti(){
        this.section = 'inviti';
        if(this.receivedInvites.length == 0){
            let challenge = StorageServices.getSelectedChallenge();
            FriendChallengeClient.getReceivedInvites(store.state.loginResponse.userIdentifier, challenge.identifier)
            .then(x => {
                this.receivedInvites = x;
            });
        }
    }

    rifiutaInvito(item: OM.FriendChallengeInvite, index: number){
        FriendChallengeClient.denyInvite(item.identifier)
        .then(x => {
            this.receivedInvites.splice(index, 1);
            ModalServices.OpenModal(AlertModal, {
                text: 'Invito rifiutato!'
            })
        })
    }

    accettaInvito(item: OM.FriendChallengeInvite, index: number){
        FriendChallengeClient.acceptInvite(item.identifier)
        .then(x => {
            this.receivedInvites.splice(index, 1);
            ModalServices.OpenModal(AlertModal, {
                text: 'Invito Accettato!'
            }, () => {
                let challenge = StorageServices.getSelectedChallenge();
                FriendChallengeClient.getByUser(store.state.loginResponse.userIdentifier, challenge.identifier)
                .then(x => {
                    this.challenges = x;
                })
            })
        })
    }
}

</script>

<style scoped>

    button.btn.btn-dark.abs {
        position: absolute;
        bottom: 20vh;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 220px;
    }

</style>