<template>
    <div class="registerContainer">
        <div class="text-center">
            <img src="@/img/App_icon.svg" />
            <h4 class="mt-2 text-low-white">Accedi</h4>
        </div>
        <div>
            <div class="form-group">
                <label>Email</label>
                <input class="form-control" type="email" v-model="vm.email" />
            </div>
            <div class="form-group">
                <label>Password</label>
                <input type="password" class="form-control" v-model="vm.password" />
            </div>
        </div>
        
        <span v-if="loginError" class="error-message px-3 text-center">Utente non trovato</span>
        
        <div class="w-100 d-flex justify-content-around">
            <button type="button" class="btn btn-dark" @click="back">Indietro</button>
            <button type="button" class="btn btn-dark" @click="login">Accedi</button>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import router from '@/router';
import store from '@/store';
import { fbRedirectPage } from '@/config';
import { AuthServices } from '@/services/AuthServices';
import { StorageServices } from '@/services/StorageServices';
import * as VM from '@/viewModel';
import { appVersion } from '@/config';

@Component
export default class LoginForm extends Vue {

    vm: VM.LoginDataWithVersion = new VM.LoginDataWithVersion();
    loginError: boolean = false;

    created(){
        this.vm.appVersion = appVersion;
    }

    back(){
        this.$router.back();
    }

    login(){
        this.loginError = false;

        AuthServices.LoginWithVersion(this.vm).then( x => {
            AuthServices.setAuthToken(x.token);
            store.state.loginResponse = x;
            StorageServices.setLoginResponse(x);
            router.replace("/blank");
        }).catch( () =>{
            this.loginError = true;
        })
    }

}
</script>
<style scoped>


</style>
