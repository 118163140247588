<template>
    <div>
        <div class="temaProgressBarWrapper">
            <span class="numPercentage" :class="{ moving: moving }">
                <animated-number :value="localCompleted" :decimals="0" :speed="2000" :start-from="localCompleted">
                    <span slot-scope="animated">{{animated.value}}%</span>
                </animated-number>
            </span>
            <div class="temaProgressBar" :class="{ animated: animate, moving: moving }">
                <div class="completedStep"
                    :style="'width:' + localCompleted + '%; background-color:' + color + ';'"></div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import AnimatedNumber from '@/components/animatedNumber.vue'

@Component({
    components: {
        AnimatedNumber
    }
})
export default class ProgressBar extends Vue {
    
    @Prop() completed: number;
    @Prop() color: string;
    
    @Prop({
        required: false,
        default: false,
        type: Boolean
    }) animate: boolean;

    localCompleted: number = 0;
    moving: boolean = false

    movingTimeout: number;
    @Watch('completed')
    onCompletedChange(next, prev){
        this.localCompleted = next;
        if(this.animate){
            this.moving = true;
            clearTimeout(this.movingTimeout);
            setTimeout(() => {
                this.moving = false;
            }, 3000);
        }
    }

    created(){
        if(this.animate){
            this.localCompleted = 0;
            setTimeout(() => {
                this.localCompleted = this.completed || 0;
            }, 0)
        } else {
            this.localCompleted = this.completed || 0;
        }
    }


}
</script>
<style scoped>


    .temaProgressBar {
        width: 80%;
        background-color: rgba(34, 44, 52);
        border-radius: 5px;
        overflow: hidden;
        /* background-color: rgba(255,255,255,0.5); */
    }

    .temaProgressBarWrapper .numPercentage {
        color: #fff;
        font-size: 0.8em;
        font-weight: 600;
        letter-spacing: 1px;
        display: block;
        transition: transform 0.2s linear;
    }
    .temaProgressBarWrapper .numPercentage.moving {
        transform: translateY(-5px);
    }

    .temaProgressBar.animated {
        transition: transform 0.2s linear;
    }
    .temaProgressBar.animated .completedStep {
        /* transition: width 2.8s cubic-bezier(0, 0.35, 0.30, 1); */
        transition: width 2s;
    }

    .temaProgressBar.moving {
        transform: scaleY(2.5);
    }

    .temaProgressBar .completedStep {
        height: 5px;
        background-color: #fff;
    }

</style>
