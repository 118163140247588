var _baseUrl = process.env.VUE_APP_ENDPOINT;

console.log(_baseUrl);

export let appVersion = "2.2.1";
 
export let privacyPolicyUrl = "https://password.capitaleyes.app/privacy_policy.html";
export let termsAndConditionUrl = "https://password.capitaleyes.app/terms_and_conditions.html";

export let playStoreUrl = "https://play.google.com/store/apps/details?id=app.capitaleyes.com";
export let appleStoreUrl = "https://apps.apple.com/it/app/capitaleyes/id1462904170";

export let baseUrl = _baseUrl;


const _fbRedirectPage = "https://localhost:8080";

export let fbRedirectPage = _fbRedirectPage;



//custom variable set in package.json
export let platform = process.env.VUE_APP_PLATFORM ? process.env.VUE_APP_PLATFORM.trim() : "";
export let debugAnalytics = false;

export const platformList = {
    web: "web",
    android: "android",
    ios: "ios"
}