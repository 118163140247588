<template>
    <div class="fullCard">
        <h5 class="pl-3 pt-3">{{$store.state.dizionario.challenge_assets_chart_drawdown_label}}</h5> <!-- Abbiamo tolto un <br /> tra le parole Max e Drawdown -->
        <!-- <h4 class="title pl-3 pt-4 mb-0 text-white">Max <br />Drawdown</h4> -->
        <div class="d-flex justify-content-between pl-3 pr-3 pt-2">
            <div>
                <p class="text-big" v-color="drawdown.drawDown">{{drawdown.drawDown | perc(1)}}%</p>
            </div>
            <div>
                <small class="text-low-white">{{durataDrawdownLabel}}</small>
                <p class="text-large text-white text-center">{{dataFine() != '-' ? durataDrawdownDays : '-'}}</p>
            </div>
        </div>
        <div class="d-flex justify-content-between text-center mt-4">
            <div class="w-33">
                <small class="text-low-white">{{$store.state.dizionario.challenge_assets_chart_drawdown_started}}</small>
                <p class="text-white">{{drawdown.dataInizioDrawdown | date('DD MMM YY')}}</p>
            </div>
            <div class="w-33">
                <small class="text-low-white">{{$store.state.dizionario.challenge_assets_chart_drawdown_min}}</small>
                <p class="text-white">{{drawdown.prezzoMin.date | date('DD MMM YY')}}</p>
            </div>
            <div class="w-33">
                <small class="text-low-white">{{$store.state.dizionario.challenge_assets_chart_drawdown_recupero}}</small>
                <p class="text-white">
                    {{dataFine()}}
                </p>
            </div>
        </div>
        <div class="d-flex justify-content-center text-center pb-4">
            <div class="leftDays">
                <small class="text-low-white">{{startedLabel}}</small>
                <p class="text-white">{{startedMinDays}}</p>
            </div>
            <div class="rightDays">
                <small class="text-low-white">{{minRecuperoLabel}}</small>
                <p class="text-white">{{minRecuperoDays}}</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import moment from 'moment';

@Component
export default class DrawdownCard extends Vue {

    @Prop({
        type: [ Object, OM.Drawdown ]
    }) drawdown: OM.Drawdown;


    startedLabel: string = "-";
    minRecuperoLabel: string = "-";
    durataDrawdownLabel: string = "-";

    startedMinDays: any = '-';
    minRecuperoDays: any = '-';
    durataDrawdownDays: any = '-';

    created(){
        let diff = Math.abs(new Date(this.drawdown.dataInizioDrawdown).getTime() - new Date(this.drawdown.prezzoMin.date).getTime());
        let ris = parseFloat((diff / 1000 / 60 / 60 / 24).toFixed(0));
        if(ris < 365){
            this.startedLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_days;
            this.startedMinDays = ris;
        } else if(ris < 1080) {
            this.startedLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_months;
            this.startedMinDays = Math.round(ris / 30);
        } else {
            this.startedLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_years;
            this.startedMinDays = parseFloat((ris / 365).toFixed(1));
        }

        if(this.drawdown.dataFineDrawdown){
            diff = Math.abs(new Date(this.drawdown.prezzoMin.date).getTime() - new Date(this.drawdown.dataFineDrawdown).getTime());
            ris = parseFloat((diff / 1000 / 60 / 60 / 24).toFixed(0));
            if(ris < 365){
                this.minRecuperoLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_days;
                this.minRecuperoDays = ris;
            } else if(ris < 1080) {
                this.minRecuperoLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_months;
                this.minRecuperoDays = Math.round(ris / 30);
            } else {
                this.minRecuperoLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_years;
                this.minRecuperoDays = parseFloat((ris / 365).toFixed(1));
            }
        }

        this.drawdown.durataDrawdown;
        if(this.drawdown.durataDrawdown < 365){
            this.durataDrawdownLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_days;
            this.durataDrawdownDays = this.drawdown.durataDrawdown;
        } else if(this.drawdown.durataDrawdown < 1080) {
            this.durataDrawdownLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_months;
            this.durataDrawdownDays = Math.round(this.drawdown.durataDrawdown / 30);
        } else {
            this.durataDrawdownLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_years;
            this.durataDrawdownDays = parseFloat((this.drawdown.durataDrawdown / 365).toFixed(1));
        }
    }

    dataFine(){
        if(this.drawdown.dataFineDrawdown)
            return moment(this.drawdown.dataFineDrawdown).format('DD MMM YY');
        else
            return '-';
    }
}

</script>

<style scoped>

    .fullCard .title {
        font-size: 23px;
        color: rgb(50, 60, 71);
        font-family: 'Poppins';
    }

    .selezionaPeriodoSelect {
        display: block;
        background: transparent;
        border: 1px solid rgb(46, 50, 56);
        border-radius: 12px;
        width: 120px;
        height: 25px;
        text-transform: uppercase;
        padding: 0 4px;
        outline: none!important;
        font-weight: 700;
        margin: 7px 0;
    }

    .text-big {
        font-size: 70px;
        letter-spacing: -3px;
        font-family: 'Poppins-ExtraLight';
        margin: 0;
        line-height: 1.2;
    }

    .text-large {
        font-size: 33px;
        letter-spacing: 0.39px;
        font-family: 'Poppins-ExtraLight';
        margin: 4px 0 0;
        line-height: 1.2;
    }

    .leftDays {
        position: relative;
        margin-right: 14%;
        padding-top: 4px;
        line-height: 2;
    }
    
    .rightDays {
        position: relative;
        margin-left: 14%;
        padding-top: 4px;
        line-height: 2;
    }

    .leftDays::before, .rightDays::before {
        content: '';
        border-bottom: 1px solid orange;
        border-left: 1px solid orange;
        width: 5vw;
        height: 40px;
        position: absolute;
        left: -11vw;
        top: 0;
    }
    
    .leftDays::after, .rightDays::after {
        content: '';
        border-bottom: 1px solid orange;
        border-right: 1px solid orange;
        width: 5vw;
        height: 40px;
        position: absolute;
        right: -11vw;
        top: 0;
    }

</style>