export function stdDev(input: number[]){
    let media = average(input);
    let sumOfSquaresOfDifferences = 0;
    input.forEach(x => {
        sumOfSquaresOfDifferences += Math.pow(x - media, 2);
    });
    return Math.sqrt(sumOfSquaresOfDifferences / (input.length - 1));
}

export function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function average(input: number[]){
    let sum = 0;
    input.forEach(x => sum += x);
    return sum / input.length;
}

export function removeDiacritics(input: string){
    //input = input.replace(new RegExp(/\s/g),"");
    input = input.replace(new RegExp(/[àáâãäå]/g),"a");
    input = input.replace(new RegExp(/æ/g),"ae");
    input = input.replace(new RegExp(/ç/g),"c");
    input = input.replace(new RegExp(/[èéêë]/g),"e");
    input = input.replace(new RegExp(/[ìíîï]/g),"i");
    input = input.replace(new RegExp(/ñ/g),"n");                
    input = input.replace(new RegExp(/[òóôõö]/g),"o");
    input = input.replace(new RegExp(/œ/g),"oe");
    input = input.replace(new RegExp(/[ùúûü]/g),"u");
    input = input.replace(new RegExp(/[ýÿ]/g),"y");
    
    return input;
}

export function beltColorToSvg(input: string){
    let ris = '';
    if(input == '#F0F8FE'){
        ris = "belt_white.svg";
    } else if(input == '#FFD700'){
        ris = "belt_yellow.svg";
    } else if(input == '#FF8200'){
        ris = "belt_orange.svg";
    } else if(input == '#31B700'){
        ris = "belt_green.svg";
    } else if(input == '#0085CA'){
        ris = "belt_blue.svg";
    } else if(input == '#7C4D3A'){
        ris = "belt_brown.svg";
    } else if(input == '#15001F'){
        ris = "belt_black.svg";
    } 
    return ris;
}