<template>
    <div class="challenge" :class="{ 'partecipa': challenge.portafoglioIdentifier, 'closed': challenge.winnerName }">
        <div class="challengeBand">
            <div class="challengeIcon"></div>
        </div>
        <div class="challengeInfo px-3">
            <div class="d-flex justify-content-between">
                <div>
                    <small>{{$store.state.dizionario.challenge_card_startDate_label}}</small>
                    <p class="text-orange">{{challenge.dataInizio | date('DD MMM YYYY')}}</p>
                </div>
                <div>
                    <small>{{$store.state.dizionario.challenge_card_endDate_label}}</small>
                    <p class="text-orange">{{challenge.dataFine | date('DD MMM YYYY')}}</p>
                </div>
            </div>
            <h3 class="slim">{{challenge.titolo}}</h3>
            <div class="d-flex justify-content-between">
                <div class="risk">
                    <small>{{$store.state.dizionario.challenge_card_starsRisk_label}}</small>
                    <stars-risk :activeProp="challenge.stelline"></stars-risk>
                    <!-- <p class="text-orange">Stelline - {{challenge.percentualeRischio}}</p> -->
                </div>
            </div>
            <div class="players">
                <small>{{$store.state.dizionario.challenge_card_players_label}}</small>
                <p class="text-orange biggerParagraph">{{players || '-'}}</p>
            </div>
            <!-- <div class="ranking">
                <small>{{$store.state.dizionario.challenge_card_ranking_label}}</small>
                <p class="biggerParagraph"><span class="text-orange">685</span> / 1234</p>
            </div> -->
            <div class="winner">
                <small>{{$store.state.dizionario.challenge_card_winner_label}}</small>
                <p class="text-white">{{challenge.winnerName}}</p>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import SwiperSlides from '@/components/swiperSlides.vue';
import StarsRisk from '@/components/starsRisk.vue';
import { ChallengeClient } from '@/services/Services';

@Component({
    components: {
        StarsRisk
    }
})
export default class ChallengeCard extends Vue {

    @Prop({
        type: [Object, VM.ChallengeVm]
    }) challenge: VM.ChallengeVm;

    players: number = null;

    created(){
        ChallengeClient.countPlayersInChallenge(this.challenge.identifier)
        .then(x => {
            this.players = x;
        })
    }
}
</script>

<style scoped>

    .challenge {
        border: 1.5px solid #2e3238;
        border-radius: 14px;
        margin-bottom: 16px;
        min-height: 190px;
        overflow: hidden;
        display: flex;
        position: relative;
    }
    
    .challenge p {
        margin-bottom: 8px;
    }
    .challenge p.biggerParagraph {
        font-size: 14px;
        margin: 6px 0 0px;
    }

    .challenge:not(.partecipa) .ranking{
        display: none;
    }
    
    .challenge:not(.closed) .winner{
        display: none;
    }

    .challengeBand {
        height: 100%;
        width: 16%;
        background-color: rgb(246, 156, 20);
        transition: background-color 0.4s;
        position: absolute;
    }
        .challengeBand .challengeIcon {
            width: 21px;
            height: 30px;
            margin: 13px auto 0;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('../img/play_chessIcon.png');
        }

    .challengeInfo {
        height: 100%;
        width: 84%;
        padding: 10px 0;
        transition: background-color 0.4s;
        margin-left: 16%;
    }

    .challenge.partecipa .challengeBand {
        background-color: rgb(126, 211, 33);
    }
        .challenge.partecipa .challengeBand .challengeIcon {
            width: 30px;
            background-image: url('../img/checkIcon.png');
        }

    .challenge.partecipa .challengeInfo {
        background-color: rgb(34, 44, 52);
    }

    /* .challenge.partecipa .challengeInfo .players {
        display: none;
    } */

    .challenge.closed {
        border-color: rgba(246, 156, 20, 0.7);
    }
    
    .challenge.closed .challengeBand {
        background-color: rgb(25, 32, 39);
    }
        .challenge.closed .challengeBand .challengeIcon {
            width: 26px;
            background-image: url('../img/play_crownIcon.png');
        }

    .challenge.closed .challengeInfo {
        background-color: rgb(34, 44, 52);
    }
    
    .challenge.closed .challengeInfo h3 {
        color: rgb(98, 102, 105);
    }

    .challenge.closed .challengeInfo .players {
        display: none;
    }

</style>
