<template>
    <div class="fullPage" style="padding: 0 30px;">
        <div class="d-flex flex-column justify-content-around h-100">
            <router-link class="menuLink" to="/percorsi/1/allTemi">
                <div class="menuImgContainer">
                    <img src="@/img/principiante.svg" />
                    <span>{{$store.state.dizionario.percorso_1}}</span>  
                </div>
            </router-link>
            <router-link class="menuLink" to="/percorsi/2/allTemi">
                <div class="menuImgContainer">
                    <img src="@/img/medio.svg" />
                    <span>{{$store.state.dizionario.percorso_2}}</span>  
                </div>
            </router-link>
            <router-link class="menuLink" to="/percorsi/3/allTemi">
                <div class="menuImgContainer">
                    <img src="@/img/esperto.svg" />
                    <span>{{$store.state.dizionario.percorso_3}}</span>
                </div>
            </router-link>
            <router-link class="menuLink" to="/percorsi/4/allTemi">
                <div class="menuImgContainer">
                    <img src="@/img/super_esperto.svg" />
                    <span>{{$store.state.dizionario.percorso_4}}</span>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';
import { CinturaClient } from '@/services/Services';

@Component({})
export default class PercorsiView extends Vue {

}

</script>

<style scoped>

</style>
