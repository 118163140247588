<template>
    <div class="modalWrapper">
        <div class="modalHeader">
            <i v-if="type == 'success'" class="fas fa-thumbs-up fa-3x text-success"></i>
            <i v-if="type == 'error'" class="fas fa-times fa-3x text-danger"></i>
        </div>
        <div class="modalBody">
            <h5>{{text}}</h5>
        </div>
        <div class="modalFooter">
            <button type="button" class="btn btn-dark btn-large" @click="$emit('close')">
                Ok
            </button>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices'

@Component
export default class AlertModal extends Vue {
    
    @Prop({
        required: true
    }) text: string;

    @Prop({
        required: false
    }) type: string;
    
}
</script>
<style scoped>

.modalWrapper {
    height: 100%;
    display: grid;
    grid-template-rows: auto auto auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    background-color: #11171d;
}

.modalHeader {
    grid-row: 1/2;
}

.modalHeader i {
    animation: scala 1s 1;
    animation-delay: .2s;
}

.modalBody {
    grid-row: 2/3;
}

.modalFooter {
    grid-row: 3/4;
}

@keyframes scala {
    0%{
        transform: scale(1);
    }
    15%{
        transform: scale(1.5);
    }
    30%{
        transform: scale(1);
    }
    45%{
        transform: scale(1.5);
    }
    60%{
        transform: scale(1);
    }
    75%{
        transform: scale(1.5);
    }
    100%{
        transform: scale(1);
    }
}

</style>
