import * as OM from '@/model';
import { IModalPlugin } from '@/plugins/modalPlugin/modalPlugin';
import { Vue } from 'vue-property-decorator';

class _ModalServices  {
    
    $modal: IModalPlugin;
    SetModalInstance($modal){
        this.$modal = $modal;
    }

    modalNames = [];
    private closeFromService: boolean = false;
    OpenModal(modalComponent, props, closeCallback?){
        this.$modal.show(
            modalComponent, 
            props,
            closeCallback
        );
    }
    CloseLastModal(useCallback: boolean = false){
        this.$modal.closeLast();
    }
}

export let ModalServices = new _ModalServices();