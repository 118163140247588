<template>
    <div class="modalWrapper">
        <div class="modalHeader header">
            <div class="headerLeft"></div>
            <div class="headerCenter">
                <p class="title text-center">
                    Capital<span class="subtitle">EYES</span>
                    <br>
                </p>
            </div>
            <div class="headerRight pr-3" v-if="showClose" @click="save">
                <img src="@/img/Close_header.svg" />
            </div>
        </div>
        <div class="modalBody p-3 text-low-white" v-if="infoConfig">
            <p class="text-14">
                {{$store.state.dizionario.userinfo_description}}
            </p>
            <div class="form-group">
                <label>{{$store.state.dizionario.userinfo_sessoLabel}}</label>
                <div class="d-flex justify-content-around">
                    <button type="button" class="btn" 
                        :class="vm.info.sesso == 'M' ? 'btn-orange' : 'btn-gray'" @click="vm.info.sesso = 'M'">
                        {{$store.state.dizionario.userinfo_maschioChoice_Label}}
                    </button>
                    <button type="button" class="btn" 
                        :class="vm.info.sesso == 'F' ? 'btn-orange' : 'btn-gray'" @click="vm.info.sesso = 'F'">
                        {{$store.state.dizionario.userinfo_femminaChoice_Label}}
                    </button>
                </div>
            </div>
            <div class="form-group">
                <label>{{$store.state.dizionario.userinfo_fasciaDiEta}}</label>
                <select class="form-control" v-model="vm.info.fasciaDiEta">
                    <option v-for="(item, i) in infoConfig.fasceDiEtaOptions" :key="i" :value="item.value">
                        {{item.text}}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>{{$store.state.dizionario.userinfo_statoLavorativo}}</label>
                <select class="form-control" v-model="vm.info.statoLavorativo">
                    <option v-for="(item, i) in infoConfig.statoLavorativoOptions" :key="i" :value="item.value">
                        {{item.text}}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>{{$store.state.dizionario.userinfo_conoscenzaFinanziaria}}</label>
                <select class="form-control" v-model="vm.info.conoscenzaFinanziaria">
                    <option v-for="(item, i) in infoConfig.conoscenzaFinanziariaOptions" :key="i" :value="item.value">
                        {{item.text}}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>{{$store.state.dizionario.userinfo_comeMaiInteresse}}</label>
                <select class="form-control" v-model="vm.info.comeMaiInteresse">
                    <option v-for="(item, i) in infoConfig.comeMaiInteresseOptions" :key="i" :value="item.value">
                        {{item.text}}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>{{$store.state.dizionario.userinfo_comeSeiArrivato}}</label>
                <select class="form-control" v-model="vm.info.comeSeiArrivato">
                    <option v-for="(item, i) in infoConfig.comeSeiArrivatoOptions" :key="i" :value="item.value">
                        {{item.text}}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>{{$store.state.dizionario.userinfo_quantoTempoPensi}}</label>
                <select class="form-control" v-model="vm.info.quantoTempoPensi">
                    <option v-for="(item, i) in infoConfig.quantoTempoPensiOptions" :key="i" :value="item.value">
                        {{item.text}}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>{{$store.state.dizionario.userinfo_areaGeo1Label}}</label>
                <select class="form-control" v-model="vm.info.areaGeo1" @change="getProvince">
                    <option v-for="(item, i) in regioni" :key="i" :value="item">
                        {{item}}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>{{$store.state.dizionario.userinfo_areaGeo2Label}}</label>
                <select class="form-control" :class="{ disabled: !vm.info.areaGeo1}" :disabled="!vm.info.areaGeo1"
                    v-model="vm.info.areaGeo2" @change="getComuni">
                    <option v-for="(item, i) in province" :key="i" :value="item">
                        {{item}}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>{{$store.state.dizionario.userinfo_areaGeo3Label}}</label>
                <select class="form-control" :class="{ disabled: !vm.info.areaGeo2}" :disabled="!vm.info.areaGeo2"
                    v-model="vm.info.areaGeo3">
                    <option v-for="(item, i) in comuni" :key="i" :value="item">
                        {{item}}
                    </option>
                </select>
            </div>
            <div class="text-center">
                <button type="button" class="btn btn-dark" @click="save">
                    {{$store.state.dizionario.userinfo_closeButton}}
                </button>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { GlobalConfigClient, UserClient } from '@/services/Services';

@Component
export default class InfoModal extends Vue {

    @Prop({
        type: [Object, VM.ProfileVm]
    }) vm: VM.ProfileVm;
    
    @Prop({
        type: Boolean,
        default: true
    }) showClose: boolean;

    infoConfig: OM.QuestionarioInizialeConfig = null;

    regioni: string[] = [];
    province: string[] = [];
    comuni: string[] = [];

    created(){
        let proms = [];
        proms.push(GlobalConfigClient.getUserInfoConfig());
        proms.push(GlobalConfigClient.getRegioni());
        if(this.vm.info.areaGeo1)
            proms.push(GlobalConfigClient.getProvince(this.vm.info.areaGeo1));
        if(this.vm.info.areaGeo2)
            proms.push(GlobalConfigClient.getComuni(this.vm.info.areaGeo2));

        Promise.all(proms)
        .then(xs => {
            this.infoConfig = xs[0];
            this.regioni = xs[1];
            this.province = xs[2];
            this.comuni = xs[3];
        })
    }

    getProvince(){
        this.vm.info.areaGeo2 = '';
        this.vm.info.areaGeo3 = '';
        GlobalConfigClient.getProvince(this.vm.info.areaGeo1)
        .then(x => {
            this.province = x;
        })
    }
    
    getComuni(){
        this.vm.info.areaGeo3 = '';
        GlobalConfigClient.getComuni(this.vm.info.areaGeo2)
        .then(x => {
            this.comuni = x;
        })
    }

    save(){
        UserClient.saveProfile(this.vm)
        .then(x => {
            UserClient.setQuestionarioIniziale(this.vm.identifier, true);
            this.$emit('close');
        })
    }

}
</script>
<style scoped>

    .header {
        position: relative;
    }

    .modalWrapper {
        background: rgb(18, 24, 29);
        height: 100%;
        /* overflow: scroll; */
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    
    .form-group label {
        display: block;
        color: #fafafcb3;
        color: rgb(246, 156, 20);
    }
    
</style>
