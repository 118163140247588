export enum TipoAsset
{
    Benchmark = 1,
    Valuta,
    Azione,
    Obbligazione,
    Etf,
    Fondo
}

export enum TipoOrdine
{
    Acquisto = 1,
    Vendita,
    VenditaSwitch,
    AcquistoSwitch
}

export enum FriendChallengeInviteMode
{
    Azzera = 1,
    Prosegui
}

export let FriendChallengeInviteStatus = 
{
    Pending: "pending",
    Accepted: "accepted",
    Denied: "denied"
}

export let ResocontoType =
{
    LastQuarter: "LastQuarter",
    QuarterToDate: "QuarterToDate",
    LastMonth: "LastMonth",
    MonthToDate: "MonthToDate",
    LastWeek: "LastWeek",
    WeekToDate: "WeekToDate"
}

export let BenchmarkIdentifier = '15c10e92-6467-4312-a664-aa3000f99a11';
export let LiquidityIdentifier = '1fab8c58-21ec-4d0a-8915-6b83ff12dcb1';