<template>
    <div>
        <div class="swiperContainer" ref="swiperContainer">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(n, i) in 101" :key="i">
                    <div :class="{'_decina': (n-1) % 10 == 0, '_unita': (n-1) % 10 != 0}" v-typeclass="assetType">
                        {{n - 1}}
                    </div>
                </div>
            </div>
        </div>
        <div class="_infiniteAsticella"></div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Swiper from 'swiper';

@Component
export default class SwiperPercentage extends Vue {
    @Prop() value: number;
    @Prop() assetType: string;

    slideWidth: number;
    minTranslate: number;
    maxTranslate: number;
    step: number;

    swiper: any;
    internalValue: number = 0;
    mounted(){
        let comp = this;
        function transFunction(){
            comp.internalValue = (this.translate - comp.minTranslate) / (comp.step);
            if(comp.internalValue < 0 || isNaN(comp.internalValue))
                comp.internalValue = 0;
            if(comp.internalValue > 100)
                comp.internalValue = 100;
            comp.internalValue = Math.round(comp.internalValue * 10) / 10;
            comp.$emit('input', comp.internalValue);
        }
        this.swiper = new Swiper(this.$refs.swiperContainer, {
            direction: 'horizontal',
            slidesPerView: 10,
            speed: 100,
            freeMode: true,
            freeModeMomentumBounce: false,
            centeredSlides: true,
            slideToClickedSlide: true,
            on: {
                transitionEnd: transFunction,
                sliderMove: transFunction
            },
        });
        this.slideWidth = this.swiper.width;
        this.minTranslate = this.swiper.translate;
        this.maxTranslate = -(this.slideWidth * 10 - this.minTranslate);
        this.step = (this.maxTranslate - this.minTranslate) / 100;
    }

    @Watch('value')
    onValueChange(next, prev){
        let setTo = this.minTranslate + next * this.step;
        this.swiper.setTranslate(setTo);
    }

}

</script>

<style scoped>
    .swiper-slide {
        display: flex;
        height: 30px;
        justify-content: center;
        align-items: center;
    }
    ._decina {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: inline-block;
        background-color: #d38e0f;
        text-align: center;
        line-height: 30px;
        color: white;
    }
    ._unita {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        display: inline-block;
        background-color: #d38e0f;
        text-align: center;
        line-height: 20px;
        color: white;
    }
    /* ._infiniteAsticella {
        height: 30px;
        width: 2px;
        background: #ff9b76;
        margin: auto;
        bottom: 0px;
        position: absolute;
        left: 0px;
        right: 0px;
    } */
</style>