<template>
    <div class="modalWrapper">
        <div class="modalHeader header">
            <div class="headerLeft"></div>
            <div class="headerCenter">
                <p class="title text-center">
                    {{$store.state.dizionario.rankingFilter_headerTitle}}
                </p>
            </div>
            <div class="headerRight pr-3" @click="rollback">
                <img src="@/img/Close_header.svg" />
            </div>
        </div>
        <div class="modalBody px-3 text-low-white" v-if="infoConfig">
            <p class="text-14">
                {{$store.state.dizionario.rankingFilter_description}}
            </p>
            <div class="form-group">
                <label>{{$store.state.dizionario.userinfo_fasciaDiEta}}</label>
                <div class="" :class="{'d-flex': vm.fasciaDiEta}">
                    <select class="form-control" v-model="vm.fasciaDiEta">
                        <option v-for="(item, i) in infoConfig.fasceDiEtaOptions" :key="i" :value="item.value">
                            {{item.text}}
                        </option>
                    </select>
                    <button type="button" class="btn btn-clear" v-if="vm.fasciaDiEta" @click="vm.fasciaDiEta = 0">
                        <img src="@/img/Close_header.svg" />
                    </button>
                </div> 
            </div>
            <div class="form-group">
                <label>{{$store.state.dizionario.userinfo_statoLavorativo}}</label>
                <div class="" :class="{'d-flex': vm.statoLavorativo}">
                    <select class="form-control" v-model="vm.statoLavorativo">
                        <option v-for="(item, i) in infoConfig.statoLavorativoOptions" :key="i" :value="item.value">
                            {{item.text}}
                        </option>
                    </select>
                    <button type="button" class="btn btn-clear" v-if="vm.statoLavorativo" @click="vm.statoLavorativo = 0">
                        <img src="@/img/Close_header.svg" />
                    </button>
                </div> 
            </div>
            <div class="form-group">
                <label>{{$store.state.dizionario.userinfo_conoscenzaFinanziaria}}</label>
                <div class="" :class="{'d-flex': vm.conoscenzaFinanziaria}">
                    <select class="form-control" v-model="vm.conoscenzaFinanziaria">
                        <option v-for="(item, i) in infoConfig.conoscenzaFinanziariaOptions" :key="i" :value="item.value">
                            {{item.text}}
                        </option>
                    </select>
                    <button type="button" class="btn btn-clear" v-if="vm.conoscenzaFinanziaria" @click="vm.conoscenzaFinanziaria = 0">
                        <img src="@/img/Close_header.svg" />
                    </button>
                </div> 
            </div>
            <div class="form-group">
                <label>Regione</label>
                <div class="" :class="{'d-flex': vm.areaGeo1}">
                    <select class="form-control" v-model="vm.areaGeo1" @change="getProvince">
                        <option v-for="(item, i) in regioni" :key="i" :value="item">
                            {{item}}
                        </option>
                    </select>
                    <button type="button" class="btn btn-clear" v-if="vm.areaGeo1" 
                        @click="() => {vm.areaGeo1 = ''; vm.areaGeo2 = ''; vm.areaGeo3 = ''; }">
                        <img src="@/img/Close_header.svg" />
                    </button>
                </div>
            </div>
            <div class="form-group">
                <label>Provincia</label>
                <div class="" :class="{'d-flex': vm.areaGeo2}">
                    <select class="form-control" :class="{ disabled: !vm.areaGeo1}" :disabled="!vm.areaGeo1"
                        v-model="vm.areaGeo2" @change="getComuni">
                        <option v-for="(item, i) in province" :key="i" :value="item">
                            {{item}}
                        </option>
                    </select>
                    <button type="button" class="btn btn-clear" v-if="vm.areaGeo2"
                        @click="() => {vm.areaGeo2 = ''; vm.areaGeo3 = ''; }">
                        <img src="@/img/Close_header.svg" />
                    </button>
                </div>
            </div>
            <div class="form-group">
                <label>Comune</label>
                <div class="" :class="{'d-flex': vm.areaGeo3}">
                    <select class="form-control" :class="{ disabled: !vm.areaGeo2}" :disabled="!vm.areaGeo2"
                        v-model="vm.areaGeo3">
                        <option v-for="(item, i) in comuni" :key="i" :value="item">
                            {{item}}
                        </option>
                    </select>
                    <button type="button" class="btn btn-clear" v-if="vm.areaGeo3" @click="vm.areaGeo3 = ''">
                        <img src="@/img/Close_header.svg" />
                    </button>
                </div>
            </div>
            <call-to-button @click="closeModal">
                {{$store.state.dizionario.rankingFilter_filterConfirmButton}}
            </call-to-button>
            <!-- <div class="text-center">
                <button type="button" class="btn btn-dark" @click="closeModal">
                    {{$store.state.dizionario.rankingFilter_filterConfirmButton}}
                </button>
            </div> -->
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import CallToButton from '@/components/callToButton.vue';
import { GlobalConfigClient } from '@/services/Services';

@Component({
    components: {
        CallToButton
    }
})
export default class RankingFilterModal extends Vue {

    @Prop({
        type: [Object, VM.RankingFilterVm]
    }) vm: VM.RankingFilterVm;

    rollbackVm: VM.RankingFilterVm = null;

    infoConfig: OM.QuestionarioInizialeConfig = null;
    
    regioni: string[] = [];
    province: string[] = [];
    comuni: string[] = [];

    created(){
        this.rollbackVm = JSON.parse(JSON.stringify(this.vm));
        let proms = [];
        proms.push(GlobalConfigClient.getUserInfoConfig());
        proms.push(GlobalConfigClient.getRegioni());
        if(this.vm.areaGeo1)
            proms.push(GlobalConfigClient.getProvince(this.vm.areaGeo1));
        if(this.vm.areaGeo2)
            proms.push(GlobalConfigClient.getComuni(this.vm.areaGeo2));

        Promise.all(proms)
        .then(xs => {
            this.infoConfig = xs[0];
            this.regioni = xs[1];
            this.province = xs[2];
            this.comuni = xs[3];
        })
    }
    
    getProvince(){
        this.vm.areaGeo2 = '';
        this.vm.areaGeo3 = '';
        GlobalConfigClient.getProvince(this.vm.areaGeo1)
        .then(x => {
            this.province = x;
        })
    }
    
    getComuni(){
        this.vm.areaGeo3 = '';
        GlobalConfigClient.getComuni(this.vm.areaGeo2)
        .then(x => {
            this.comuni = x;
        })
    }

    rollback(){
        this.vm.periodo = this.rollbackVm.periodo;
        this.vm.fasciaDiEta = this.rollbackVm.fasciaDiEta;
        this.vm.statoLavorativo = this.rollbackVm.statoLavorativo;
        this.vm.conoscenzaFinanziaria = this.rollbackVm.conoscenzaFinanziaria;
        this.vm.areaGeo1 = this.rollbackVm.areaGeo1;
        this.vm.areaGeo2 = this.rollbackVm.areaGeo2;
        this.vm.areaGeo3 = this.rollbackVm.areaGeo3;
        this.$emit('close');
    }

    closeModal(){
        this.$emit('close');
    }
}
</script>
<style scoped>

    .header {
        position: fixed;
    }

    .modalWrapper {
        background: rgb(18, 24, 29);
        height: 100%;
        /* overflow: scroll; */
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .modalBody {
        padding-top: 50px!important;
        padding-bottom: 80px!important;
    }
    
    .form-group label {
        display: block;
        color: #fafafcb3;
        color: rgb(246, 156, 20);
    }
    
</style>
