<template>
    <div class="modalWrapper">
        <div class="header">
            <div class="headerLeft"></div>
            <div class="headerCenter">
                <p class="title text-center">
                    Capital<span class="subtitle">EYES</span>
                    <br>
                </p>
            </div>
            <div class="headerRight pr-3" @click="closeModal">
                <img src="@/img/Close_header.svg" />
            </div>
        </div>

        <div class="form-group d-flex justify-content-center flex-column">
            <label class="text-white mt-3">{{$store.state.dizionario.register_reset_password_insert_email}}</label>
            <input type="text" class="form-control w-75 mx-auto" v-model="email" />
            <button type="button" class="btn forgot-password-button mx-auto" @click="sendResetPassword">Invia</button>

            <label class="text-red w-100 pt-2" v-if="pageState == 'error'">
                {{$store.state.dizionario.register_error}}
            </label>
            <label class="text-low-white w-100 pt-2" v-if="pageState == 'ok'">
                {{$store.state.dizionario.register_resetPassword_email_sent}}
            </label>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { AuthServices } from '../services/AuthServices';

@Component
export default class ForgotPasswordModal extends Vue {

    email: string = "";
    pageState: string = '';
    
    closeModal(){
        this.$emit('close');
    }

    sendResetPassword(){
        this.pageState = '';
        AuthServices.forgotPassword(this.email).then( () => {
            this.pageState = 'ok';
        }).catch( () => {
            this.pageState = 'error';
        })
    }
}
</script>
<style scoped>
.header{
    width: 100%;
    position: absolute;
    top: 0px;
}

.modalWrapper {
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 53px;
    background-color: #11171d;
}
</style>
