<template>
    <div class="chatRoot">
        <div class="subHeader">
            <transition name="fade">
                <span v-if="fetchingData" class="pull-right" style="position: absolute; right: 10px; top: 10px;">
                    <i class="fas fa-spinner fa-spin"></i>
                </span>
            </transition>
        </div>
        <div class="chatMessageContainer" :style="messageContainerStyle">
            <chat-message v-for="(item, i) in messages" :key="i" @longtouch="onLongtouch"
                :ref="item.identifier" :message="item">
            </chat-message>
        </div>
        <transition name="fade">
            <div class="newChatMessages" v-if="showNuoviMessaggi" @click="goToBottom">
                <span><i class="fa fa-arrow-down"></i> nuovi messaggi <i class="fa fa-arrow-down"></i></span>
            </div>
        </transition>
        <div class="chatWriter">
            <div class="quotedMessage" v-if="quotedMessage">
                <button class="cancel" type="button" @click="cancelQuote">X</button>
                <p class="sender">{{quotedMessage.sender}}</p>
                <span class="ellipsis" v-html="quotedMessage.text"></span>   
            </div>
            <div class="chatInput">
                <textarea class="form-control" @input="ev => messageText = ev.target.value" :value.prop="messageText" rows="3"
                    @keypress.enter="addReturn" :class="{ disabled: sending }"
                    placeholder="Scrivi..."></textarea>
            </div>
            <div class="chatSend">
                <button type="button" class="send" :class="{'disabled': !messageText.trim() || chatRoom.disabled }" @click="sendMessage">
                    <img src="@/img/send-button.png" style="width: 100%;" />
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import linkify from 'linkifyjs/html';
import ChatMessage from '@/components/chatMessage.vue';
import { StorageServices } from '@/services/StorageServices';
import { ChatClient } from '@/services/Services';

@Component({
    components: {
        ChatMessage
    }
})
export default class Chat extends Vue {

    @Prop() chatIdentifier: string;

    chatRoom: VM.ChatRoomVm = new VM.ChatRoomVm();
    messages: OM.ChatMessage[] = [];
    quotedMessage: OM.QuotedMessage = null;
    userId: string = store.state.loginResponse.userIdentifier;
    messageText: string = "";
    showNuoviMessaggi: boolean = false;
    messageContainer: Element;
    scrollListenerOn: boolean;    
    isTicket: boolean = false;
    fetchingData: boolean = false;
    doneFetching: boolean;
    take: number = 20;
    sending: boolean = false;

    isIos = false;

    pollingInterval: any;
    created(){
        this.isIos = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        store.state.counters.chat = 0;
    }

    messageContainerStyle: string = "";
    calcMessageContainerStyle(){
        let ris = '';
        if(this.isIos)
            ris += '-webkit-overflow-scrolling: touch;';
        if(this.quotedMessage){
            let quotedContainer = document.querySelector('.chatWriter .quotedMessage');
            let height = getComputedStyle(quotedContainer).height;
            let toSubtract = 156 + parseFloat(height.substring(0, height.length - 2));
            ris += 'height: calc(100vh - ' + toSubtract + 'px);';
        }
        this.messageContainerStyle = ris;
        if(ris && this.isAtBottom())
            setTimeout(() => this.goToBottom(), 10);
    }
    mounted(){
        this.messageContainer = <Element>document.querySelector('.chatMessageContainer');
        ChatClient.getChatMessages(this.chatIdentifier, this.messages.length, this.take, 1)
        .then(msgs => {
            this.messages = msgs;
            StorageServices.setLastChatTimestamp(this.messages[this.messages.length - 1].timestamp);
            this.$nextTick( () => {
                this.messageContainer.scrollTop = this.messageContainer.scrollHeight;
                this.messageContainer.addEventListener('scroll', this.onTopScroll);
                this.calcMessageContainerStyle();
            });
            this.pollingInterval = setInterval(() => {
                ChatClient.getMessagesFrom(this.chatIdentifier, this.messages[this.messages.length - 1].timestamp)
                .then(x => {
                    if(x.length > 0){
                        this.messages.push(...x);
                        if(this.isAtBottom()){
                            setTimeout(() => {
                                this.goToBottom();
                            }, 10);
                        } else {
                            //nuovi messaggi
                            this.showNuoviMessaggi = true;
                            this.scrollListenerOn = true;
                            this.messageContainer.addEventListener('scroll', this.scrollListener);
                        }
                        StorageServices.setLastChatTimestamp(x[x.length-1].timestamp);
                    }
                });
            }, 5000);
        })
    }

    onLongtouch(val: OM.ChatMessage){
        this.quotedMessage = {
            sender: val.senderName,
            text: val.text,
        };
        this.$nextTick(() => {
            this.calcMessageContainerStyle();
        });
    }

    cancelQuote(){
        this.quotedMessage = null;
        this.calcMessageContainerStyle();
    }

    addReturn(){
        this.messageText += `
        `;
    }

    onTopScroll(){
        if(!this.isAtTop())
            return;
        if(this.fetchingData || this.doneFetching)
            return;
        this.fetchingData = true;
        ChatClient.getChatMessages(this.chatIdentifier, this.messages.length, this.take, 1)
        .then(msgs => {
            this.fetchingData = false;
            let scrollBottom = this.messageContainer.scrollHeight - this.messageContainer.scrollTop;
            this.messages.unshift(...msgs);
            this.$nextTick(() => {
                this.messageContainer.scrollTop = this.messageContainer.scrollHeight - scrollBottom;
            });
            if(msgs.length == 0){
                this.doneFetching = true;
                this.messageContainer.removeEventListener('scroll', this.onTopScroll);
            }
        })
    }
    
    destroyed(){
        clearInterval(this.pollingInterval);
        this.messageContainer.removeEventListener('scroll', this.scrollListener);
        this.messageContainer.removeEventListener('scroll', this.onTopScroll);
    }
    
    isAtTop(){
        return this.messageContainer.scrollTop == 0;
    }

    isAtBottom(){
        let a = Math.abs(Math.floor(this.messageContainer.clientHeight - this.messageContainer.scrollHeight));
        let b = Math.floor(this.messageContainer.scrollTop + 10);
        return a <= b;
    }
    scrollListener(){
        if(this.isAtBottom()){
            this.showNuoviMessaggi = false;
            this.scrollListenerOn = false;
            this.messageContainer.removeEventListener('scroll', this.scrollListener);
        }
    }

    sendMessage(){
        if(!this.messageText.trim()) {
            return;
        }
        let parsedText = linkify(this.messageText);
        let newMessage: VM.SendMessageVm;
        newMessage = {
            chatRoomIdentifier: this.chatIdentifier,
            text: parsedText,
            senderName: store.state.loginResponse.nomeUtente,
            senderIdentifier: store.state.loginResponse.userIdentifier,
            quotedMessage: this.quotedMessage
        }
        this.sending = true;
        ChatClient.sendMessage(newMessage)
        .then(x => {
            this.messages.push(x);
            this.messageText = "";
            this.quotedMessage = null;
            this.calcMessageContainerStyle();
            this.$nextTick(() => {
                this.goToBottom();
            });
            StorageServices.setLastChatTimestamp(x.timestamp);
        })
        .finally(() => {
            this.sending = false;
        });
    }

    goToBottom(){
        this.messageContainer.scrollTop = this.messageContainer.scrollHeight;
    }
}
</script>

<style scoped>

.chatRoot {
    position: relative;
    height: calc(100vh - 55px);
    background-color: #e8e8e8;
}
.chatWriter {
    position: absolute;
    width: 100%;
    padding: 10px;
    display: flex;
    bottom: 0px;
    background-color: #e8e8e8;
    border-top: 1px solid black;
}
.chatWriter .chatInput {
    display: flex;
    width: 100%;
}
.chatWriter .chatSend {
    display: flex;
    align-items: center;
}
.chatWriter .send {
    margin-left: 15px;
    border-radius: 50%;
    border: none;
    width: 50px;
    height: 40px;
    background: none;
}
.chatWriter .send.disabled {
    opacity: 0.4;
}
.chatWriter .send:active, .chatWriter .send:focus {
    border: none;
    outline: none;
}
.chatInput textarea {
    border: none;
    resize: none;
    max-height: 100px;
}
.chatMessageContainer {
    overflow-y: scroll;
    height: calc(100vh - 155px);
    background-color: #e8e8e8;
    padding-bottom: 10px;
}

.newChatMessages {
    position: fixed;
    bottom: 130px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    background-color: #10171d;
    color: white;
    height: 30px;
    line-height: 30px;
    border-radius: 50px;
    width: 50%;
}


.quotedMessage {
    border-left: 2px solid #efc48d;
    padding: 0 12px 2px;
    background: #f4f4f4;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 60px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    position: absolute;
    bottom: calc(100% - 10px);
    width: 77%;
}
.quotedMessage .sender {
    margin: 0;
    margin-top: 2px;
    font-size: 12px;
    padding-left: 2px;
    color: #e17e00;
    display: block!important;
}
.quotedMessage .cancel {
    position: absolute;
    right: -8px;
    top: -8px;
    background: transparent;
    border: none;
    height: 30px;
    width: 30px;
    padding: 10px 10px 0 0;
}
</style>
<style>
    .quotedMessage a.linkified {
        pointer-events: none;
    }
</style>