<template>
    <div class="pb-4">
        <wallet-bar class="fixedWalletBar" :refresh="false" :for-switch="true"
            :sum-to-liquidity="sumToLiquidity" ></wallet-bar>
        <!-- <wallet-bar class="fixedWalletBar" 
            :wallet-risk="walletRisk" 
            :sum-to-liquidity="-totaleAcquisto" 
            :liquidity-percent="liquidityPercentage">
        </wallet-bar> -->
        <!-- <wallet-bar class="fixedWalletBar" :refresh="false"></wallet-bar> -->
        <div class="walletBarPadding">
            <transition name="fastFade" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Watch } from 'vue-property-decorator';
import Component from '@/routeComponent'
import { StorageServices } from '@/services/StorageServices'
import * as VM from '@/viewModel';
import WalletBar from '@/components/walletBar.vue';
import CallToButton from '@/components/callToButton.vue';
import store from '@/store';
import { stdDev } from '@/utility'

@Component({
    components: {
        WalletBar,
        CallToButton
    }
})
export default class SwitchAcquisto extends Vue {

    walletRisk: number = 0;

    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        store.state.headerText =`<small class="text-orange small">` + 
            challenge.titolo + `</small><p class="mb-0">Switch - Acquisto</p>`;

        next((vc: SwitchAcquisto) => {

        });
    }

    get sumToLiquidity(){
        return - store.state.portafoglioStatus.liquidity + this.totaleOrdineSwitch;
    }

    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        next();
    }

    get totaleOrdineSwitch(){
        if(!store.state.creaSwitchVm)
            return 0;

        let vendite = store.state.creaSwitchVm.ordiniVendita.reduce((p, x) => p += x.controvalore, 0);
        let acquisti = store.state.creaSwitchVm.ordiniAcquisto.reduce((p, x) => p += x.controvalore, 0);
        return vendite - acquisti;
    }

    get liquidityPercentage(){
        if(!this.totaleOrdineSwitch || !store.state.portafoglioStatus.liquidity || !store.state.portafoglioStatus.controvalorePortafoglio)
            return 0;
            
        return (this.totaleOrdineSwitch + store.state.portafoglioStatus.liquidity) / store.state.portafoglioStatus.controvalorePortafoglio;
    }
    
    // calcolaWalletRisk(variazioniPesate){
    //     this.walletRisk = stdDev(variazioniPesate) * Math.sqrt(250);
    // }
    
}

</script>

<style scoped>

</style>
