<template>
    <div class="accordion" :class="openThis ? 'open' : ''">
        <div class="titleWrapper" @click="toggleOpen">
            <slot name="title"></slot>
        </div>
        <transition name="fade">
            <div class="accordionBody" :class="{'indent': indent, 'zeroHeight': !openThis}" v-show="openThis">
                <slot name="content"></slot>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import router from '@/router';
import * as OM from '@/model';

@Component
export default class Accordion extends Vue {
    @Prop() open: boolean;
    @Prop({
        default: false
    }) indent: boolean;

    @Prop() active: boolean;

    openThis: boolean = false;
    created(){
        if(this.open){
            this.openThis = true;
        }
    }

    toggleOpen(){
        this.openThis = !this.openThis;
        this.$emit('toggleOpen', this.openThis);
    }
}

</script>
<style scoped>

    .accordion .titleWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 0 5px 0;
    }

    .accordion .titleWrapper i {
        transition: color 0.2s;
    }

    .accordion .titleWrapper i.active {
        color: rgb(34, 212, 52);
    }

    .accordion .accordionButton {
        position: absolute;
        left: 5px;
        top: 0px;
    }

    .accordion .accordionButton button {
        background: none;
        border: none;
        width: 33px;
        height: 33px;
    }

    .accordion .accordionBody {
        overflow: hidden;
    }

    .accordion .accordionBody.indent {
        padding-left: 20px;
    }


</style>
