<template>
    <div class="modalWrapper">
        <div class="modalHeader">
            <i class="fas fa-question fa-3x"></i>
        </div>
        <div class="modalBody mt-3">
            <div>{{text}}</div>
            <div class="text-center mt-2">
                <input type="text" class="form-control" v-model="userInput" />
            </div>
        </div>
        <div class="modalFooter mt-4">
            <button type="button" class="btn btn-dark btn-large" @click="doCb">
                Ok
            </button>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices'

@Component
export default class AlertModal extends Vue {
    
    @Prop({
        required: true
    }) text: string;
    
    @Prop({
        required: true
    }) callback: (userInput: string) => void;

    userInput: string = "";
    doCb(){
        this.callback(this.userInput);
        this.$emit('close');
    }
    
}
</script>
<style scoped>

.modalWrapper {
    height: 100%;
    display: grid;
    grid-template-rows: auto auto auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    background-color: #11171d;
}

.modalHeader {
    grid-row: 1/2;
}

.modalBody {
    grid-row: 2/3;
}

.modalFooter {
    grid-row: 3/4;
}

</style>
