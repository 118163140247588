<template>
    <div class="temaWrapper">
        <div class="temaContainer" 
        :style="'background-color:' + cinturaColor + '; background-image: url(img/' + hexToCintura(cinturaColor) + '_' + icona + '.jpg)'">
            <div class="percentage">
                <!-- <animated-number :value="completato" :decimals="0" v-if="animate" :start-from="completato">
                    <span slot-scope="animated">{{animated.value}}<small>%</small></span>
                </animated-number>
                <template v-else>
                    {{completato | int}}<small>%</small>
                </template> -->
                <animated-number :value="localCompleted" :decimals="0" :speed="2000" :start-from="localCompleted">
                    <span slot-scope="animated">{{animated.value}}%</span>
                </animated-number>
            </div>
            <div class="lezioni">
                <animated-number v-if="lezioni > 0" :value="lezioni" :decimals="0" :speed="200" :start-from="0">
                    <span slot-scope="animated">{{animated.value}} lezioni</span>
                </animated-number>
            </div>
        </div>
        <p class="text-orange mb-0" v-if="index">
            Lesson {{index}}
        </p>
        <div class="temaTitle" v-if="nome">
            {{nome}}
        </div>
    </div>
</template>
<script lang="ts">
import { Vue, Prop, Watch } from 'vue-property-decorator';
import Component from '@/routeComponent';
import * as VM from '@/viewModel';
import AnimatedNumber from '@/components/animatedNumber.vue'

@Component({
    components: {
        AnimatedNumber
    }
})
export default class TemaCintura extends Vue {

    @Prop() cinturaColor: string;
    @Prop() nome: string;
    @Prop() icona: string;
    @Prop() index: number;
    @Prop() enabled: boolean;
    @Prop() completato: number;
    @Prop() lezioni: number;

    @Prop({
        required: false,
        default: false,
        type: Boolean
    }) animate: boolean;
    
    @Watch('completato')
    onCompletedChange(next, prev){
        this.localCompleted = next;
    }

    localCompleted: number = 0;
    created(){
        if(this.animate){
            this.localCompleted = 0;
            setTimeout(() => {
                this.localCompleted = this.completato || 0;
            }, 0)
        } else {
            this.localCompleted = this.completato || 0;
        }
    }

    hexToCintura(hex: string){
        if(!hex)
            return;
        switch(hex.toLowerCase()){
            case "#F0F8FE".toLowerCase():
                return "Bianca";
            case "#FFD700".toLowerCase():
                return "Gialla";
            case "#FF8200".toLowerCase():
                return "Arancione";
            case "#31B700".toLowerCase():
                return "Verde";
            case "#0085CA".toLowerCase():
                return "Blu";
            case "#7C4D3A".toLowerCase():
                return "Marrone";
            case "#15001F".toLowerCase():
                return "Nera";
        }
    }
}

</script>
<style scoped>

    .temaWrapper {
        display: inline-grid;
        width: 105px;
        margin: 0 8px 0 0;
        overflow: hidden;
    }

    .temaContainer {
        height: 100px;
        width: 100px;
        border-radius: 6px;
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .temaContainer:last-child {
        margin-right: 20px;
    }


    .temaTitle {
        margin-top: 2px;
        color: #fff;
        letter-spacing: 0.8px;
        white-space: normal;
    }

    .lockWrapper {
        position: absolute;
        left: 10px;
        bottom: 10px;
    }

    .lockWrapper i {
        color: rgba(255,255,255,0.5);
        font-size: 1.5em;
    }

    .temaProgressContainer {
        position: absolute;
        bottom: 60px;
        width: 90%;
        margin: 0 auto;
        left: 0;
        right: 0;
    }

    .lezioni {
        font-size: 20px;
        color: white;
        position: absolute;
        bottom: 7px;
        left: 7px;
        text-shadow: 0px 0px 5px rgb(0 0 0);
        font-weight: bold;
    }

    .percentage {
        font-size: 35px;
        color: white;
        position: absolute;
        bottom: 0px;
        right: 4px;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
    }

    .percentage small {
        font-size: 20px;
        vertical-align: super;
    }
</style>

