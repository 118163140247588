import Vue, { VNodeDirective, VNodeData } from 'vue';

Vue.directive('glossario', {
    bind: function (el: HTMLElement, binding: VNodeDirective) {
        let toBind = bindGlossario(binding.value); 
        el.innerHTML = toBind;
    }
})

function bindGlossario(input: string){
    let currentString = input;
    let currentPosition = 0;
    let ris = "";
    let leftBracketPosition = input.indexOf("[");
    let rightBracketPosition = input.indexOf("]");
    while(leftBracketPosition > -1 && rightBracketPosition > -1){
        ris += currentString.substring(currentPosition, leftBracketPosition);
        let parola = currentString.substring(leftBracketPosition + 1, rightBracketPosition);
        let link = parola;
        if(parola.indexOf('|') > -1){
            let splitted = parola.split('|');
            link = splitted[0];
            parola = splitted[1];
        }
        let href = '<a href="#/glossario/' + link + '">' + parola + '</a>';
        ris += href;
        currentString = currentString.substring(rightBracketPosition + 1);
        leftBracketPosition = currentString.indexOf("[");
        rightBracketPosition = currentString.indexOf("]");
    }
    ris += currentString.substring(rightBracketPosition + 1);

    return ris;
}

Vue.directive('color', {
    bind: function (el: HTMLElement, binding: VNodeDirective) {
        el.classList.remove('text-green');
        el.classList.remove('text-red');
        if(binding.value > 0)
            el.classList.add('text-green');
        else if(binding.value < 0)
            el.classList.add('text-red');
    },
    update: function (el: HTMLElement, binding: VNodeDirective) {
        el.classList.remove('text-green');
        el.classList.remove('text-red');
        if(binding.value > 0)
            el.classList.add('text-green');
        else if(binding.value < 0)
            el.classList.add('text-red');
    }
})

import { removeDiacritics } from '@/utility';
Vue.directive('typeclass', {
    bind: function (el: HTMLElement, binding: VNodeDirective) {
        let className = removeDiacritics(binding.value).toLowerCase().replace(/ /g,"-");
        el.classList.add(className);
    },
    update: function (el: HTMLElement, binding: VNodeDirective) {
        let className = removeDiacritics(binding.value).toLowerCase().replace(/ /g,"-");
        el.classList.add(className);
    }
})