<template>
    <div class="macroClassesContainer">
        <div class="mx-2 my-2" v-for="(cl, i) in classes" :key="i" @click="select(cl)">
            <icon-title-text-card :label="filterVm.assetType" :text="cl"></icon-title-text-card>
        </div>
    </div>
</template>

<script lang="ts">
import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import WalletBar from '@/components/walletBar.vue';
import IconTitleTextCard from '@/components/iconTitleTextCard.vue';
import { StorageServices } from '@/services/StorageServices'
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { AssetClient } from '@/services/Services';

@Component({
    components: {
        WalletBar,
        IconTitleTextCard,
    }
})
export default class MacroClassesView extends Vue {

    classes: string[] = [];
    filterVm: OM.AssetFilterVm = new OM.AssetFilterVm();
    
    beforeRouteEnter(to, from, next){
        let filterVm = new OM.AssetFilterVm();
        filterVm.assetType = to.params.assetType;
        filterVm.challengeIdentifier = to.params.challengeIdentifier;
        let challenge = StorageServices.getSelectedChallenge();
        store.state.headerText = `<small class="text-orange small">` + challenge.titolo + `</small><p class="mb-0">` + filterVm.assetType + `</p>`
        AssetClient.getAssetClasses(filterVm).then(x => {
            next((vc: MacroClassesView) => {
                vc.classes = x;
                vc.filterVm = filterVm;
            });
        })
    }

    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        next();
    }

    select(cl: string){
        this.$router.push('./' + this.filterVm.assetType + '/' + cl)
    }
}

</script>

<style scoped>

</style>