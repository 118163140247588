<template>
    <div class="modalWrapper text-center text-white">
        <p>Cancellazione Switch</p>
        <p style="font-size: 20px;">Sei sicuro di voler cancellare lo switch?</p>
        <div class="d-flex justify-content-around">
            <button type="button" class="btn btn-dark" @click="$emit('close')">
                No
            </button>
            <button type="button" class="btn btn-dark" @click="deleteSwitch">
                Si
            </button>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';
import { OrdineClient, PortafoglioClient } from '@/services/Services';

@Component
export default class DeleteOrderModal extends Vue {
    
    @Prop({
        type: [Array]
    }) ordini: VM.OrdinePendingVm[];
    
    deleteSwitch(){
        let vm: VM.DeleteMultipleVm = {
            orders: this.ordini
        }
        OrdineClient.deleteMultiple(vm)
        .then(x => {
            PortafoglioClient.getPortafoglioStatusWithSwitch({
                portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
                creaSwitchVm: null
            })
            .then(x => {
                store.state.portafoglioStatus = x;
                StorageServices.setPortafoglioStatus(x);
            });
            this.$emit('close');
        });
    }

}
</script>
<style scoped>

    .modalWrapper{
        background: rgb(18, 24, 29);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 20px;
    }

</style>

    