<template>
    <div>
        <div class="cintureWrapper hasFooter">
            <div class="mb-3"> <!--  v-for="(cintura, iCint) in cintureList" :key="iCint" :class="cintura.enabled ? '' : 'disabled'" -->
                <!-- <h5 class="cinturaTitle">{{cintura.nome}}</h5>
                <div class="progressBar">
                    <progress-bar :completed="cintura.progress" :color="cintura.hexColor"></progress-bar>
                </div> -->
                <div class="allTemiContainer">
                    <tema-cintura v-for="(tema, i) in temas" :key="i" :nome="tema.nome" 
                        :icona="tema.icona"
                        :lezioni="tema.lezioniCount"
                        :completato="tema.completamento" 
                        cintura-color="#F0F8FE" @click.native="navTo(tema)"></tema-cintura>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import TemaCintura from '@/components/temaCintura.vue';
import ProgressBar from '@/components/progressBar.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';
import { CinturaClient, TemaClient } from '@/services/Services';

@Component({
    components: {
        TemaCintura,
        ProgressBar
    }
})
export default class allTemi extends Vue {

    username: string = store.state.loginResponse.nomeUtente;
    
    temas: OM.TemaVm[] = [];

    beforeRouteEnter(to, from, next){
        TemaClient.getTemaVmListByPercorso(to.params.percorsoIdentifier)
        .then(x => {
            next((vc: allTemi) => {
                vc.temas = x;
            })
        })
    }

    navTo(tema: OM.Tema){
        // if(cintura.enabled){
            this.$router.push('/percorsi/' + this.$route.params.percorsoIdentifier + '/allTemi/' + tema.identifier);
        // }
    }

}

</script>

<style scoped>


.porcataBtn {
    position: fixed;
    top: 50px;
    right: 30px;
    border: 5px solid #fff;
    z-index: 100;
}
</style>
