<template>
    <div class="starsContainer">
        <span v-for="(star, index) in stars" :key="index" >
            <img src="@/img/Star_on_icon.svg" v-if="star <= activeProp" />
            <img src="@/img/Star_off_icon.svg" v-if="star > activeProp" />
        </span>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import router from '@/router';

@Component
export default class StarsRisk extends Vue {
    
    @Prop({
        default: 0
    }) activeProp: number;

    stars: Array<any> = [1, 2, 3, 4, 5, 6, 7];

}

</script>

<style scoped>
    .starsContainer {
        margin: 3px 0 8px;
    }
        .starsContainer span {
            margin-right: 8px;
        }
</style>