<template>
    <div>
        <div class="px-3 mt-3 mb-5">
            <donut-chart-card class="pb-4" :values="donutSerie" :types="donutTypes" :label="$store.state.dizionario.challenge_assets_donut_label" y-type="%"></donut-chart-card>
        </div>
        <div class="circleContainer">
            <div class="circleItem" v-for="(type, i) in types" :key="i">
                <img-card :label="type" @select="filterByType"></img-card>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import WalletBar from '@/components/walletBar.vue';
import AssetTypeSlugs from '@/components/assetTypeSlugs.vue';
import CircleCard from '@/components/circleCard.vue';
import ImgCard from '@/components/imgCard.vue';
import { StorageServices } from '@/services/StorageServices'
import * as OM from '@/model';
import * as VM from '@/viewModel';
import DonutChartCard from '@/components/donutChartCard.vue'
import { AssetClient, PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        WalletBar,
        AssetTypeSlugs,
        CircleCard,
        ImgCard,
        DonutChartCard,
    }
})
export default class AssetsView extends Vue {

    types: string[] = []
    classes: string[] = []
    filterVm: OM.AssetFilterVm = new OM.AssetFilterVm();
    donutSerie: number[] = [];
    donutTypes: VM.TypeForDonutChartVm[] = [];
    
    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        store.state.headerText = `<small class="text-orange small">` + challenge.titolo + `</small><p class="mb-0">Main Asset Class</p>`
        let filterVm = new OM.AssetFilterVm();
        filterVm.challengeIdentifier = to.params.challengeIdentifier;
        let proms = [];
        proms.push(AssetClient.getAssetClasses(filterVm));
        filterVm.portafoglioIdentifier = to.params.portafoglioIdentifier;
        proms.push(PortafoglioClient.getDonutChartTypes(filterVm));
        Promise.all(proms).then(datas => {
            next((vc: AssetsView) => {
                // datas[0].unshift('All');
                vc.types = datas[0];
                filterVm.assetType = "All";
                vc.filterVm = filterVm;
                vc.filterVm.portafoglioIdentifier = "";
                vc.donutTypes = datas[1];
                vc.donutSerie = datas[1].map(x => x.peso);
                AssetClient.getAssetClasses(filterVm)
                .then(x => {
                    vc.classes = x;
                })
            });
        })
    }

    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        next();
    }

    filterByType(val){
        this.filterVm.assetType = val;
        this.$router.push('assets/' + this.filterVm.assetType);
    }

}

</script>

<style scoped>

</style>
