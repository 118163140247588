import * as OM from '@/model';
import * as VM from '@/viewModel';

class _StorageServices  {
    
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }
    
    getCreaSwitch() {
        return this.readFromLocalStorage<OM.CreaSwitchVm>('creaSwitchVm');
    }
    setCreaSwitch(creaSwitchVm: OM.CreaSwitchVm) {
        window.localStorage.setItem('creaSwitchVm', JSON.stringify(creaSwitchVm));
    }
    
    getProgressiUser() {
        return this.readFromLocalStorage<OM.ProgressiUser>('progressiUser');
    }
    setProgressiUser(progressi: OM.ProgressiUser) {
        window.localStorage.setItem('progressiUser', JSON.stringify(progressi));
    }
    
    getLoginCredentials() {
        return this.readFromLocalStorage<{ email: string, password: string }>('credentials');
    }
    setLoginCredentials(email: string, password: string) {
        window.localStorage.setItem('credentials', JSON.stringify({ email, password }));
    }

    getLoginResponse() {
        return this.readFromLocalStorage<OM.LoginResponse>('loginResponse');
    }
    setLoginResponse(response: OM.LoginResponse) {
        window.localStorage.setItem('loginResponse', JSON.stringify(response));
    }

    getCintureList() {
        return this.readFromLocalStorage<OM.CinturaWithTemiVm[]>('cintureList');
    }
    setCintureList(cintureList: OM.CinturaWithTemiVm[]) {
        window.localStorage.setItem('cintureList', JSON.stringify(cintureList));
    }
    
    getTemaVm() {
        return this.readFromLocalStorage<OM.TemaVm>('temaVm');
    }
    setTemaVm(temaVm: OM.TemaVm) {
        window.localStorage.setItem('temaVm', JSON.stringify(temaVm));
    }
    
    getSelectedCintura() {
        return this.readFromLocalStorage<OM.CinturaWithTemiVm>('selectedCintura');
    }
    setSelectedCintura(selectedCintura: OM.CinturaWithTemiVm) {
        window.localStorage.setItem('selectedCintura', JSON.stringify(selectedCintura));
    }

    getSelectedChallenge() {
        return this.readFromLocalStorage<VM.ChallengeVm>('selectedChallenge');
    }
    setSelectedChallenge(selectedChallenge: VM.ChallengeVm) {
        window.localStorage.setItem('selectedChallenge', JSON.stringify(selectedChallenge));
    }
    
    getSelectedFriendChallenge() {
        return this.readFromLocalStorage<any>('selectedFriendChallenge');
    }
    setSelectedFriendChallenge(selectedChallenge: any) {
        window.localStorage.setItem('selectedFriendChallenge', JSON.stringify(selectedChallenge));
    }

    getPortafoglioStatus(portafoglioIdentifier: string) {
        return this.readFromLocalStorage<OM.PortafoglioStatusVm>('portafoglioStatus' + portafoglioIdentifier);
    }
    setPortafoglioStatus(portafoglioStatus: OM.PortafoglioStatusVm) {
        window.localStorage.setItem('portafoglioStatus' + portafoglioStatus.portafoglioIdentifier, JSON.stringify(portafoglioStatus));
    }
    deletePortafoglioStatus(portafoglioIdentifier: string) {
        window.localStorage.removeItem('portafoglioStatus' + portafoglioIdentifier);
    }
    
    getBenchmarkVm() {
        return this.readFromLocalStorage<OM.BenchmarkVm>('benchmarkVm');
    }
    setBenchmarkVm(vm: OM.BenchmarkVm) {
        window.localStorage.setItem('benchmarkVm', JSON.stringify(vm));
    }

    getChartData() {
        return this.readFromLocalStorage<OM.DateValue[]>('horizontalChartData');
    }
    setChartData(chartData: OM.DateValue[]) {
        window.localStorage.setItem('horizontalChartData', JSON.stringify(chartData));
    }

    getHorizontalTableData() {
        return this.readFromLocalStorage<OM.DatiPeriodo[]>('horizontalTableData');
    }
    setHorizontalTableData(tableData: OM.DatiPeriodo[]) {
        window.localStorage.setItem('horizontalTableData', JSON.stringify(tableData));
    }
    
    getResocontoLiquidity() {
        return this.readFromLocalStorage<OM.KeyValuePairOfStringAndResocontoPortafoglio>('resocontoLiquidity');
    }
    setResocontoLiquidity(resocontoLiquidity: OM.KeyValuePairOfStringAndResocontoPortafoglio) {
        window.localStorage.setItem('resocontoLiquidity', JSON.stringify(resocontoLiquidity));
    }
    
    getResocontoWallet() {
        return this.readFromLocalStorage<OM.KeyValuePairOfStringAndResocontoPortafoglio>('resocontoWallet');
    }
    setResocontoWallet(resocontoWallet: OM.KeyValuePairOfStringAndResocontoPortafoglio) {
        window.localStorage.setItem('resocontoWallet', JSON.stringify(resocontoWallet));
    }

    getFallbackIcon(iconName) {
        return this.readFromLocalStorage<OM.KeyValuePairOfStringAndResocontoPortafoglio>('fallbackIcon' + iconName);
    }
    setFallbackIcon(iconName: string, value: string) {
        window.localStorage.setItem('fallbackIcon' + iconName, JSON.stringify(value));
    }
    
    getRisultatoQuestionario() {
        return this.readFromLocalStorage<OM.SaveQuestionarioResponseVm>('risultatoQuestionario');
    }
    setRisultatoQuestionario(risultatoQuestionario: OM.SaveQuestionarioResponseVm) {
        window.localStorage.setItem('risultatoQuestionario', JSON.stringify(risultatoQuestionario));
    }
    
    getSelectedLezione() {
        return this.readFromLocalStorage<OM.Lezione>('selectedLezione');
    }
    setSelectedLezione(selectedLezione: OM.Lezione) {
        window.localStorage.setItem('selectedLezione', JSON.stringify(selectedLezione));
    }

    getLastChatTimestamp() {
        return this.readFromLocalStorage<number>('lastChatTimestamp');
    }
    setLastChatTimestamp(timestamp: number) {
        window.localStorage.setItem('lastChatTimestamp', JSON.stringify(timestamp));
    }
}

export let StorageServices = new _StorageServices();