<template>
    <div>
        <transition name="fade" mode="out-in">
            <div class="notificationDrawer" v-if="$store.state.notification" @click="notificationDrawerClick">
                <div class="notificationBox">
                    <button type="button" class="notificationClose" @click.stop.prevent="$store.state.notification = null">
                        <img src="../../img/Close_header.svg">
                    </button>
                    <div class="text-orange">
                        {{$store.state.notification.title}}
                    </div>
                    <div class="notificationText">
                        {{$store.state.notification.message}}
                    </div>
                </div>
            </div>
        </transition>
        <div class="header">
            <div class="customContainer">
                <div class="headerLeft">
                    <header-button v-if="$store.state.headerButtons.first && !this.openMenu" 
                        :button="$store.state.headerButtons.first"></header-button>
                    <template v-else>
                        <div class="headerButton" v-if="showBack" @click="$router.back()">
                            <img src="@/img/back_header.svg" />
                        </div>
                        <div class="headerButton" @click="openGlossario" v-if="showGlossario">
                            <img src="@/img/Book_icon.svg" />
                        </div>
                    </template>
                    <header-button v-if="$store.state.headerButtons.second && !this.openMenu" 
                        :button="$store.state.headerButtons.second"></header-button>
                </div>
                <div class="headerCenter" :class="{ 'reduced': showGlossario || showHelp }">
                    <p class="title text-center" v-html="headerText">
                    </p>
                </div>
                <div class="headerRight">
                    <header-button v-if="$store.state.headerButtons.third && !this.openMenu" 
                        :button="$store.state.headerButtons.third"></header-button>
                    <template v-else>
                        <div class="headerButton" @click="openHelp" v-if="showHelp">
                            <i class="fa fa-question"></i>
                        </div>
                    </template>
                    
                    <header-button v-if="$store.state.headerButtons.fourth && !this.openMenu" 
                        :button="$store.state.headerButtons.fourth"></header-button>
                    <template v-else>
                        <div class="headerButton" @click="openMenu = !openMenu" v-if="!hideMenu">
                            <img src="@/img/menu_header.svg" v-if="!openMenu" />
                            <img src="@/img/Close_header.svg" v-else />
                        </div>
                    </template>
                </div>
            </div>
        </div>
        
        <transition name="fade" mode="out-in">
            <div class="appMenu" v-show="openMenu">
                
                <div class="customContainer">
                    <router-link class="menuLink" to="/profile/edit" @click.native="openMenu = false" style="height: unset;">
                        <div class="profileImgContainer">
                            <div class="profileImg" :style="$store.state.loginResponse.userImgUrl ? 'background-image: url(' + $store.state.loginResponse.userImgUrl + ')' : ''"></div>
                        </div>
                        <div class="profileName text-white mb-0">
                            {{$store.state.loginResponse.nomeUtente}}
                        </div>
                    </router-link>

                    <div class="firstRowMenuContainer">
                        <router-link class="menuLink" to="/percorsi" @click.native="openMenu = false">
                            <div class="menuImgContainer learningContainer">
                                <div class="iconContainer">
                                    <img src="@/img/menu_learning.svg" />
                                    <span>{{$store.state.dizionario.menu_1}}</span>
                                </div>
                            </div>
                        </router-link>
                    </div>

                    <div class="secondRowMenuContainer">
                        <router-link class="menuLink" to="/archivio/0" @click.native="openMenu = false; $store.state.counters.archivio = false">
                            <div class="menuImgContainer">
                                <img src="@/img/archivio_icon.png" />
                                <div class="counter big" v-if="$store.state.counters.archivio">
                                </div>
                                <span>{{$store.state.dizionario.menu_5}}</span>  
                            </div>
                        </router-link>
                        <router-link class="menuLink" to="/challenges" @click.native="openMenu = false">
                            <div class="menuImgContainer">
                                <img src="@/img/menu_gaming.svg" />
                                <span>{{$store.state.dizionario.menu_2}}</span>
                            </div>
                        </router-link>
                    </div>


                    <div class="d-flex">
                        <router-link class="menuLink" to="/premi" @click.native="openMenu = false">
                            <div class="menuImgContainer smaller">
                                <img src="@/img/coppa.svg" />
                                <span>{{$store.state.dizionario.menu_3}}</span>
                            </div>
                        </router-link>
                        <router-link class="menuLink" to="/chatRoom" @click.native="openMenu = false">
                            <div class="menuImgContainer smaller">
                                <img src="@/img/chat.svg" />
                                <div class="counter" v-if="$store.state.counters.chat">
                                    {{$store.state.counters.chat}}
                                </div>
                                <span>{{$store.state.dizionario.menu_4}}</span>
                            </div>
                        </router-link>
                        <router-link class="menuLink" to="/profile/edit" @click.native="openMenu = false">
                            <div class="menuImgContainer smaller">
                                <img src="@/img/menu_setup.svg" />
                                <span>{{$store.state.dizionario.menu_6}}</span>
                            </div>
                        </router-link>
                    </div>

                    <div class="menuFooter">
                        <a @click="openBrowser('privacy')">{{$store.state.dizionario.privacyPolicyLabel}}</a>
                        <a @click="openBrowser('terms')">{{$store.state.dizionario.termsAndConditionsLabel}}</a>
                    </div>

                </div>
            </div>
        </transition>

        <!-- <transition name="fastFade" mode="out-in"> -->
            <div ref="layoutContainer" class="layoutContainer">
                <router-view :key="layoutKey"></router-view> 
            </div>
        <!-- </transition> -->

        <svg width="100" height="50" version="1.1" xmlns="http://www.w3.org/2000/svg" style="position: fixed;">
            <defs>
                <linearGradient id="areaGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="5%" stop-color="#11171d" />
                <stop offset="95%" stop-color="#ffa200" />
                </linearGradient>
            </defs>
        </svg>
    </div>
</template>

<script lang="ts">
import router from '@/router';
import { Component, Vue, Watch } from 'vue-property-decorator';
import store from '@/store';
import { ModalServices } from '../../services/ModalServices';
import HelperModal from '@/components/helperModal.vue';
import HeaderButton from './headerButton.vue';
import { StorageServices } from '../../services/StorageServices';
import { privacyPolicyUrl, termsAndConditionUrl } from '@/config'
import { ArchivioClient, AssetClient, ChatClient, GlobalConfigClient } from '@/services/Services';

@Component({
    components: {
        HeaderButton
    }
})
export default class LayoutView extends Vue {

    openMenu: boolean = true;
    layoutContainer: any = null;

    bodyScroll: number = 0;
    @Watch('openMenu')
    onOpenMenu(next, prev){
        if(this.openMenu) {
            this.bodyScroll = document.body.scrollTop;
            setTimeout(() => {
                this.layoutContainer.style="overflow-y: hidden;position: relative; height: 100vh;";
            }, 300);
        } else {
            this.layoutContainer.style="";
            setTimeout(() => {
                document.body.scrollTop = this.bodyScroll;
            }, 10)
        }
    }

    get showBack(){ 
        return this.$route.meta.backButton && !this.openMenu;
    }
    get showGlossario(){
        return this.$route.path.indexOf('cinture') > -1 && !this.openMenu;
    }
    get showFilterButton(){
        return this.$route.meta.filterButton && !this.openMenu;
    }
    get headerText() {
        return store.state.headerText || `Capital<span class="subtitle">EYES</span>`
    }
    get layoutKey() {
        return this.$route.meta.pageKey || 'vueview';
    }
    get hideMenu() {
        return !!this.$route.meta.hideMenu;
    }
    
    openBrowser(what){
        if(what == 'privacy')
            window.open(privacyPolicyUrl, '_blank');
        else if(what == 'terms')
            window.open(termsAndConditionUrl, '_blank');
    }

    mounted(){
        this.layoutContainer = this.$refs.layoutContainer;
        this.layoutContainer.style="overflow-y: hidden;position: relative; height: 100vh;";

        router.afterEach((to, from) => {
            if(to.name == 'blank')
                this.openMenu = true;
        });

        AssetClient.getValuteValues().then(x => {
            store.state.valute.EURUSD = x['EURUSD'];
            store.state.valute.USD = x['EURUSD'];
            store.state.valute.EURGBP = x['EURGBP'];
            store.state.valute.GBP = x['EURGBP'];
            store.state.valute.EURCHF = x['EURCHF'];
            store.state.valute.CHF = x['EURCHF'];
            store.state.valute.EURJPY = x['EURJPY'];
            store.state.valute.JPY = x['EURJPY'];
            console.log(store.state.valute);
        })
        
        GlobalConfigClient.getDizionario()
        .then(x => {
            store.state.dizionario = x;
        });
        
        GlobalConfigClient.getSystemDate()
        .then(x => {
            store.state.systemDate = x;
        });

        GlobalConfigClient.getDizionarioHtml()
        .then(x => {
            store.state.dizionarioHtml = x;
        });

        ChatClient.checkNewMessages(store.state.loginResponse.userIdentifier, StorageServices.getLastChatTimestamp())
        .then(x => {
            store.state.counters.chat = x;
        })
        
        ArchivioClient.checkToRead(store.state.loginResponse.userIdentifier)
        .then(x => {
            store.state.counters.archivio = x;
        })
        
        let notificationCallback = (payload: any) => {

            console.log(payload);
            if(payload.additionalData.redirect == '/chatRoom'){
                if(this.$route.name == 'chatRoom')
                    store.state.notification = null;
                else
                    store.state.counters.chat++;
            }
            if(!payload.tap){// || !payload.additionalData.foreground){
                if(payload.additionalData.coldstart){
                    if(payload.additionalData.redirect){
                        this.openMenu = false;
                        this.$router.push(payload.additionalData.redirect);
                    }
                }
            } else {
                store.state.notification = payload;
                let route = '';
                if(payload.additionalData.redirect){
                    route = payload.additionalData.redirect;
                }
                store.state.notificationFunction = () => {
                    if(route){
                        this.openMenu = false;
                        this.$router.push(route);
                    }
                }
            }
        };
                        
        document.addEventListener("deviceready", () => {
            // PushServices.Register(notificationCallback);
        }, false);
    }

    notificationDrawerClick(){
        store.state.notification = null;
        store.state.notificationFunction();
    }

    openGlossario(){
        if(!this.showGlossario)
            return;
        
        this.$router.push('/glossario')
    }

    get showHelp(){
        return !!store.state.dizionarioHtml[this.$route.meta.tutorial] && !this.openMenu;
    }

    openHelp(){
        if(!this.showHelp)
            return;

        ModalServices.OpenModal(HelperModal,
        {
            dictionaryKey: this.$route.meta.tutorial
        });
    }
}

</script>

<style scoped>

.profileImgContainer {
    margin-bottom: 0;
}

</style>
