<template>
    <div class="iconTitleTextCard">
        <img :src="require('@/img/' + imgName + '.svg')" class="icon" />
        <div class="info">
            <label class="infoTitle">
                {{label}}
                <span v-if="label2 != ''">{{label2}}</span>
            </label>
            <h2>{{text}}</h2>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { removeDiacritics } from '@/utility';

@Component
export default class IconTitleTextCard extends Vue {

    @Prop() label: string;
    @Prop() label2: string;
    @Prop() text: string;

    get imgName(){
        let ris = removeDiacritics(this.label).toLowerCase();
        return ris.replace(/ /g,"_");
    }

}
</script>

<style scoped>

    .iconTitleTextCard {
        border-radius: 8px;
        border: 1px solid rgba(34, 44, 52, 0.5);
        background-color: rgba(34, 34, 44, 0.25);
        padding: 20px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
        .iconTitleTextCard .icon {
            height: 37px;
            margin-right: 10px;
        }
        .iconTitleTextCard .info .infoTitle {
            font-size: 12px;
            margin: 0;
        }
        .iconTitleTextCard .info h2 {
            font-family: 'Poppins-ExtraLight';
            font-size: 18px;
            margin: 0;
        }

</style>
