<template>
    <div class="modalWrapper">
        <div class="modalHeader header">
            <div class="headerLeft"></div>
            <div class="headerCenter">
                <p class="title text-center">
                    Capital<span class="subtitle">EYES</span>
                    <br>
                </p>
            </div>
            <div class="headerRight pr-3" @click="closeModal">
                <img src="@/img/Close_header.svg" />
            </div>
        </div>
        <div class="modalBody p-3 contentHtmlContainer" v-html="$store.state.dizionarioHtml[dictionaryKey]">
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class HelperModal extends Vue {
    @Prop() dictionaryKey: string;

    closeModal(){
        this.$emit('close');
    }
}
</script>
<style scoped>

    .header {
        position: relative;
    }

    .modalWrapper {
        background: rgb(18, 24, 29);
        height: 100%;
        /* overflow: scroll; */
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    
</style>
