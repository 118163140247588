import * as OM from '@/model';
import * as Enum from '@/enum';

export class UserProfileVm {
    name: string;
    surname: string;
    dateOfBirth: string;
    lastAccessDate: string;
    job: string;
    isMaschio: boolean;
    base64Image: string;
    eta: number;
    receiveNotification: boolean;
    identifier: string;
    constructor(){
        this.name = "";
        this.surname = "";
        this.dateOfBirth = "";
        this.lastAccessDate = "";
        this.job = "";
        this.isMaschio = false;
        this.base64Image = "";
        this.eta = 0;
        this.receiveNotification = true;
        this.identifier = "";
    }
}

export class CinturaWithTemiVm {
    nome: string;
    temi: TemaVm[];
    hexColor: string;
    identifier: string;
    enabled: boolean;
    progress: number;

    constructor(){
        this.progress = 0;    
    }
}

export class TemaVm {
    nome: string;
    identifier: string;
    completamento: number;
    icona: string;
}

export class LezioneGroupedVm
{
    identifier: string;
    cintura: string;
    hexColor: string;
    cinturaIdentifier: string;
    cinturaProgress: number;
    elements: LezioneVm[];
    enabled: boolean;
    isOpen?: boolean;
    constructor(){
        this.elements = [];
        this.cintura = "";
        this.hexColor = "";
        this.cinturaProgress = null;
        this.enabled = false;
        this.isOpen = false;
    }
}

export class LezioneVm
{
    identifier: string;
    titolo: string;
    superata: boolean;
    cinturaIdentifier: string;
}

export class ProgressoCinturaVm
{
    identifier: string;
    nome: string;
    hexColor: string;
    progress: number;
    constructor(){
        this.identifier = "";
        this.nome = "";
        this.hexColor = "";
        this.progress = null;
    }
}

export class ProfileVm
{
    identifier: string;
    name: string;
    surname: string;
    alias: string;
    punteggioLearning: number;
    punteggioGaming: number;
    info: OM.Info;
    premiUser: PremioUserVm[];
    constructor(){
        this.identifier = "";
        this.name = "";
        this.surname = "";
        this.alias = "";
        this.punteggioLearning = 0;
    }
}

export class RankingFilterVm
{
    challengeIdentifier: string;
    challengeType: string;
    periodo: string;
    fasciaDiEta: number;
    statoLavorativo: number;
    conoscenzaFinanziaria: number;
    areaGeo1: string;
    areaGeo2: string;
    areaGeo3: string;
    constructor(){
        this.challengeIdentifier = '';
        this.challengeType = 'global';
        this.periodo = '';
        this.fasciaDiEta = 0;
        this.statoLavorativo = 0;
        this.conoscenzaFinanziaria = 0;
        this.areaGeo1 = '';
        this.areaGeo2 = '';
        this.areaGeo3 = '';
    }
}

export class PremioUserVm
{
    tipoPremio: string;
    podioUno: number;
    podioDue: number;
    podioTre: number;
}

export class SaveQuestionarioResponseVm
{
    lezioneSuperata: boolean;
    cintura: string;
    tema: string;
    cinturaProgressFrom: number;
    cinturaProgressTo: number;
    temaProgressFrom: number;
    temaProgressTo: number;
    newCintura: boolean;
    nextCintura: string;
    nextCinturaColor: string;
    risposte: OM.RispostaAlQuestionario[];
}

export class RisultatoQuestionarioVm {
    userIdentifier : string;
    lezioneIdentifier : string;
    cinturaIdentifier : string;
    temaIdentifier : string;
    risposte : OM.RispostaAlQuestionario[];
    constructor() 
    {
        this.userIdentifier = null;
        this.lezioneIdentifier = null;
        this.cinturaIdentifier = null;
        this.temaIdentifier = null;
        this.risposte = [];
    }
}

export class ChallengeVm extends OM.ChallengeVm
{
    portafoglioIdentifier?: string;
    notified?: boolean;
}

export class CreateUserBalanceVm
{
    userIdentifier: string;
    challengeIdentifier: string;
    nomeGara: string;
    balance: number;
    fromDate: string;
}


export class BenchmarkVm
{
    identifier: string;
    nome: string;
    codiceBloomberg: string;
    datiPeriodo: OM.DatiPeriodo[];
    performance: OM.DateValue[];
    volatilita750: OM.DateValue[];
    drawdown: OM.Drawdown;
    performanceTable: AssetPerformanceVm[];
    constructor(){
        this.identifier = "";
        this.nome = "";
        this.codiceBloomberg = "";
        this.datiPeriodo = [];
        this.performance = [];
        this.volatilita750 = [];
        this.drawdown = new OM.Drawdown();
        this.performanceTable = [];
    }
}

export class AssetPerformanceVm
{
    anno: number;
    firstQ: number;
    secondQ: number;
    thirdQ: number;
    fourthQ: number;
    yearly: number;
}

export class AssetPerformanceByMonthVm
{
    year: number;
    monthValues: number[];
}

export class AssetForBuyListVm
{
    identifier: string;
    nome: string;
    valuta: string;
    nav: number;        
    performance: OM.DateValue[];
    tipoAsset: string;
    tipo: string;

    constructor(){
        this.performance = [];
    }
}

export class AssetForDonutChart
{
    assetIdentifier: string;
    nome: string;
    tipo: string;
    valuta: string;
    peso: number;
    controvalore: number;
    quantita: number;
    performanceYtd: number;
    performance1Year: number;
    performance3Year: number;
    volatilita750: number;
    selected?: boolean;
    selling?: number;
}

export class TypeForDonutChartVm
{
    nome: string;
    peso: number;
}

export class PortafoglioStatusVm {
    portafoglioIdentifier: string;
    controvalorePortafoglio: number;
    liquidity: number;
    liquidityPercentage: number;
    walletRisk: number;
    matchRisk: number;
    analysisEnabled: string;
    constructor(){
        this.portafoglioIdentifier = "";
        this.controvalorePortafoglio = 0;
        this.liquidity = 0;
        this.liquidityPercentage = 0;
        this.walletRisk = 0;
        this.matchRisk = 0;
        this.analysisEnabled = "";
    }
}

export class CreaOrdineVm
{
    userIdentifier: string;
    portafoglioIdentifier: string;
    tipoOrdine: Enum.TipoOrdine;
    assetIdentifier: string;
    assetName: string;
    assetType: string;
    controvalore: number;
    dataOrdine: string;
    percentage?: number;
}

export class CreaSwitchVm
{
    userIdentifier: string;
    walletIdentifier: string;
    ordiniVendita: CreaOrdineVm[];
    ordiniAcquisto: CreaOrdineVm[];
    constructor(){
        this.userIdentifier = "";
        this.walletIdentifier = "";
        this.ordiniVendita = [];
        this.ordiniAcquisto = [];
    }
}

export class DataForCalcoloAcquistoVm
{
    tipoAsset: string;
    lastNav: number;
    variazioniAsset: number[];
    variazioniPesate: number[];
    constructor(){
        this.variazioniAsset = [];
        this.variazioniPesate = [];
    }
}

export class RispostaQualitaVm
{
    identifier?: string;
    userIdentifier: string;
    userName: string;
    lezioneIdentifier: string;
    titoloLezione: string;
    nomeCintura: string;
    nomeTema: string;
    risposte: OM.DomandaWithRisposta[];
    commento: string;
    constructor(){
        this.risposte = [];
    }
}

export class OrdinePendingVm
{
    identifier: string;
    strumento: string;
    tipoOrdine: Enum.TipoOrdine;
    controvalore: number;
    peso: number;
    dataOrdine: string;
    dataMovimento: string;
    tipoAsset: string;
    selected?: boolean;
    deletable: boolean;
}

export class SwitchPendingVm
{
    deletable: boolean;
    orders: OrdinePendingVm[];
}


export class RegisterVm
{
    nome: string;
    cognome: string;
    email: string;
    password: string;
    appVersion: string;
}

export class ProviderTokenLoginVm
{
    access_token: string;
    password: string;
    appVersion: string;
}

export class LoginData
{
    email:string;
    password:string;

    constructor(){
        this.email = "";
        this.password = "";
    }
}
export class LoginDataWithVersion extends LoginData
{
    appVersion: string;
    constructor(){
        super();
        this.appVersion = "";
    }
}

export class RankingCardVm
{
    userIdentifier: string;
    isSelf: boolean;
    playerName: string;
    totalPoints: number;
    performance: number;
    flag: string;
    cintura: string;
    coloreCintura: string;
    imageUrl: string;
    vsPrevious: number;
    punteggioLearning: number;
    punteggioGaming: number;
    premiUser: PremioUserVm[];

    selected?: boolean;
    blocked?: boolean;
}

export class ChatRoomVm
{
    userIdentifier: string;
    userName: string;
    chatIdentifier: string;
    lastMessageTimeStamp: number;
}

export class SendMessageVm
{
    chatRoomIdentifier: string;
    text: string;
    senderIdentifier: string;
    senderName: string;
    quotedMessage: OM.QuotedMessage;
}

export class PollyTextVm
{
    text: string;
    cintura: string;
    tema: string;
    lezione: string;
}

export class ArchivioNodeVm
{
    parentIdentifier: string;
    isRead: boolean;
    identifier: string;
    isFolder: boolean;
    name: string;
    iconUrl: string;
}

export class CreateFriendChallengeVm
{
    name: string;
    challengeIdentifier: string;
    challengeName: string;
    invitedUsers: OM.ValueIdentifierOfString[];
    azzeraDati: boolean;
    constructor(){
        this.name = '';
        this.challengeIdentifier = '';
        this.challengeName = '';
        this.invitedUsers = [];
        this.azzeraDati = false;
    }
}

export class FriendChallengeVm
{
    identifier: string;
    name: string;
    dataInizio: string;
    userCount: number;
    creatorIdentifier: string;
    creatorName: string;
}

export class FriendChallengeInvitesVm
{
    name: string;
    challengeIdentifier: string;
    invitedUsers: OM.ValueIdentifierOfString;
    azzeraDati: boolean;
}

export class GetDataForCalcoloAcquistoVm
{
    portafoglioIdentifier: string;
    assetIdentifier: string;
    creaSwitchVm: CreaSwitchVm;
}

export class GetPortafoglioStatusWithSwitchVm
{
    portafoglioIdentifier: string;
    creaSwitchVm: CreaSwitchVm;
}

export class GetOrdiniPendingResponseVm
{
    switches: SwitchPendingVm[];
    ordini: OrdinePendingVm[];
}

export class DeleteMultipleVm
{
    orders: OrdinePendingVm[];
    constructor(){
        this.orders = [];
    }
}


export class HeaderButtonDefinition {
    imgUrl: string;
    onClick: (ev) => void;
    constructor(){
        this.imgUrl = '';
        this.onClick = (ev) => {};
    }
}