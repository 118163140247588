<template>
    <div class="headerButton" @click="button.onClick">
        <img :src="button.imgUrl" />
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Component
export default class HeaderButton extends Vue {
    @Prop() button: VM.HeaderButtonDefinition;
}

</script>

<style scoped>

</style>
