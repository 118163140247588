<template>
    <div>
        <div class="archivioNode">
            <div class="d-flex">
                <div class="nodeImg" :style="'background-image: url(' + node.iconUrl + ')'"></div> 
                <span class="relative">
                    {{node.name}}
                    <div class="pallino" v-if="!node.isRead"></div>
                </span>
            </div>
            <div class="chevronRight">
                <img src="@/img/chevron-right.png" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Component
export default class ArchivioNode extends Vue {

    @Prop({
        type: [VM.ArchivioNodeVm, Object]
    }) node: VM.ArchivioNodeVm;

    emit(eventType: string){
        this.$emit(eventType, this.node)
    }

}
</script>

<style scoped>

    .archivioNode {
        position: relative;
        margin: 10px;
        background-color: rgb(34, 44, 52);
        height: 80px;
        border-radius: 4px;
        color: white;
        padding: 20px 40px 20px 10px;
        line-height: 40px;
        font-size: 18px;
    }
    .archivioNode .nodeImg {
        height: 40px;
        width: 42px;
        margin-right: 6px;
        display: inline-block;
        background-position: center;
        background-size: cover;
    }

    .chevronRight {
        position: absolute;
        right: 10px;
        top: 20px;
    }

    .chevronRight img {
        height: 30px;
        width: 15px;
    }

    .pallino {
        position: absolute;
        background-color: rgb(255, 65, 20);
        width: 14px;
        height: 14px;
        border-radius: 50%;
        top: -2px;
        right: -14px;
    }
    
</style>
