import { render, staticRenderFns } from "./assets.vue?vue&type=template&id=786e52c0&scoped=true"
import script from "./assets.vue?vue&type=script&lang=ts"
export * from "./assets.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "786e52c0",
  null
  
)

export default component.exports