<template>
    <div>
        <wallet-bar class="fixedWalletBar" :refresh="false"></wallet-bar>
        <div class="walletBarPadding">
            <transition name="fastFade" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>
    </div>
</template>

<script lang="ts">
import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import WalletBar from '@/components/walletBar.vue'

@Component({
    components: {
        WalletBar
    }
})
export default class AssetsLayoutView extends Vue {
}

</script>
