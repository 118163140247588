<template>
    <img :src="require('@/img/' + imgName + '.svg')" class="icon" />
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { removeDiacritics } from '@/utility';

@Component
export default class AssetTypeIcon extends Vue {
    @Prop() label: string;

    get imgName(){
        let ris = removeDiacritics(this.label).toLowerCase();
        return ris.replace(/ /g,"_");
    }
}
</script>

<style scoped>

    img.icon {
        width: 30px;
        height: 30px;
    }

</style>
