<template>
    <div class="pb-4">
        <p class="text-center mt-3">Immetti gli ordini di vendita</p>
        <asset-type-slugs class="my-3" :slugs="types" @select="filterByType"></asset-type-slugs>
        <div class="assetList">
            <div class="assetItemBlock" v-for="(item, i) in assetsWithControvalore" :key="'a'+i" @click="openSellAssetModal(item)">
                <div class="assetItem p-3">
                    <!-- :style="{'background-image': 'url(' + item.image + ')'}" -->
                    <div class="imageContainer">
                        <asset-type-icon class="" :label="item.tipo"></asset-type-icon>
                    </div>
                    <div class="info">
                        <h2>{{item.nome}}</h2>
                        <div class="d-flex justify-content-between">
                            <div class="text-left w-50">
                                <small class="text-low-white">{{$store.state.dizionario.wallet_list_value_label}}</small>
                                <p class="mb-0 text-white">€ {{item.controvalore | currency}}</p>
                            </div>
                            <div class="text-left w-50">
                                <small class="text-low-white">{{$store.state.dizionario.wallet_list_wallet_perc_label}}</small>
                                <p class="mb-0 text-white">{{item.peso.toFixed(2)}}%</p>
                            </div>
                            <div class="text-right w-25" style="margin-top: -10px;" v-if="item.selling">
                                <small class="text-low-white">Selling</small>
                                <p class="mb-0 text-red text-large">{{item.selling}}%</p>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div>
                                <small class="text-low-white">{{$store.state.dizionario.wallet_list_wallet_perf_ytd}}</small>
                                <p class="mb-0" v-color="item.performanceYtd">{{item.performanceYtd | perc(1)}}%</p>
                            </div>
                            <div>
                                <small class="text-low-white">{{$store.state.dizionario.wallet_list_wallet_perf_1y}}</small>
                                <p class="mb-0" v-color="item.performance1Year">{{item.performance1Year | perc(1)}}%</p>
                            </div>
                            <div>
                                <small class="text-low-white">{{$store.state.dizionario.wallet_list_wallet_perf_3y}}</small>
                                <p class="mb-0" v-color="item.performance3Year">{{item.performance3Year | perc(1)}}%</p>
                            </div>
                            <div>
                                <small class="text-low-white">{{$store.state.dizionario.wallet_list_wallet_vol_3y}}</small>
                                <p class="mb-0" v-color="item.volatilita750">{{item.volatilita750 | perc(1)}}%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <call-to-button @click="saveOrdiniVendita" v-if="canSave">
            Conferma Ordini
        </call-to-button>
    </div>
</template>

<script lang="ts">
import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import { StorageServices } from '@/services/StorageServices'
import * as OM from '@/model';
import AssetTypeSlugs from '@/components/assetTypeSlugs.vue';
import AssetTypeIcon from '@/components/assetTypeIcon.vue';
import SellAssetModal from '@/components/sellAssetModal.vue';
import { LiquidityIdentifier } from '@/enum';
import { ModalServices } from '@/services/ModalServices';
import CallToButton from '@/components/callToButton.vue';
import { OrdineClient, PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        AssetTypeSlugs,
        AssetTypeIcon,
        CallToButton
    }
})
export default class SwitchVendita extends Vue {

    types: string[] = []
    filter: OM.AssetFilterVm = new OM.AssetFilterVm();
    assets: OM.AssetForDonutChart[] = [];
    ordiniPending: OM.OrdinePendingVm[] = [];
    donutSerie: number[] = [];
    donutTypes: OM.TypeForDonutChartVm[] = [];

    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        store.state.headerText =`<small class="text-orange small">` + 
            challenge.titolo + `</small><p class="mb-0">Switch</p>`;

        store.state.creaSwitchVm = new OM.CreaSwitchVm();

        let filterVm = new OM.AssetFilterVm();
        filterVm.portafoglioIdentifier = to.params.portafoglioIdentifier;
        Promise.all([   
            PortafoglioClient.getAssetClasses(filterVm),
            PortafoglioClient.getDonutChart(filterVm),
            PortafoglioClient.getDonutChartTypes(filterVm),
            OrdineClient.getOrdiniPending(filterVm.portafoglioIdentifier, ""),
        ])
        .then(datas => {
            next((vc: SwitchVendita) => {
                store.state.creaSwitchVm = new OM.CreaSwitchVm();
                store.state.creaSwitchVm.userIdentifier = store.state.loginResponse.userIdentifier;
                store.state.creaSwitchVm.walletIdentifier = to.params.portafoglioIdentifier;
                datas[0].unshift('All');
                let liquidityTypeIndex = datas[0].indexOf('Liquidità');
                datas[0].splice(liquidityTypeIndex, 1);
                vc.types = datas[0];
                filterVm.assetType = "All";
                vc.filter = filterVm;
                let liquidityIndex = datas[1].findIndex(x => x.assetIdentifier == LiquidityIdentifier);
                datas[1].splice(liquidityIndex, 1);
                vc.assets = datas[1];
                vc.donutTypes = datas[2];
                vc.ordiniPending = datas[3];
            });
        })
    }

    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        next();
    }
    
    filterByType(val){
        this.filter.assetType = val;
        Promise.all([
            PortafoglioClient.getDonutChart(this.filter),
            OrdineClient.getOrdiniPending(this.filter.portafoglioIdentifier, val)
        ])
        .then(x => {
            let liquidityIndex = x[0].findIndex(x => x.assetIdentifier == LiquidityIdentifier);
            if(liquidityIndex > -1)
                x[0].splice(liquidityIndex, 1);
            this.assets = x[0];
            this.ordiniPending = x[1];
            this.assets.forEach(item => {
                store.state.creaSwitchVm.ordiniVendita.forEach(ord => {
                    if(item.assetIdentifier == ord.assetIdentifier){
                        Vue.set(item, 'selling', (<any>ord).percentage);
                    }
                })
            })
        })
    }

    get assetsWithControvalore(){
        return this.assets.filter(x => x.controvalore > 0);
    }

    openSellAssetModal(asset: OM.AssetForDonutChart) {
        if(asset.nome == "Liquidity")
            return;
        ModalServices.OpenModal(SellAssetModal,
        {
            asset: asset,
            portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
            creaSwitchVm: store.state.creaSwitchVm,
            saveCb: (vm: OM.CreaOrdineVm) => {
                let ind = store.state.creaSwitchVm.ordiniVendita.findIndex(x => x.assetIdentifier == vm.assetIdentifier);
                if(ind > -1){
                    store.state.creaSwitchVm.ordiniVendita.splice(ind, 1);
                    Vue.delete(asset, 'selling');
                }
                if(vm.controvalore > 0) {
                    store.state.creaSwitchVm.ordiniVendita.push(vm);
                    Vue.set(asset, 'selling', (<any>vm).percentage);
                    PortafoglioClient.getPortafoglioStatusWithSwitch({
                        portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
                        creaSwitchVm: store.state.creaSwitchVm
                    })
                    .then(x => {
                        store.state.portafoglioStatus = x;
                        StorageServices.setPortafoglioStatus(x);
                    });
                }
                ModalServices.CloseLastModal();
            }
        });
    }

    get canSave(){
        return store.state.creaSwitchVm.ordiniVendita.length > 0;
    }

    saveOrdiniVendita(){
        StorageServices.setCreaSwitch(store.state.creaSwitchVm)
        this.$router.push('switchAcquisto/assets');
    }

    reinit(){
        let filterVm = new OM.AssetFilterVm();
        filterVm.portafoglioIdentifier = this.$route.params.portafoglioIdentifier;
        let proms = [];
        proms.push(PortafoglioClient.getAssetClasses(filterVm));
        proms.push(PortafoglioClient.getDonutChart(filterVm));
        proms.push(PortafoglioClient.getDonutChartTypes(filterVm));
        proms.push(OrdineClient.getOrdiniPending(filterVm.portafoglioIdentifier, ''));
        Promise.all(proms)
        .then( datas => {
            datas[0].unshift('All');
            this.types = datas[0];
            filterVm.assetType = "All";
            this.filter = filterVm;
            this.assets = datas[1];
            this.donutTypes = datas[2];
            this.donutSerie = datas[2].map(x => x.peso);
            this.ordiniPending = datas[3];
        })
    }

}

</script>

<style scoped>

</style>
