import { CommonServices } from '@/services/CommonServices';
import { baseUrl } from '@/config';
import * as VM  from '@/viewModel'
class _AuthServices {

    defaultLogin(email: string, password: string) {
        var BaseCredentials = {
            "Email": email,
            "Password": password
        }
        return CommonServices.post<any>(baseUrl + "api/token/login", 
            BaseCredentials,
            {
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'authorization': '' 
                }
            }
        );
    }

    facebookTokenLogin(vm: VM.ProviderTokenLoginVm){
        return CommonServices.post(baseUrl + "facebookauth/facebookTokenLogin", vm);
    }

    facebookLogin(fbToken: string, password: string = '') {
        return CommonServices.get(baseUrl + "facebookauth/redirectaccesstoken", { 
            access_token: fbToken,
            password
        });
    }

    googleTokenLogin(vm: VM.ProviderTokenLoginVm){
        return CommonServices.post(baseUrl + "googleauth/googleTokenLogin", vm);
    }

    googleLogin(idToken: string, password: string = '') {
        return CommonServices.get(baseUrl + "googleauth/checkIdToken", { 
            idToken: idToken,
            password
        });
    }

    CheckAppVersion(appVersion: string){
        return CommonServices.get(baseUrl + "api/Auth/CheckAppVersion", { 
            appVersion
        });
    }


    AddToSocialLogin(fbToken: string) {
        return CommonServices.get(baseUrl + "api/Auth/AddToSocialLogin", { access_token: fbToken });
    }

    logout(){
        let prom = new Promise<void>((resolve, reject) => {
            CommonServices.destroyToken();
            window.localStorage.setItem('email', "");
            window.localStorage.setItem('password', "");
            resolve();
        })
        return prom;
    }

    setAuthToken(token: string) {
        return CommonServices.setAuthToken(token);
    }

    checkLogin() {
        return CommonServices.get(baseUrl + "api/token/checklogin");
    }

    Register(credentials: VM.RegisterVm){
        return CommonServices.post(baseUrl + "api/Auth/Register", credentials);
    }

    LoginWithVersion(credentials: VM.LoginDataWithVersion){
        return CommonServices.post(baseUrl + 'api/Auth/LoginWithVersion', credentials);
    }

    forgotPassword(email: string){
        return CommonServices.get(baseUrl + 'api/Auth/ForgotPassword', { email });
    }

    appleTokenLogin(token: string){
        return CommonServices.get(baseUrl + "appleauth/appleTokenLogin", { token });
    }

}
export let AuthServices = new _AuthServices();