<template>
    <div class="chatMessage" :class="message.senderIdentifier == userId ? 'right' : 'left'" 
        @dblclick="emitLongTouch" @touchstart="checkLongPress" @touchmove="voidLongPress" @touchend="voidLongPress">
        <div class="quotedMessage" v-if="message.quotedMessage">
            <p class="sender">{{message.quotedMessage.sender}}</p>
            <span class="ellipsis" v-html="message.quotedMessage.text"></span>   
        </div>
        <div class="chatImg"
            :style="'background-image: url(' + senderImage + '), url(defaultUser.png)'">
        </div>
        <p class="sender">{{message.senderName}}</p>
        <span v-html="message.text"></span>   
        <small class="timestamp">{{message.timestamp | date('YYYY MMMM DD HH:mm')}}</small>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import moment from 'moment';

@Component
export default class ChatMessage extends Vue {
    userId: string = store.state.loginResponse.userIdentifier;
    
    @Prop({
        type: [Object, OM.ChatMessage]
    }) message: OM.ChatMessage;

    @Prop() senderImage: string;

    timer: any = null;
    checkLongPress(){
        var touchduration = 400;
        this.timer = setTimeout(() => {
            this.$emit('longtouch', this.message);
        }, touchduration);
    }
    voidLongPress(){
        clearTimeout(this.timer);
    }
    emitLongTouch(){
        this.$emit('longtouch', this.message);
    }
}
</script>
<style scoped>

.chatMessage {
    max-width: 80%;
    background: #fafafa;
    border-radius: 5px;
    margin: 8px 0;
    padding: 12px 10px 20px;
    position: relative;
    white-space: pre-line;
    color: rgb(98, 102, 105);
}
.chatMessage.left {
    text-align: left;
    left: 2%;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.chatMessage.right {
    text-align: right;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    right: -18%;
}
/* .chatMessage.right .sender {
    display: none;
} */
.chatMessage .chatImg {
    width: 35px;
    height: 35px;
    background-size: cover;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    left: -46px;
}
.chatMessage.right .chatImg {
    display: none;
}
.chatMessage .sender {
    margin: 0;
    margin-top: -5px;
    font-size: 12px;
    padding-left: 2px;
    color: #e17e00;
}
.chatMessage .timestamp {
    position: absolute;
    right: 10px;
    bottom: 0;
    color: #1c2349;
}

.chatMessage .quotedMessage {
    border-radius: 5px;
    margin: 0px 0px 8px;
}
.quotedMessage {
    border-left: 2px solid #efc48d;
    padding: 0 12px 2px;
    background: #f4f4f4;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 60px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    position: relative;
}
.quotedMessage .sender {
    margin: 0;
    margin-top: 2px;
    font-size: 12px;
    padding-left: 2px;
    color: #e17e00;
    display: block!important;
}
.quotedMessage .cancel {
    position: absolute;
    right: -8px;
    top: -8px;
    background: transparent;
    border: none;
    height: 30px;
    width: 30px;
    padding: 10px 10px 0 0;
}
</style>
