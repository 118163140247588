<template>
    <div>
        <!-- <div class="hideScrollBarX">
            <div class="slugsContainer">
                <div class="slug" :class="{ active: filter.periodo == 'iniziogara' }" @click="filter.periodo = 'iniziogara'">
                    {{$store.state.dizionario.ranking_filter_inizio_gara}}
                </div>
                <div class="slug" :class="{ active: filter.periodo == 'trimestre' }" @click="filter.periodo = 'trimestre'">
                    {{$store.state.dizionario.ranking_filter_ultimo_trimestre}}
                </div>
                <div class="slug" :class="{ active: filter.periodo == 'mese' }" @click="filter.periodo = 'mese'">
                    {{$store.state.dizionario.ranking_filter_mese}}
                </div>
            </div>
        </div> -->
        <!-- <div class="slugsContainer text-center" v-if="filtered">
            <div class="slug text-orange" @click="resetFilter">
                {{$store.state.dizionario.ranking_filter_resetLabel}}
            </div>
        </div> -->
        <div class="text-center w-75 mx-auto mt-2" v-if="people.length == 0" :key="index">
            {{$store.state.dizionario.friendChallengeRanking_noPeople}}
        </div>
        <div v-for="(person, index) in people" :key="index">
            <ranking-card :item="person" :position="index + 1"
                :ref="person.isUserLogged ? 'scrollHere':''"></ranking-card>
        </div>
    </div>
</template>

<script lang="ts">
import router from '@/router';
import { Vue, Watch } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import RankingCard from '@/components/rankingCard.vue';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import { ModalServices } from '@/services/ModalServices';
import RankingFilterModal from './rankingFilterModal.vue';
import { FriendChallengeClient } from '@/services/Services';

@Component({
    components: {
        RankingCard
    }
})
export default class FriendChallengeRanking extends Vue {

    people: VM.RankingCardVm[] = [];

    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        let friendChallenge = StorageServices.getSelectedFriendChallenge();
        store.state.headerText = `<small class="text-orange small">` + challenge.titolo + `</small><p class="mb-0">` 
            + friendChallenge.name + `</p>`;
        FriendChallengeClient.getFriendChallengeRankings(friendChallenge.identifier)
        .then(x => {
            next((vc: FriendChallengeRanking) => {
                vc.people = x;
                if(friendChallenge.creatorIdentifier == store.state.loginResponse.userIdentifier){
                    store.state.headerButtons.third = {
                        imgUrl: './img/menu_setup.svg',
                        onClick: () => {
                            vc.$router.push('/challenge/' + vc.$route.params.challengeIdentifier + '/' + 
                            vc.$route.params.portafoglioIdentifier + '/editFriendChallenge/' + 
                            vc.$route.params.friendChallengeIdentifier)
                        }
                    }
                }
            });
        })
    }

    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        store.state.headerButtons.third = null;
        next();
    }

}

</script>

<style scoped>

</style>