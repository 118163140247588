<template>
    <div class="splash">
        <div class="text-center">
            <img src="@/img/App_icon.svg" />
        </div>
        <p class="title text-center">
            Capital<span class="subtitle">Eyes</span>
        </p>
        <div class="buttonContainer">
            <button class="btn btn-orange" @click="register">{{$store.state.dizionario.login_create_profile}}</button>
            <button class="btn btn-orange" @click="login">{{$store.state.dizionario.login_login}}</button>

            <!-- <div v-if="isCordova">
                <p><small>OR</small></p>
                <social-login :login-cb="socialLogin" :googleCb="gplusLogin" :facebook-cb="facebookLogin"></social-login>
            </div> -->
            
            <!-- <div class="twoButtons">
                <button @click="facebookLogin()" class="btn btn-gray">
                    <i class="fab fa-facebook-f"></i>
                </button>
                <button @click="gplusLogin()" class="btn btn-gray">
                    <i class="fab fa-google-plus-g"></i>
                </button>
            </div> -->
        </div>
        
        <div class="forgot-div text-low-white">
            <span @click="forgotPassword">{{$store.state.dizionario.login_forgot_password}}</span>
        </div>

        
        <div class="menuFooter mt-4">
            <a @click="openBrowser('https://password.capitaleyes.app/privacy_policy.html')">{{$store.state.dizionario.privacyPolicyLabel}}</a>
            <a @click="openBrowser('https://password.capitaleyes.app/terms_and_conditions.html')">{{$store.state.dizionario.termsAndConditionsLabel}}</a>
        </div>

    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import router from '@/router';
import store from '@/store';
import { fbRedirectPage } from '@/config';
import { AuthServices } from '@/services/AuthServices';
import { StorageServices } from '@/services/StorageServices';
import { ModalServices } from '@/services/ModalServices';
import forgotPasswordModal from '@/components/forgotPasswordModal.vue';
import { appVersion } from '@/config';
import * as VM from '@/viewModel';
import { GlobalConfigClient } from '@/services/Services';

@Component({})
export default class Login extends Vue {

    loginError: string = "";
    isCordova: boolean = false;

    created(){
        if(Object.keys(store.state.dizionario).length == 0){
            GlobalConfigClient.getDizionario()
            .then(x => {
                store.state.dizionario = x;
            });
        }
        if(window.cordova)
            this.isCordova = true;
        else
            this.isCordova = false;
    }

    register(){
        this.$router.push('register');
    }

    login(){
        this.$router.push('loginForm');
    }
    
    openBrowser(link){
        window.open(link, '_blank');
    }

    forgotPassword(){
        ModalServices.OpenModal(forgotPasswordModal,{});
    }

}
</script>
<style scoped>

    .buttonContainer {
        width: 90%;
        color: white;
        text-align: center;
        margin: auto;
        left: 0;
        right: 0;
    }

    .forgot-div {
        margin-top: 20px;
        width: 100%;
        text-align: center;
    }

    .buttonContainer .btn {
        width: 100%;
        margin-bottom: 20px;
    }


</style>
