<template>
    <div class="overlayBg" @click="clicked">
        <div class="overlayContainer">
            <div class="customContainer">
                <div class="overlayIcon">
                    <img :src="require('@/img/' + icon + '_icon.svg')" />
                </div>
                <div class="overlayCross">
                    <img src="@/img/Cross_orange_icon.svg" />
                </div>
                <div class="overlayTextContainer">
                    <p class="overlayTitle">{{title}}</p>
                    <span class="overlayText">{{text}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Component
export default class OverlayQuestionario extends Vue {

    @Prop() title: string;
    @Prop() text: string;
    @Prop() icon: string;

    clicked(ev){
        this.$emit('click', ev);
    }

}

</script>

<style scoped>

    .overlayBg {
        background-color: rgba(18,24,29,0.6);
        height: 100vh;
        width: 100vw;
        position: fixed;
        z-index: 999;
        left: 0;
        top: 0;
        display: flex;
        align-items: flex-end;
    }

    .overlayContainer {
        position: relative;
        /* display: flex;
        justify-content: center;
        align-items: center; */
        border-top: 2px solid rgb(246,156,20);
        width: 100%;
        background-color: rgb(34,44,52);
        height: 100px;

        display: block;
    }

    .customContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .overlayIcon {
        text-align: right;
        width: 100px;
    }

    .overlayTextContainer {
        padding-left: 10px;
        width: 70%;
    }

    .overlayTextContainer .overlayTitle {
        text-transform: uppercase;
        margin-bottom: 4px;
        color: white;
    }

    .overlayTextContainer .overlayText {
        color: rgb(246,156,20);
    }
    
    .overlayCross {
        height: 12px;
        width: 12px;
        position: absolute;
        top: 15px;
        right: 20px;
        text-align: center;
    }
    .overlayCross img {
        width: 100%;
    }

</style>
