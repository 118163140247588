<template>
    <div>
        <swiper-slides :slide-index="0" @slidechange="setCurrentSlide" :auto-height="false">
            <div class="swiper-slide">
                <div class="px-3 mt-4 mb-3 position-relative">
                    <div v-if="benchmark" class="openChart" @click="openChart(benchmark.performance, $store.state.dizionario.challenge_assets_chart_performance_label)">
                        <span class="text-low-white">{{$store.state.dizionario.challenge_assets_chart_open_button}}</span>
                        <img src="@/img/round_arrow_right.svg" />
                    </div>
                    <chart-card v-if="benchmark" :values="benchmark.performance" :label="$store.state.dizionario.challenge_assets_chart_performance_label" y-type="%" x-frequency="years"></chart-card>
                </div>
                <div class="px-3 mb-3" style="display: block;" v-show="currentSlide == 0">
                    <mini-spinner v-if="!benchmark"></mini-spinner>
                    <performance-table v-else :table="benchmark.performanceTable" @openTable="openPerformanceTable(benchmark.nome)"></performance-table>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="px-3 mt-4 mb-3 position-relative">
                    <div v-if="benchmark" class="openChart" @click="openChart(benchmark.volatilita750, $store.state.dizionario.challenge_assets_chart_volatility_label)">
                        <span class="text-low-white">{{$store.state.dizionario.challenge_assets_chart_open_button}}</span>
                        <img src="@/img/round_arrow_right.svg" />
                    </div>
                    <mini-spinner v-if="!benchmark"></mini-spinner>
                    <chart-card v-else :values="benchmark.volatilita750" :label="$store.state.dizionario.challenge_assets_chart_volatility_label" y-type="%" x-frequency="years"></chart-card>
                    <div class="d-flex justify-content-around mt-3 text-low-white text-center">
                        <div>
                            <small>{{$store.state.dizionario.challenge_assets_chart_volatility_table_header1}}</small>
                            <p class="text-white">{{minVolatilita | perc(1)}} %</p>
                        </div>
                        <div>
                            <small>{{$store.state.dizionario.challenge_assets_chart_volatility_table_header2}}</small>
                            <p class="text-white">{{avgVolatilita | perc(1)}} %</p>
                        </div>
                        <div>
                            <small>{{$store.state.dizionario.challenge_assets_chart_volatility_table_header3}}</small>
                            <p class="text-white">{{maxVolatilita | perc(1)}} %</p>
                        </div>
                        <div>
                            <small>{{$store.state.dizionario.challenge_assets_chart_volatility_table_header4}}</small>
                            <p class="text-white">{{lastVolatilita | perc(1)}} %</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="px-3 mt-4">
                    <div class="fullCard">
                        <mini-spinner v-if="!benchmark"></mini-spinner>
                        <drawdown-card v-else :drawdown="benchmark.drawdown"></drawdown-card>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="px-3 mt-4 position-relative">
                    <div v-if="benchmark" class="openChart" @click="openTable(benchmark.datiPeriodo, $store.state.dizionario.challenge_assets_chart_period_label)">
                        <span class="text-low-white">{{$store.state.dizionario.challenge_assets_chart_open_button}}</span>
                        <img src="@/img/round_arrow_right.svg" />
                    </div>
                    <mini-spinner v-if="!benchmark"></mini-spinner>
                    <periodi-card v-else :periodi="benchmark.datiPeriodo"></periodi-card>
                </div>
            </div>
        </swiper-slides>
        <call-to-button @click="seeAssets">{{$store.state.dizionario.challenge_assets_chart_see_assets_button}}</call-to-button>
    </div>
</template>

<script lang="ts">
import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import WalletBar from '@/components/walletBar.vue';
import CircleCard from '@/components/circleCard.vue';
import { StorageServices } from '@/services/StorageServices'
import CallToButton from '@/components/callToButton.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import SwiperSlides from '@/components/swiperSlides.vue';
import PeriodiCard from '@/components/periodiCard.vue';
import DrawdownCard from '@/components/drawdownCard.vue';
import ChartCard from '@/components/chartCard.vue';
import PerformanceTable from '@/components/performanceTable.vue';
import MiniSpinner from '@/components/miniSpinner.vue';
import { AssetClient } from '@/services/Services';

@Component({
    components: {
        WalletBar,
        CallToButton,
        SwiperSlides,
        PeriodiCard,
        DrawdownCard,
        ChartCard,
        PerformanceTable,
        MiniSpinner
    }
})
export default class MicroClassSummary extends Vue {

    benchmark: VM.BenchmarkVm = null;
    selectedPeriod: OM.DatiPeriodo = new OM.DatiPeriodo();
    scroller: any;
    performanceTable: any;
    currentSlide: number = 0;
    minVolatilita: number = 0;
    avgVolatilita: number = 0;
    maxVolatilita: number = 0;
    lastVolatilita: number = 0;

    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        store.state.headerText = `<small class="text-orange small">` + 
            to.params.macroClass + `</small><p class="mb-0">` + to.params.microClass + `</p>`;
        next();       
    }
    created(){
        AssetClient.getBenchmarkByMacroAndMicro(this.$route.params.macroClass, this.$route.params.microClass, false)
        .then( data => {
            this.benchmark = data;
            this.selectedPeriod = this.benchmark.datiPeriodo[0];
            
            let volatilitaValues = this.benchmark.volatilita750.map(x => x.value).filter(x => x);
            this.minVolatilita = Math.min(...volatilitaValues);
            let total = 0;
            volatilitaValues.forEach(x => x ? total += x : false);
            this.avgVolatilita = total / volatilitaValues.length;
            this.maxVolatilita = Math.max(...volatilitaValues);
            this.lastVolatilita = volatilitaValues[volatilitaValues.length - 1];
        })
    }

    mounted(){
        this.scroller = document.querySelector('.playPageContainer');
        this.scroller.addEventListener('scroll', this.onPageScroll);
        this.performanceTable = document.querySelector('.performanceTable');
    }

    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        this.scroller.removeEventListener('scroll', this.onPageScroll);
        next();
    }

    onPageScroll(ev){
        if(this.currentSlide != 0)
            return;

        if(ev.target.scrollTop >=ev.target.scrollHeight - ev.target.offsetHeight - 20){
            this.performanceTable.classList.add('scrollit');
        } else {
            this.performanceTable.querySelector('tbody').scrollTop = 0;
            this.performanceTable.classList.remove('scrollit');
        }
    }

    setCurrentSlide(val){
        this.currentSlide = val;
    }

    seeAssets(){
        this.$router.push(this.$route.params.microClass + '/list')
    }

    openChart(data, label){
        StorageServices.setChartData(data);
        this.$router.push('/horizontalChart/' + label + '/' + this.$route.params.microClass + '/perc');
    }

    openPerformanceTable(label){
        this.$router.push('/performanceHorizontalTable/' + label + '/' + this.benchmark.identifier);
    }
    
    openTable(data, label){
        StorageServices.setHorizontalTableData(data);
        this.$router.push('/periodiHorizontalTable/' + label + '/' + this.$route.params.microClass);
    }

}

</script>

<style scoped>

</style>
