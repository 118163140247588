<template>
    <div class="circleCard">
        <div class="circle">
        </div>
        <p class="text-low-white">{{label}}</p>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CircleCard extends Vue {

    @Prop() label: string;

}
</script>

<style scoped>

    .circle {
        width: 60px;
        height: 60px;
        background-color: darkviolet;
        border-radius: 50%;
        margin-bottom: 10px;
    }

    .circleCard {
        border-radius: 8px;
        border: 1px solid rgba(34, 44, 52, 0.5);
        background-color: rgba(34, 44, 52, 0.3);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        text-align: center;
        height: 100%;
    }

    .circleCard p {
        margin-bottom: 0px;
    }

</style>
