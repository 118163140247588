<template>
    <div class="fullCard">
        <h5 class="pl-3 pt-3">{{$store.state.dizionario.challenge_assets_chart_period_label}}</h5>
        <!-- <h4 class="title pl-3 pt-4 text-white">Periodi</h4> -->
        <div class="d-flex justify-content-between p-3">
            <div>
                <small class="text-low-white">{{$store.state.dizionario.challenge_assets_chart_num_period_label}}</small>
                <p class="text-big text-white">{{periodo.numeroPeriodi}}</p>
            </div>
            <div>
                <small class="text-orange">{{$store.state.dizionario.challenge_assets_chart_select_period}}</small>
                <select v-model="periodo" class="selezionaPeriodoSelect text-orange">
                    <option v-for="(item, i) in periodi" :key="i" :value="item">
                        {{item.nomePeriodo}}
                    </option>
                </select>
            </div>
        </div>
        <hr/>
        <div class="d-flex justify-content-between p-3">
            <div>
                <small class="text-low-white">{{$store.state.dizionario.challenge_assets_chart_period_positive}}</small>
                <p class="text-big text-white">{{periodo.periodiPositivi | perc}}%</p>
                <p class="text-big text-green">{{Math.round(periodo.numeroPeriodi * periodo.periodiPositivi)}}</p>
            </div>
            <div>
                <small class="text-low-white">{{$store.state.dizionario.challenge_assets_chart_period_negative}}</small>
                <p class="text-big text-white">{{periodo.periodiNegativi | perc}}%</p>
                <p class="text-big text-red">{{periodo.numeroPeriodi - Math.round(periodo.numeroPeriodi * periodo.periodiPositivi)}}</p>
            </div>
        </div>
        <hr/>
        <div class="d-flex justify-content-between pt-3 pr-3 pb-0 pl-3">
            <div>
                <small class="text-low-white">{{$store.state.dizionario.challenge_assets_chart_period_perf_max}}</small>
                <p class="text-green">{{periodo.performanceMax | perc(1)}}%</p>
            </div>
            <div>
                <small class="text-low-white">{{$store.state.dizionario.challenge_assets_chart_period_perf_media}}</small>
                <p class="text-green">{{periodo.performanceMedia | perc(1)}}%</p>
            </div>
            <div>
                <small class="text-low-white">{{$store.state.dizionario.challenge_assets_chart_period_perf_min}}</small>
                <p class="text-red">{{periodo.performanceMin | perc(1)}}%</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Component
export default class PeriodiCard extends Vue {

    @Prop({
        type: [ Array ]
    }) periodi: OM.DatiPeriodo[];

    selectedPeriodo = null;
    get periodo(){
        return this.selectedPeriodo || this.periodi[0];
    }
    set periodo(val){
        this.selectedPeriodo = val;
    }
}

</script>

<style scoped>

    hr {
        border-color: rgb(50, 60, 71);
    }

    .fullCard .title {
        font-size: 23px;
        color: rgb(50, 60, 71);
        font-family: 'Poppins';
    }

    .selezionaPeriodoSelect {
        display: block;
        background: transparent;
        border: 1px solid rgb(46, 50, 56);
        border-radius: 12px;
        width: 120px;
        height: 25px;
        text-transform: uppercase;
        padding: 0 4px;
        outline: none!important;
        font-weight: 700;
        margin: 7px 0;
    }

    .text-big {
        font-size: 33px;
        letter-spacing: 0.39px;
        font-family: 'Poppins-ExtraLight';
        margin: 0;
        line-height: 1.2;
    }

</style>