<template>
    <div v-if="loaded">
        <div class="d-flex py-4 px-3 lightDark" ref="upperSection">
            <tema-cintura :enabled="true" :completato="temaVm.completamento" style="width: 100px;"
                :icona="temaVm.icona"
                :cintura-color="selectedCintura ? selectedCintura.hexColor : '#F0F8FE'"></tema-cintura>
            <div class="">
                <p class="text-large text-low-white ellipsis">{{temaVm.nome}}</p>
                <div>{{temaVm.lezioniCount}} lezioni</div>
            </div>
        </div>
        <div class="scroller-v" ref="lowerSection">
            <accordion v-for="(item, i) in lezioniList" :key="i" :class="{ 'disabled': !item.enabled }" :open="item.isOpen"
                class="temaBlock" @toggleOpen="toggleOpen(item)">
                <div slot="title" class="d-flex justify-content-between w-100">
                    <div>
                        <h5 class="cinturaTitle">{{item.cintura}}</h5>
                        <div class="progressBar">
                            <progress-bar :completed="item.cinturaProgress" :color="item.hexColor"></progress-bar>
                        </div>
                    </div>
                    <div>
                        <button type="button" class="btn btn-dark">
                            <span v-if="item.isOpen">{{$store.state.dizionario.accordion_close}}<i class="fa fa-chevron-up"></i></span>
                            <span v-if="!item.isOpen">{{$store.state.dizionario.accordion_open}}<i class="fa fa-chevron-down"></i></span>
                        </button>
                    </div>
                </div>
                <div slot="content" class="lezioniWrapper">
                    <div class="lezioneItem" v-for="(lezione, ii) in item.elements" :key="ii" 
                        :class="{ 'completed': lezione.superata }"
                        @click="navToLezione(lezione, item.enabled)">
                        <span>{{lezione.titolo}}</span>
                        <div class="lezioneIcon" :class="{ 'completed': lezione.superata }">
                        </div>
                    </div>
                </div>
            </accordion>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import router from '@/router';
import PageHeader from '@/components/pageHeader.vue';
import Accordion from '@/components/accordion.vue';
import AccordionItem from '@/components/accordionItem.vue';
import TemaCintura from '@/components/temaCintura.vue'
import ProgressBar from '@/components/progressBar.vue'
import store from '@/store';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import { CinturaClient, LezioneClient, ProgressiUserClient, TemaClient } from '@/services/Services';

@Component({
    components: {
        PageHeader,
        Accordion,
        AccordionItem,
        TemaCintura,
        ProgressBar
    }
})
export default class DettaglioTemaView extends Vue {

    lezioniList: VM.LezioneGroupedVm[] = [];
    selectedCintura: OM.CinturaWithTemiVm = new OM.CinturaWithTemiVm();
    temaVm: OM.TemaVm = new OM.TemaVm();
    progressiUser: OM.ProgressiUser = new OM.ProgressiUser();
    cintureList: OM.CinturaWithTemiVm[] = [];
    loaded: boolean = false;

    beforeRouteEnter(to, from, next){
        let lezioniList;
        let progressiUser: OM.ProgressiUser;
        let prom1 = LezioneClient.getGroupedByCinturaByPercorso(to.params.temaIdentifier, to.params.percorsoIdentifier);
        let prom2 = ProgressiUserClient.getByUserIdentifier(store.state.loginResponse.userIdentifier);
        let cinturaValue = to.params.cinturaIdentifier ? to.params.cinturaIdentifier : null;
        let prom3 = TemaClient.getTemaVmByPercorso(to.params.temaIdentifier, cinturaValue, to.params.percorsoIdentifier);
        let prom4 = CinturaClient.getCintureWithTemiByPercorso(to.params.temaIdentifier)
        Promise.all([prom1, prom2, prom3, prom4])
        .then(datas => {
            lezioniList = datas[0];
            progressiUser = datas[1];
            lezioniList.forEach(x => Vue.set(x, 'isOpen', true));
            // let cinturaApertaInd = lezioniList.findIndex(x => x.elements[0].cinturaIdentifier == to.params.cinturaIdentifier);
            // if(cinturaApertaInd > -1){
            //     Vue.set(lezioniList[cinturaApertaInd], 'isOpen', true);
            // }
            StorageServices.setCintureList(datas[3]);
            lezioniList.forEach(item => {
                item.elements.forEach(lezione => {
                    Vue.set(lezione, 'superata', progressiUser.lezioniSuperate.findIndex(x => x.identifier == lezione.identifier) > -1);
                });
            });

            next((vc: DettaglioTemaView) => {
                vc.cintureList = StorageServices.getCintureList();
                vc.selectedCintura = vc.cintureList.find(x => x.identifier == to.params.cinturaIdentifier) || null;
                vc.lezioniList = lezioniList;
                vc.progressiUser = progressiUser;
                vc.temaVm = datas[2];
                StorageServices.setTemaVm(vc.temaVm);
                vc.loaded = true;

                vc.$nextTick(() => {
                    let el: any = vc.$refs.upperSection;
                    let lowerHeight = window.innerHeight - parseFloat(getComputedStyle(el).height) - 55;
                    (<HTMLElement>vc.$refs.lowerSection).style.height = lowerHeight + "px";
                })
            });
        })
    }

    navToLezione(lezione: VM.LezioneGroupedVm, cinturaEnabled: boolean){
        if(!cinturaEnabled)
            return;

        let cintura = this.cintureList.filter(x => x.identifier == lezione.cinturaIdentifier)[0];
        StorageServices.setSelectedCintura(cintura);
        cintura.temi.forEach(x => {
            if(x.nome == this.temaVm.nome){
                StorageServices.setTemaVm(x);
            }
        })
        this.$router.push('/cinture/' + lezione.cinturaIdentifier + '/lezione/' + lezione.identifier)
    }

    toggleOpen(item: VM.LezioneGroupedVm){
        item.isOpen = !item.isOpen;
    }

}

</script>

<style scoped>

.btn.btn-dark {
    width: 98px;
    text-align: left;
    position: relative;
}

.btn.btn-dark i {
    position: absolute;
    right: 10px;
    top: 10px;
}

.lightDark {
    background-color: rgb(22, 29, 36);
}

.temaBlock {
    padding: 20px 15px;
    border-bottom: 1px solid black;
}

.temaBlock.open {
    background-color: rgb(22, 29, 36);
}

.temaBlock.disabled {
    background-color: rgb(18, 24, 29);
}

.lezioneItem {
    border-radius: 24px;
    background-color: rgb(34, 44, 52);
    width: 100%;
    padding: 16px 15px;
    margin: 10px 0;
    position: relative;
    color: rgba(250, 250, 250, 0.7);
    font-size: 16px;
}

.lezioneItem span {
    width: 90%;
    display: inline-block;
}

.lezioneItem.completed {
    border: 2px solid rgb(246, 156, 2);
    background-color: rgba(34, 44, 52, 0.3);
}

.lezioneIcon {
    position: absolute;
    right: 15px;
    width: 15px;
    height: 15px;
    background-image: url(../../../img/arrow_white.svg);
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.lezioneIcon.completed {
    background-image: url(../../../img/lesson_complete.svg);
    width: 22px;
}

.scroller-v {
    overflow-y: auto;
}

</style>
