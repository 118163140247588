<template>
    <div class="domanda">
        <div class="testoDomanda">{{domanda.testo}}</div>
        <div>
            <div class="risposta text-low-white" v-for="(item, i) in domanda.risposte" :key="i" @click="select(item)">
                <span>{{item.testo}}</span>
                <div class="arrowIcon">
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';

@Component
export default class Domanda extends Vue {
    @Prop({
        type: [Object, OM.Domanda]
    }) domanda: OM.Domanda;
    @Prop() value: OM.RispostaAlQuestionario;

    select(item: OM.Risposta){
        let risposta: OM.RispostaAlQuestionario = {
            testoDomanda: this.domanda.testo,
            testoRisposta: item.testo,
            valore: item.valore,
            testoRiepilogo: item.testoRiepilogo
        }
        this.$emit('input', risposta);
    }

}

</script>

<style scoped>

    .domanda .testoDomanda {
        margin-bottom: 25px;
        color: rgb(250, 250, 252);
    }
    .domanda ol {
        padding-left: 20px;
        text-align: left;
    }
    .domanda label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;
        margin: 15px 0 15px 0;
        vertical-align: middle;
        /* min-height: 50px; */
    }
    .domanda .testoDomanda {
        width: 80%;
    }
    .domanda .pallinoWrapper {
        position: relative;
    }
    
    .risposta {
        border-radius: 24px;
        background-color: rgb(34, 44, 52);
        width: 100%;
        padding: 16px 15px;
        margin: 10px 0;
        position: relative;
        color: white;
        font-size: 16px;
    }

    .risposta span {
        width: 90%;
        display: inline-block;
    }

</style>
