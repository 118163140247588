<template>
    <div class="pageWrapper hasHeader">
        <page-header>
            {{definizione.key}}
            <div slot="left">
                <div class="headerButton" @click="$router.back()">
                    <img src="@/img/back_header.svg" />
                </div>
            </div>

            <div>
                Glossario
            </div>
        </page-header>

        <div class="px-3 textChangeSizeContent contentHtmlContainer" v-for="(group, index) in definizione" :key="index">
            <h1>{{group.group}}</h1>
            <ul>
                <li v-for="(element, index) in group.elements" :key="index" @click="$router.push('glossario/' + element.key)">
                    {{element.key}}
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/model';
import PageHeader from '@/components/pageHeader.vue';
import LezioneContent from '@/components/lezioneContent.vue';
import { GlossarioClient } from '@/services/Services';

@Component({
    components: {
        PageHeader,
        LezioneContent
    }
})
export default class Glossario extends Vue {

    definizione: Array<OM.GlossarioGroupVm> = new Array<OM.GlossarioGroupVm>();
    loaded: boolean = false;

    created(){
        GlossarioClient.getAll()
        .then(x => {
            this.definizione = x;
            this.loaded = true;
        })
    }
}

</script>
<style scoped>
.hasHeader{
    padding-top:24px;
}

</style>
