import Vue from 'vue';
import Vuex from 'vuex';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';

Vue.use(Vuex)
let headerButtonsDefinitions: {
    first: VM.HeaderButtonDefinition, 
    second: VM.HeaderButtonDefinition, third: VM.HeaderButtonDefinition, 
    fourth: VM.HeaderButtonDefinition
} = { first: null, second: null, third: null, fourth: null }


let storeOptions = {
    state: {
        debugMessage: '',
        counters: {
            chat: 0,
            archivio: false
        },
        headerButtons: headerButtonsDefinitions,
        showSpinner: 0,
        loginResponse: StorageServices.getLoginResponse() || new OM.LoginResponse(),
        headerText: '',
        portafoglioStatus: new OM.PortafoglioStatusVm(),
        creaSwitchVm: StorageServices.getCreaSwitch() || null,
        systemDate: "",
        dizionario: {},
        dizionarioHtml: {},
        valute: {
            EUR: 1,
            USD: 1,
            GBP: 1,
            CHF: 1,
            JPY: 1,
            EUREUR: 1,
            EURUSD: 1,
            EURGBP: 1,
            EURCHF: 1,
            EURJPY: 1
        },
        analysisProperty: '',
        analysisPropertyType: '',
        notification: null,
        notificationFunction: () => {}
        //risultatoQuestionario: null
    },
    mutations: {
        showSpinner(state){
            state.showSpinner++;
        },
        hideSpinner(state){
            state.showSpinner--;
            if(state.showSpinner < 0)
                state.showSpinner = 0;
        },
    },
    getters: {

    }
}

let store = new Vuex.Store(storeOptions);

export default store;
