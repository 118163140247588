<template>
    <div>
        <table class="table table-striped performanceTable">
            <thead>
                <tr>
                    <td>{{$store.state.dizionario.challenge_assets_chart_performance_table_header1}}</td>
                    <td>{{$store.state.dizionario.challenge_assets_chart_performance_table_header2}}</td>
                    <td>{{$store.state.dizionario.challenge_assets_chart_performance_table_header3}}</td>
                    <td>{{$store.state.dizionario.challenge_assets_chart_performance_table_header4}}</td>
                    <td>{{$store.state.dizionario.challenge_assets_chart_performance_table_header5}}</td>
                    <td>{{$store.state.dizionario.challenge_assets_chart_performance_table_header6}}</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colspan="6">
                        <div @click="openTable">
                            <span class="text-low-white" style="font-size: 12px; font-weight: normal;">
                                {{$store.state.dizionario.challenge_assets_chart_open_button}}
                            </span>
                            <img src="@/img/round_arrow_right.svg" />
                        </div>
                    </td>
                </tr>
                <tr v-for="(row, i) in table" :key="i">
                    <td class="text-low-white">{{row.anno}}</td>
                    <td v-color="row.firstQ">{{row.firstQ | perc(1)}}</td>
                    <td v-color="row.secondQ">{{row.secondQ | perc(1)}}</td>
                    <td v-color="row.thirdQ">{{row.thirdQ | perc(1)}}</td>
                    <td v-color="row.fourthQ">{{row.fourthQ | perc(1)}}</td>
                    <td v-color="row.yearly">{{row.yearly | perc(1)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Component
export default class PerformanceTable extends Vue {
    
    @Prop() table: VM.AssetPerformanceVm[];

    openTable(){
        this.$emit('openTable');
    }
    
}
</script>
<style scoped>

</style>
