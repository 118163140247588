<template>
    <div class="pageWrapper mt-2" v-if="loaded">
        <div class="box text-low-white text-center">
            <div class="text-white">
                <img src="@/img/menu_hat.svg" />
            </div>
            <h5 class="mt-1">{{$store.state.dizionario.profile_learning_boxTitle}}</h5>
            <p>{{currentCintura.nome}}</p>
            <div class="progressBarContainer mb-5">
                <user-progress-bar :steps="totalCinture" :progressi-cinture="progressiUser.progressiCinture"></user-progress-bar>
            </div>
            <div class="d-flex justify-content-center">
                <div>
                    <div>Punteggio</div>
                    <p class="text-large text-white mt-1">{{user.punteggioLearning}}</p>
                </div>
            </div>
        </div>
        <div class="box my-2 text-center" v-if="user.premiUser.length == 0">
            <div class="text-white">
                <img src="@/img/menu_chart.svg" />
            </div>
            <h5>{{$store.state.dizionario.profile_gaming_boxTitle}}</h5>
            <div class="text-low-white">{{$store.state.dizionario.profile_gaming_waitingLabel}}</div>
        </div>
        <div class="box my-2 text-center" v-else>
            <div class="text-white">
                <img src="@/img/menu_chart.svg" />
            </div>
            <h5 class="mt-1">{{$store.state.dizionario.profile_gaming_boxTitle}}</h5>
            <div class="text-low-white">{{$store.state.dizionario.profile_gaming_premiLabel}}</div>
            <table class="table profiloTable mt-2">
                <tr>
                    <td></td>
                    <td>
                        <div class="pallino gold"></div>
                    </td>
                    <td>
                        <div class="pallino silver"></div>
                    </td>
                    <td>
                        <div class="pallino bronze"></div>
                    </td>
                </tr>
                <tr v-for="(item, i) in user.premiUser" :key="i">
                    <td class="text-left text-low-white pl-2">{{item.tipoPremio}}</td>
                    <td>
                        {{item.podioUno}}
                    </td>
                    <td>
                        {{item.podioDue}}
                    </td>
                    <td>
                        {{item.podioTre}}
                    </td>
                </tr>
            </table>
            <div class="d-flex justify-content-center text-center">
                <div>
                    <div class="text-low-white">Punteggio</div>
                    <p class="text-large text-white mt-1">{{user.punteggioGaming}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import PageHeader from '@/components/pageHeader.vue';
import UserProgressBar from '@/components/userProgressBar.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import { CinturaClient, ProgressiUserClient, UserClient } from '@/services/Services';

@Component({
    components: {
        PageHeader,
        UserProgressBar
    }
})
export default class PremiView extends Vue {
    loaded: boolean = false;
    user: VM.ProfileVm = new VM.ProfileVm();
    totalCinture: number = null;
    progressiUser: OM.ProgressiUser = new OM.ProgressiUser();
    currentCintura: VM.ProgressoCinturaVm = new VM.ProgressoCinturaVm();

    beforeRouteEnter(to, from, next){
        let proms = [];
        let promProgressi = ProgressiUserClient.getByUserIdentifier(store.state.loginResponse.userIdentifier)
        proms.push(promProgressi);
        let promUser = UserClient.getProfile()
        proms.push(promUser);
        let promTotalCinture = CinturaClient.getTotalCinture();
        proms.push(promTotalCinture);
        Promise.all(proms).then( datas => {
            next((vc: PremiView) => {
                console.log(datas);
                vc.progressiUser = datas[0];
                vc.currentCintura = vc.progressiUser.progressiCinture[vc.progressiUser.progressiCinture.length - 1];
                vc.user = datas[1];
                vc.totalCinture = datas[2];
                vc.loaded = true;
            });
        })
    }

    clearProgressi(){
        ProgressiUserClient.clearProgressi();
        this.loaded = false;
        let proms = [];
        let promProgressi = ProgressiUserClient.getByUserIdentifier(store.state.loginResponse.userIdentifier)
        proms.push(promProgressi);
        let promUser = UserClient.getProfile()
        proms.push(promUser);
        let promTotalCinture = CinturaClient.getTotalCinture();
        proms.push(promTotalCinture);
        Promise.all(proms).then( datas => {
            this.progressiUser = datas[0];
            this.currentCintura = this.progressiUser.progressiCinture[this.progressiUser.progressiCinture.length - 1];
            this.user = datas[1];
            this.totalCinture = datas[2];
            this.loaded = true;
        })
    }

}

</script>