<template>
    <div class="pageWrapper hasHeader glossario">
        <page-header>
            {{node.name}}
            <div slot="left">
                <div class="headerButton" @click="$router.back()">
                    <img src="@/img/back_header.svg" />
                </div>
            </div>
        </page-header>

        <div class="d-flex justify-content-end px-3 w-100 mb-4">
            <div>
                <button type="button" class="btn textChanger minus mr-2" 
                    :disabled="classChangeDimension == 'dimension_1'"
                    @click="minusText">A</button>

                <button type="button" class="btn textChanger plus" 
                    :disabled="classChangeDimension == 'dimension_4'"
                    @click="plusText">A</button>
            </div>
        </div>

        <div class="px-3 textChangeSizeContent" :class="classChangeDimension" v-if="loaded">
            <div class="contentHtmlContainer" ref="contentContainer" v-glossario="node.htmlContent"></div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/model';
import PageHeader from '@/components/pageHeader.vue';
import LezioneContent from '@/components/lezioneContent.vue';
import { ArchivioClient } from '@/services/Services';

@Component({
    components: {
        PageHeader,
        LezioneContent
    }
})
export default class ArchivioDetail extends Vue {

    classChangeDimension: string = "dimension_1";
    changeDimensionStepNumber: number = 1;
    node: OM.ArchivioNode = new OM.ArchivioNode();
    loaded: boolean = false;
    container: any;

    created(){
        ArchivioClient.getById(this.$route.params.nodeIdentifier)
        .then(x => {
            this.node = x;
            this.loaded = true;
            setTimeout(() => {
                this.container = this.$refs.contentContainer;
                let imgs = this.container.querySelectorAll('img');
                imgs.forEach(x => {
                    x.addEventListener('click', (ev) => {
                        if(!window.cordova)
                            return;

                        PhotoViewer.show(ev.target.src, '');
                    });
                })
            }, 200);
        })
    }
    
    minusText(){
        if(this.changeDimensionStepNumber > 1) {
            this.changeDimensionStepNumber -= 1;
            this.classChangeDimension = "dimension_" + this.changeDimensionStepNumber;
        }

    }

    plusText(){
        if(this.changeDimensionStepNumber < 4) {
            this.changeDimensionStepNumber += 1;
            this.classChangeDimension = "dimension_" + this.changeDimensionStepNumber;
        }
    }
}

</script>
<style scoped>
.hasHeader{
    padding-top:24px;
}

</style>
